export const COMPANY_CREATE_REQUEST = "COMPANY_CREATE_REQUEST";
export const COMPANY_CREATE_SUCCESS = "COMPANY_CREATE_SUCCESS";
export const COMPANY_CREATE_FAIL = "COMPANY_CREATE_FAIL";

export const COMPANY_DELETE_REQUEST = "COMPANY_DELETE_REQUEST";
export const COMPANY_DELETE_SUCCESS = "COMPANY_DELETE_SUCCESS";
export const COMPANY_DELETE_FAIL = "COMPANY_DELETE_FAIL";

export const COMPANY_READ_REQUEST = "COMPANY_READ_REQUEST";
export const COMPANY_READ_SUCCESS = "COMPANY_READ_SUCCESS";
export const COMPANY_READ_FAIL = "COMPANY_READ_FAIL";
export const COMPANY_READ_RESET = "COMPANY_READ_RESET";

export const COMPANY_READ_ALL_REQUEST = "COMPANY_READ_ALL_REQUEST";
export const COMPANY_READ_ALL_SUCCESS = "COMPANY_READ_ALL_SUCCESS";
export const COMPANY_READ_ALL_FAIL = "COMPANY_READ_ALL_FAIL";
export const COMPANY_READ_ALL_RESET = "COMPANY_READ_ALL_RESET";

export const COMPANY_UPDATE_REQUEST = "COMPANY_UPDATE_REQUEST";
export const COMPANY_UPDATE_SUCCESS = "COMPANY_UPDATE_SUCCESS";
export const COMPANY_UPDATE_FAIL = "COMPANY_UPDATE_FAIL";
export const COMPANY_UPDATE_RESET = "COMPANY_UPDATE_RESET";
