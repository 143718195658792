import {
    BRANCH_CREATE_REQUEST, 
    BRANCH_CREATE_SUCCESS, 
    BRANCH_CREATE_FAIL 
} from "./createBranchState";
import { ERROR_500, ERROR_504 } from "app/store/utils/constants/errorConstants";
import axios from "axios";

export const createBranch = (branchInfo) => async (dispatch, getState) => {
    try {
        dispatch({
            type: BRANCH_CREATE_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.post(
            `/branch/create/`,
            branchInfo,
            config
        );

        dispatch({
            type: BRANCH_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: BRANCH_CREATE_FAIL,
                        payload: "Ошибка при создании",
                    });
                    break;

                case 401:
                    dispatch({
                        type: BRANCH_CREATE_FAIL,
                        payload: "Пустой или неправильный токен",
                    });
                    break;

                case 403:
                    dispatch({
                        type: BRANCH_CREATE_FAIL,
                        payload: "Ошибка доступа",
                    });
                    break;

                case 404:
                    dispatch({
                        type: BRANCH_CREATE_FAIL,
                        payload: "Компания не найдена",
                    });
                    break;
                case 500:
                    dispatch({
                        type: BRANCH_CREATE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;
                default:
                    dispatch({
                        type: BRANCH_CREATE_FAIL,
                        payload: "Произошла ошибка" + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: BRANCH_CREATE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};