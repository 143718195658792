import {
    Button,
    Flex,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useColorModeValue,
    useDisclosure
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { createCompanyAction } from "../../../entities/company";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllCodes } from "../../../app/store/actions/QRActions";
import { getAllCompaniesAction } from "../../../entities/company";
import { useDidMount } from "../../../shared/hooks/useDidMount";
import { usePushNotification } from "../../../shared/hooks/usePushNotification";
import DescriptionCard from "./ui/DescriptionCard";
import QRcodes from "./ui/QRcodes";
import ReviewSettings from "./ui/ReviewSettings";

const Company = (props) => {
    const dispatch = useDispatch();
    const inputBg = useColorModeValue("white", "gray.800");
    const textColor = useColorModeValue("gray.700", "white");

    const location = useLocation();
    const navigate = useNavigate();

    const didMount = useDidMount();

    const deskNumber = location.search;

    const {isOpen, onOpen, onClose} = useDisclosure();

    const [nameCompany, setNameCompany] = useState("");
    const [tabIndex, setTabIndex] = useState(0);

    const companyCreate = useSelector((state) => state.companyCreate);
    const QRCreate = useSelector((state) => state.QRCreate);
    const allCompanies = useSelector((state) => state.allCompanies);

    const {error: errorCompanyCreate, loading: loadingCompanyCreate} = companyCreate;

    const reviewSettingsCreate = useSelector(
        (state) => state.reviewSettingsCreate
    );
    const reviewSettingsDelete = useSelector(
        (state) => state.reviewSettingsDelete
    );
    const reviewSettingsUpdate = useSelector(
        (state) => state.reviewSettingsUpdate
    );

    const {error: errorSettingsUpdate, loading: loadingSettingsUpdate} = reviewSettingsUpdate;
    const {error: errorSettingsCreate, loading: loadingSettingsCreate} = reviewSettingsCreate;
    const {error: errorSettingsDelete, loading: loadingSettingsDelete} = reviewSettingsDelete;

    const {companies, error: companyError, loading: readCompanyLoading} = allCompanies;
    const {loading} = companyCreate;

    const {error, loading: qrLoading} = QRCreate;

    useEffect(() => {
        dispatch(getAllCompaniesAction());
    }, [companyCreate]);

    usePushNotification(
        error,
        qrLoading,
        didMount,
        "QR-код создан успешно",
        "Произошла ошибка при создании QR-кода",
        QRCreate
    );
    
    usePushNotification(
        errorSettingsCreate,
        loadingSettingsCreate,
        didMount,
        "Автоответ создан успешно",
        "Произошла ошибка при создании автоответа",
        reviewSettingsCreate
    );

    usePushNotification(
        errorSettingsDelete,
        loadingSettingsDelete,
        didMount,
        "Автоответ удален успешно",
        "Произошла ошибка при удалении автоответа",
        reviewSettingsDelete
    );

    usePushNotification(
        errorSettingsUpdate,
        loadingSettingsUpdate,
        didMount,
        "Автоответ обновлен успешно",
        "При обновлении автоответа произошла ошибка",
        reviewSettingsUpdate
    );

    usePushNotification(
        errorCompanyCreate,
        loadingCompanyCreate,
        didMount,
        "Компания создана успешно",
        "Произошла ошибка при создании компании",
        companyCreate
    );

    useEffect(() => {
        dispatch(getAllCodes());
    }, [QRCreate, companyCreate]);

    useEffect(() => {
        if(deskNumber.split("desk=")[1]) {
            const num = Number(deskNumber.split("desk=")[1]);
            setTabIndex(num);
        }
    }, []);

    const [warning, setWarning] = useState("");

    const handleCreateCompany = () => {
        if (nameCompany !== "") {
            setWarning("");
            dispatch(createCompanyAction(nameCompany));
            onClose();
        } else {
            setWarning("Заполните, пожалуйста, название компании");
        }
    };

    const handleStep = () => navigate(`/dashboard/company?desk=${tabIndex}`);

    const handleTabsChange = (index) => setTabIndex(index);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Создать компанию</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                        >
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                mb="20px"
                            >
                                Название компании *
                            </Text>
                            <Flex align="center" mb="20px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={nameCompany}
                                        onChange={(e) =>
                                            setNameCompany(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="outline" onClick={handleCreateCompany}>
                            Создать
                        </Button>
                        {warning}
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Flex direction="column" pt={{base: "120px", md: "75px"}}>
                {!companyError && companies[0] && !loading && !readCompanyLoading ? (
                    <Tabs variant="soft-rounded" mt="20px" index={tabIndex} onChange={handleTabsChange}>
                        <TabList flexWrap="wrap">
                            <Tab onClick={handleStep}>Информация о компании</Tab>
                            <Tab onClick={handleStep}>Настройка автоответа</Tab>
                            <Tab onClick={handleStep}>QR коды</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <DescriptionCard companyInfo={companies[0]}/>
                            </TabPanel>
                            <TabPanel>
                                <ReviewSettings
                                    companyId={companies[0].id}
                                ></ReviewSettings>
                            </TabPanel>
                            <TabPanel>
                                <QRcodes createError={error} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                ) : (loading || readCompanyLoading) ? <Flex
                mt="100px"
                mb="100px"
                justifyContent="center"
            >
                <Spinner w="100px" h="100px" thickness="10px" />
            </Flex> : (
                    <Flex
                        justify="center"
                        gap="20px"
                        mt="20%"
                        align="center"
                        direction="column"
                    >
                        <Text fontSize="2xl" align="center" color={textColor}>
                            Вы еще не создали компанию
                        </Text>
                        <Button
                            variant="outline"
                            onClick={onOpen}
                            width={{md: "30%"}}
                            colorScheme="facebook"
                        >
                            Добавить компанию
                        </Button>
                    </Flex>
                )}
            </Flex>
        </>
    );
};

export default Company;
