import { 
    COMPANY_DELETE_REQUEST,
    COMPANY_DELETE_SUCCESS, 
    COMPANY_DELETE_FAIL
} from "./deleteCompanyState";

export const companyDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case COMPANY_DELETE_REQUEST:
            return {loading: true};

        case COMPANY_DELETE_SUCCESS:
            return {loading: false, success: true};

        case COMPANY_DELETE_FAIL:
            return {loading: false, error: action.payload};

        default:
            return state;
    }
};