import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getAllCompaniesAction } from "../../../entities/company";

import { useLocation } from "react-router-dom";

import { Flex, Heading, Spinner, Text } from "@chakra-ui/react";
import Reviews from "./ui/Reviews";

function AllReviews() {
    const dispatch = useDispatch();

    const allCompanies = useSelector((state) => state.allCompanies);

    const {companies, loading, error} = allCompanies;

    let location = useLocation();

    const page = location.search;

    useEffect(() => {
        dispatch(getAllCompaniesAction());
    }, []);

    return (
        <Flex direction="column" pt={{base: "120px", md: "75px"}}>
            <Heading fontSize="3xl" mt="20px" mb="20px">
                Отзывы
            </Heading>
            {!error && companies[0] ? (
                <Reviews pageNumber={page} />
            ) : loading ? (
                <Flex mt="100px" mb="100px" h="40vh" justifyContent="center">
                    <Spinner w="100px" h="100px" thickness="10px" />
                </Flex>
            ) : (
                <Text>Создайте компанию</Text>
            )}
        </Flex>
    );
}

export default AllReviews;
