import { useDispatch } from "react-redux";
import { logoutAction } from "../api/actions/logoutAction";

export const useLogout = () => {
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(logoutAction());
    };
    
    return { logout };
};