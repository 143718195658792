import { mode } from '@chakra-ui/theme-tools';
import 'app/styles/shared/fonts/roboto.scss';

export const globalStyles = {
    colors: {
        gray: {
            700: '#1f2733'
        },
        maincolor: '#4584FF',
        secondary: '#4333A6',
    },
    styles: {
        global: (props) => ({
            body: {
                bg: mode('gray.50', 'gray.800')(props),
                fontFamily: "'Roboto', sans-serif"
            },
            html: {
                fontFamily: "'Roboto', sans-serif"
            }
        })
    }
};
