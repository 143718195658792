import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Button,
    Card,
    CardBody,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    Textarea,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import {
    createReviewSettings,
    deleteReviewSettings,
    getReviewSettings,
    updateReviewSettings,
} from "app/store/actions/reviewSettingsActions";
import { SvgIcon } from "shared/ui/Icon";
import { FaTrashAlt } from "react-icons/fa";

function ReviewSettings({companyId}) {
    const inputBg = useColorModeValue("white", "gray.800");

    const {isOpen, onOpen, onClose} = useDisclosure();

    const {
        isOpen: isOpenUpdate,
        onOpen: onOpenUpdate,
        onClose: onCloseUpdate,
    } = useDisclosure();

    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose,
    } = useDisclosure();

    const [mask, setMask] = useState("");
    const [warning, setWarning] = useState("");

    const reviewSettingsCreate = useSelector(
        (state) => state.reviewSettingsCreate
    );
    const reviewSettingsDelete = useSelector(
        (state) => state.reviewSettingsDelete
    );
    const reviewSettingsUpdate = useSelector(
        (state) => state.reviewSettingsUpdate
    );

    useEffect(() => {
        dispatch(getReviewSettings(companyId));
    }, [reviewSettingsCreate, reviewSettingsDelete, reviewSettingsUpdate]);

    const reviewSettingsDetails = useSelector(
        (state) => state.reviewSettingsDetails
    );

    const {
        reviewSettings,
        error,
    } = reviewSettingsDetails;

    const {loading: settingsCreateLoading} = reviewSettingsCreate;
    const {loading: settingsUpdateLoading} = reviewSettingsUpdate;
    const {loading: settingsDeleteLoading} = reviewSettingsDelete;

    const dispatch = useDispatch();

    const handleCreate = () => {
        if (mask !== "") {
            dispatch(createReviewSettings({company_id: companyId, mask: mask}));
            onClose();
            setWarning("");
            setMask("");
        } else {
            setWarning("Введите обязательны поля");
        }
    };

    const handleUpdate = () => {
        if (mask !== "") {
            dispatch(updateReviewSettings(companyId, {mask: mask}));
            onCloseUpdate();
            setWarning("");
            setMask("");
        } else {
            setWarning("Введите обязательны поля");
        }
    };

    const handleDelete = () => {
        dispatch(deleteReviewSettings(companyId));
        onDeleteClose();
    };

    return (
        <Flex direction="column">
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Создайте автоответ</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Введите маску автоответа *</FormLabel>
                            <Textarea
                                bg={inputBg}
                                value={mask}
                                onChange={(e) => setMask(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Flex direction="column">
                            <Text>{warning}</Text>
                            <Button variant="facebook" onClick={handleCreate}>
                                Создать
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader closeButton></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Вы действительно хотите удалить автоответ?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="red" onClick={handleDelete}>
                        Удалить
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpenUpdate} onClose={onCloseUpdate}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Обновите автоответ</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Введите маску автоответа *</FormLabel>
                            <Textarea
                                bg={inputBg}
                                value={mask}
                                onChange={(e) => setMask(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Flex direction="column">
                            <Text>{warning}</Text>
                            <Button variant="facebook" onClick={handleUpdate}>
                                Создать
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {(settingsCreateLoading ||
                settingsDeleteLoading ||
                settingsUpdateLoading) ? (
                <Flex mt="100px" mb="100px" h="40vh" justifyContent="center">
                    <Spinner w="100px" h="100px" thickness="10px" />
                </Flex>
            ) : !error && Object.keys(reviewSettings).length !== 0 ? (
                <Card w={{sm: "100%", md: "70%"}} mt="20px">
                    <CardBody p="30px">
                            <Flex
                                justify="space-between"
                                direction="column"
                                align="center"
                            >
                                <Box>
                                    <Heading fontSize="lg">Автоответ</Heading>
                                    <Text mt="20px" mb="20px">
                                        {reviewSettings.mask}
                                    </Text>
                                </Box>
                                <Flex gap="10px" alignSelf="flex-end">
                                    <button
                                        style={{cursor: "pointer"}}
                                        onClick={onOpenUpdate}
                                    >
                                        <SvgIcon.PencilSquare
                                            width={16}
                                            height={16}
                                        />
                                    </button>
                                    <Icon
                                        color="red"
                                        as={FaTrashAlt}
                                        style={{cursor: "pointer"}}
                                        onClick={onDeleteOpen}
                                    />
                                </Flex>
                            </Flex>
                        
                    </CardBody>
                </Card>
            ) : (
                <Flex direction="column">
                    <Heading size="md">Вы еще не создали автоответ</Heading>
                    <Button mt="30px" colorScheme="facebook" onClick={onOpen}>
                        Создать автоответ
                    </Button>
                </Flex>
            )}
            {error && error !== "Компания не найдена" && (
                <Text color="red">{error}</Text>
            )}
        </Flex>
    );
}

export default ReviewSettings;
