import { 
    USER_LOGOUT,
    USER_DETAILS_RESET
} from "../states/logoutState";

export const logoutAction = () => (dispatch) => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("company");
    dispatch({type: USER_LOGOUT});
    dispatch({type: USER_DETAILS_RESET});
};