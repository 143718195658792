import { useEffect, useState } from "react";
// Chakra imports
import {
    Button,
    Flex,
    Icon,
    Input,
    InputGroup,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
// Custom components

import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";

import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import { updateUserAction } from "../../../../entities/user";

import { validatePassword } from "../../../../shared/helpers/registerValidator";
import { SvgIcon } from "shared/ui/Icon";

const ProfileInformation = ({
    title,
    description,
    mobile,
    rate,
    job,
    name,
    email,
    error,
}) => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
    const inputBg = useColorModeValue("white", "gray.800");

    const {isOpen, onOpen, onClose} = useDisclosure();

    const dispatch = useDispatch();

    useEffect(() => {
        if (name.split(" ")[0] !== undefined) {
            setName(name.split(" ")[0]);
            if (description !== null) setDescription(description);
            setSurname(name.split(" ")[1]);
            if (mobile !== null) setMobile(mobile);
            if (job !== null) setJob(job);
        }
    }, [isOpen]);

    const [nameEdit, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [descriptionEdit, setDescription] = useState("");
    const [mobileEdit, setMobile] = useState("");
    const [jobEdit, setJob] = useState("");
    const [necessaryText, setNecessaryText] = useState("");
    const [passwordWarning, setPasswordWarning] = useState("");

    let userUpdateInfo = {
        first_name: nameEdit,
        last_name: surname,
        password: password,
        description: descriptionEdit,
        phone_number: mobileEdit,
        job_title: jobEdit,
    };

    const userUpdate = useSelector((state) => state.userUpdate);
    const {error: errorUpdate} = userUpdate;

    const handleUpdateProfileInfo = () => {
        if (
            validatePassword(password, passwordRepeat) &&
            nameEdit !== "" &&
            surname !== ""
        ) {
            dispatch(updateUserAction(userUpdateInfo));
            setNecessaryText("");
            setPasswordWarning("");
            onClose();
            setPassword("");
            setPasswordRepeat("");
        }
        if (!validatePassword(password, passwordRepeat)) {
            setPasswordWarning("Пароли не совпадают");
        }
        if (nameEdit === "" || surname === "") {
            setNecessaryText("Заполните обязательные поля");
        }
    };

    const handleMobileMask = (e) => {
        const x = e.target.value
            .replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2]
            ? x[1]
            : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        setMobile("7" + e.target.value.replace(/\D/g, ""));
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Редактирование профиля
                        {error && (
                            <h5 className="text-danger">
                                Произошла ошибка обновления данных
                            </h5>
                        )}
                        {errorUpdate && (
                            <h5 className="text-danger">
                                Произошла ошибка обновления данных
                            </h5>
                        )}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                        >
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                mb="10px"
                            >
                                Ваше имя *
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={nameEdit || ""}
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 10px 0px"
                            >
                                Ваша фамилия *
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={surname || ""}
                                        onChange={(e) =>
                                            setSurname(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 10px 0px"
                            >
                                Пароль
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        type="password"
                                        value={password || ""}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 10px 0px"
                            >
                                Повторите пароль
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        type="password"
                                        value={passwordRepeat || ""}
                                        onChange={(e) =>
                                            setPasswordRepeat(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 10px 0px"
                            >
                                Новое описание
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Textarea
                                        value={descriptionEdit || ""}
                                        fontSize="xs"
                                        py="11px"
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                    ></Textarea>
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 10px 0px"
                            >
                                Ваш номер телефона
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        placeholder="(777)777-7777"
                                        onChange={handleMobileMask}
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 10px 0px"
                            >
                                Ваша должность
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={jobEdit || ""}
                                        onChange={(e) => setJob(e.target.value)}
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Flex direction="column">
                            <Text color="gray">{necessaryText}</Text>
                            <Text color="gray">{passwordWarning}</Text>
                            <Flex>
                                <Button
                                    colorScheme="facebook"
                                    onClick={handleUpdateProfileInfo}
                                    mr={3}
                                >
                                    Сохранить
                                </Button>
                                <Button
                                    colorScheme="gray"
                                    onClick={() => {
                                        onClose();
                                        setNecessaryText("");
                                    }}
                                >
                                    Закрыть
                                </Button>
                            </Flex>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Card p="16px" my={{sm: "24px", xl: "0px"}}>
                <CardHeader p="12px 5px" mb="12px">
                    <Flex gap="10px" align="center">
                        <Text fontSize="lg" color={textColor} fontWeight="bold" margin="0">
                            {title}
                        </Text>
                        <button
                            style={{cursor: "pointer"}}
                            onClick={onOpen}
                        >
                            <SvgIcon.PencilSquare
                                width={16}
                                height={16}
                            />
                        </button>
                    </Flex>
                </CardHeader>
                <CardBody px="5px">
                    <Flex direction="column">
                        {error && (
                            <h3 className="text-danger">
                                Произошла ошибка. Не удалось загрузить данные
                            </h3>
                        )}

                        <Text
                            fontSize="md"
                            color="gray.500"
                            fontWeight="400"
                            mb="30px"
                        >
                            {description ? description : "Описания нет"}
                        </Text>
                        <Flex align="center" mb="18px">
                            <Text
                                fontSize="md"
                                color={textColor}
                                fontWeight="bold"
                                me="10px"
                            >
                                Полное имя:{" "}
                            </Text>

                            <Text
                                fontSize="md"
                                color="gray.500"
                                fontWeight="400"
                            >
                                {name}
                            </Text>
                        </Flex>
                        <Flex align="center" mb="18px">
                            <Text
                                fontSize="md"
                                color={textColor}
                                fontWeight="bold"
                                me="10px"
                            >
                                Email:{" "}
                            </Text>

                            <Text
                                fontSize="md"
                                color="gray.500"
                                fontWeight="400"
                            >
                                {email}
                            </Text>
                        </Flex>
                        {mobile && (
                            <Flex align="center" mb="18px">
                                <Text
                                    fontSize="md"
                                    color={textColor}
                                    fontWeight="bold"
                                    me="10px"
                                >
                                    Мобильный телефон:{" "}
                                </Text>

                                <Text
                                    fontSize="md"
                                    color="gray.500"
                                    fontWeight="400"
                                >
                                    {mobile}
                                </Text>
                            </Flex>
                        )}
                        {rate && (
                            <Flex align="center" mb="18px">
                                <Text
                                    fontSize="md"
                                    color={textColor}
                                    fontWeight="bold"
                                    me="10px"
                                >
                                    Информация о тарифе:{" "}
                                </Text>

                                <Text
                                    fontSize="md"
                                    color="gray.500"
                                    fontWeight="400"
                                >
                                    {rate}
                                </Text>
                            </Flex>
                        )}
                        {job && (
                            <Flex align="center" mb="18px">
                                <Text
                                    fontSize="md"
                                    color={textColor}
                                    fontWeight="bold"
                                    me="10px"
                                >
                                    Должность:{" "}
                                </Text>

                                <Text
                                    fontSize="md"
                                    color="gray.500"
                                    fontWeight="400"
                                >
                                    {job}
                                </Text>
                            </Flex>
                        )}
                        <Flex
                            display="flex"
                            alignItems="center"
                            mb="18px"
                            gap="10px"
                        >
                            <Text
                                fontSize="md"
                                color={textColor}
                                fontWeight="bold"
                                me="10px"
                                margin={0}
                            >
                                Социальные сети:{" "}
                            </Text>
                            <Flex>
                                <Link
                                    href="#"
                                    color="maincolor"
                                    fontSize="lg"
                                    me="10px"
                                    _hover={{color: "maincolor"}}
                                >
                                    <Icon as={FaFacebook} />
                                </Link>
                                <Link
                                    href="#"
                                    color="maincolor"
                                    fontSize="lg"
                                    me="10px"
                                    _hover={{color: "maincolor"}}
                                >
                                    <Icon as={FaInstagram} />
                                </Link>
                                <Link
                                    href="#"
                                    color="maincolor"
                                    fontSize="lg"
                                    me="10px"
                                    _hover={{color: "maincolor"}}
                                >
                                    <Icon as={FaTwitter} />
                                </Link>
                            </Flex>
                        </Flex>
                    </Flex>
                </CardBody>
            </Card>
        </>
    );
};

export default ProfileInformation;
