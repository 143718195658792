import { useDispatch } from "react-redux";
import { sendCode } from "app/store/actions/userActions";

export const useGetCode = (mail) => {
  const dispatch = useDispatch();

  const handleGetCode = () => {
    try {
      dispatch(sendCode(mail));
      return { success: true };
    } catch (error) {
      return { success: false, error: 'Ошибка отправки кода' };
    }
  };

  return {
    getCode: handleGetCode,
  };
};