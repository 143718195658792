export const List = (props) => {
    const {
        data,
        itemContentRender,
        listStyle,
        itemStyle
    } = props;

    return (
        <ul className={listStyle}>
            {data.map((item, index) => (
                <li key={index} className={itemStyle}>
                    {itemContentRender(item)}
                </li>
            ))}
        </ul>
    );
};

