import { RouterProvider } from "react-router-dom";
import { router } from "./routers/router";

const user = localStorage.getItem('userInfo');

function App() {
    return (
        <RouterProvider router={router} />
    );
}

export default App;