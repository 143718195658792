import styles from "./Preloader.module.scss";

export const Preloader = (props) => {
    const {
        children,
    } = props;

    return (
        <section className={styles.preloader}>
            {children}
        </section>
    );
};