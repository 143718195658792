export function nameValidation(value) {
    if (value.length === 0) {
        return {
            value: value,
            state: 'empty'
        };
    } else {
        return {
            value: value,
            state: 'success'
        };
    }
}