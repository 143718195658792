import axios from "axios";

import {
    BRANCH_ALL_FAIL,
    BRANCH_ALL_REQUEST,
    BRANCH_ALL_SUCCESS,
    BRANCH_GET_ONE_FAIL,
    BRANCH_GET_ONE_REQUEST,
    BRANCH_GET_ONE_SUCCESS,
    BRANCH_UPDATE_FAIL,
    BRANCH_UPDATE_REQUEST,
    BRANCH_UPDATE_SUCCESS,
} from "../utils/constants/branchConstants";

import {ERROR_500, ERROR_504} from "../utils/constants/errorConstants";

// export const createBranch = (branchInfo) => async (dispatch, getState) => {
//     try {
//         dispatch({
//             type: BRANCH_CREATE_REQUEST,
//         });

//         const {
//             userAuthentication: {userInfo},
//         } = getState();

//         const config = {
//             headers: {
//                 "Content-type": "application/json",
//                 Authorization: `Bearer ${userInfo.token}`,
//             },
//         };

//         const {data} = await axios.post(
//             `/branch/create/`,
//             branchInfo,
//             config
//         );

//         dispatch({
//             type: BRANCH_CREATE_SUCCESS,
//             payload: data,
//         });
//     } catch (error) {
//         if (error.response) {
//             switch (error.response.status) {
//                 case 400:
//                     dispatch({
//                         type: BRANCH_CREATE_FAIL,
//                         payload: "Ошибка при создании",
//                     });
//                     break;

//                 case 401:
//                     dispatch({
//                         type: BRANCH_CREATE_FAIL,
//                         payload: "Пустой или неправильный токен",
//                     });
//                     break;

//                 case 403:
//                     dispatch({
//                         type: BRANCH_CREATE_FAIL,
//                         payload: "Ошибка доступа",
//                     });
//                     break;

//                 case 404:
//                     dispatch({
//                         type: BRANCH_CREATE_FAIL,
//                         payload: "Компания не найдена",
//                     });
//                     break;
//                 case 500:
//                     dispatch({
//                         type: BRANCH_CREATE_FAIL,
//                         payload: "500",
//                     });
//                     dispatch({
//                         type: ERROR_500,
//                         payload: true,
//                     });

//                     break;
//                 default:
//                     dispatch({
//                         type: BRANCH_CREATE_FAIL,
//                         payload: "Произошла ошибка" + error,
//                     });
//                     break;
//             }
//         } else {
//             dispatch({
//                 type: BRANCH_CREATE_FAIL,
//                 payload: error.message,
//             });
//             if (error.message === "timeout of 5000ms exceeded") {
//                 dispatch({
//                     type: ERROR_504,
//                     payload: true,
//                 });
//             }
//         }
//     }
// };

export const getListBranches = (listID) => async (dispatch, getState) => {
    try {
        dispatch({
            type: BRANCH_ALL_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.get(
            `/branch/read/list/${listID}`,
            config
        );

        dispatch({
            type: BRANCH_ALL_SUCCESS,
            payload: data,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: BRANCH_ALL_FAIL,
                        payload: "Ошибка при запросе",
                    });
                    break;

                case 401:
                    dispatch({
                        type: BRANCH_ALL_FAIL,
                        payload: "Пустой или неправильный токен",
                    });
                    break;

                case 403:
                    dispatch({
                        type: BRANCH_ALL_FAIL,
                        payload: "Ошибка доступа",
                    });
                    break;

                case 404:
                    dispatch({
                        type: BRANCH_ALL_FAIL,
                        payload: "Компания не найдена",
                    });
                    break;
                case 500:
                    dispatch({
                        type: BRANCH_ALL_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;

                default:
                    dispatch({
                        type: BRANCH_ALL_FAIL,
                        payload: "Произошла ошибка" + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: BRANCH_ALL_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};

export const getPageListBranches =
    (listID, page) => async (dispatch, getState) => {
        try {
            dispatch({
                type: BRANCH_ALL_REQUEST,
            });

            const {
                userAuthentication: {userInfo},
            } = getState();

            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const {data} = await axios.get(
                `/branch/read/list/${listID}?page=${page}`,
                config
            );

            dispatch({
                type: BRANCH_ALL_SUCCESS,
                payload: data,
            });
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        dispatch({
                            type: BRANCH_ALL_FAIL,
                            payload: "Ошибка при запросе",
                        });
                        break;

                    case 401:
                        dispatch({
                            type: BRANCH_ALL_FAIL,
                            payload: "Пустой или неправильный токен",
                        });
                        break;

                    case 403:
                        dispatch({
                            type: BRANCH_ALL_FAIL,
                            payload: "Ошибка доступа",
                        });
                        break;

                    case 404:
                        dispatch({
                            type: BRANCH_ALL_FAIL,
                            payload: "Компания не найдена",
                        });
                        break;

                    case 500:
                        dispatch({
                            type: BRANCH_ALL_FAIL,
                            payload: "500",
                        });
                        dispatch({
                            type: ERROR_500,
                            payload: true,
                        });

                        break;

                    default:
                        dispatch({
                            type: BRANCH_ALL_FAIL,
                            payload: "Произошла ошибка" + error,
                        });
                        break;
                }
            } else {
                dispatch({
                    type: BRANCH_ALL_FAIL,
                    payload: error.message,
                });
                if (error.message === "timeout of 5000ms exceeded") {
                    dispatch({
                        type: ERROR_504,
                        payload: true,
                    });
                }
            }
        }
    };

export const getOneBranch = (branchID) => async (dispatch, getState) => {
    try {
        dispatch({
            type: BRANCH_GET_ONE_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();
        
        const config = {
            headers: {
                "Content-type": "application/json",
            },
        };

        const {data} = await axios.get(`/qrcode/read/slug/${branchID}`, config);

        dispatch({
            type: BRANCH_GET_ONE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: BRANCH_GET_ONE_FAIL,
                        payload: "Ошибка при запросе",
                    });
                    break;

                case 401:
                    dispatch({
                        type: BRANCH_GET_ONE_FAIL,
                        payload: "Пустой или неправильный токен",
                    });
                    break;

                case 403:
                    dispatch({
                        type: BRANCH_GET_ONE_FAIL,
                        payload: "Ошибка доступа",
                    });
                    break;

                case 404:
                    dispatch({
                        type: BRANCH_GET_ONE_FAIL,
                        payload: "Компания не найдена",
                    });
                    break;

                case 500:
                    dispatch({
                        type: BRANCH_GET_ONE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;

                default:
                    dispatch({
                        type: BRANCH_GET_ONE_FAIL,
                        payload: "Произошла ошибка" + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: BRANCH_GET_ONE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};

export const updateBranch = (branchID, info) => async (dispatch, getState) => {
    try {
        dispatch({
            type: BRANCH_UPDATE_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        if (info.address === "") delete info.address;
        if (info.description === "") delete info.description;
        if (info.short_description === "") delete info.short_description;
        if (info.phone_number === "") delete info.phone_number;
        if (info.email === "") delete info.email;

        const {data} = await axios.put(
            `/branch/update/${branchID}`,
            info,
            config
        );

        dispatch({
            type: BRANCH_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: BRANCH_UPDATE_FAIL,
                        payload: "Ошибка при запросе",
                    });
                    break;

                case 401:
                    dispatch({
                        type: BRANCH_UPDATE_FAIL,
                        payload: "Пустой или неправильный токен",
                    });
                    break;

                case 403:
                    dispatch({
                        type: BRANCH_UPDATE_FAIL,
                        payload: "Ошибка доступа",
                    });
                    break;

                case 404:
                    dispatch({
                        type: BRANCH_UPDATE_FAIL,
                        payload: "Филиал не найден",
                    });
                    break;

                case 500:
                    dispatch({
                        type: BRANCH_UPDATE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;

                default:
                    dispatch({
                        type: BRANCH_UPDATE_FAIL,
                        payload: "Произошла ошибка" + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: BRANCH_UPDATE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};