import { useEffect } from "react";
import { getAllReviewsAction, getAllReviewsSliceListAction } from "../../../../entities/review";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import "app/styles/shared/pagination.scss";

import { paginationGenerate } from "shared/helpers/pagination";

import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    Spinner,
    Stack,
    StackDivider,
    Text,
} from "@chakra-ui/react";

import { Pagination } from "react-bootstrap";

const Reviews = ({pageNumber}) => {
    const dispatch = useDispatch();

    let navigate = useNavigate();

    const reviewAll = useSelector((state) => state.reviewAll);
    const {allReviews, loading, error} = reviewAll;

    const {reviews} = allReviews;

    const allCompanies = useSelector((state) => state.allCompanies);
    const {companies} = allCompanies;

    useEffect(() => {
        if (!pageNumber.split("?page=")[1]) {
            dispatch(getAllReviewsAction(companies[0].id));
        } else {
            dispatch(
                getAllReviewsSliceListAction(
                    companies[0].id,
                    pageNumber.split("?page=")[1]
                )
            );
        }
    }, []);

    let items = [];

    const handleNext = () => {
        if (allReviews.page < allReviews.pages) {
            navigate(`/dashboard/reviews?page=${allReviews.page + 1}`);
            dispatch(getAllReviewsSliceListAction(companies[0].id, allReviews.page + 1));
            window.scrollTo(0, 0);
        }
    };

    const handlePrev = () => {
        if (allReviews.page > 1) {
            navigate(`/dashboard/reviews?page=${allReviews.page - 1}`);
            dispatch(getAllReviewsSliceListAction(companies[0].id, allReviews.page - 1));
            window.scrollTo(0, 0);
        }
    };

    const handleFirst = () => {
        navigate(`/dashboard/reviews?page=${1}`);
        dispatch(getAllReviewsSliceListAction(companies[0].id, 1));
        window.scrollTo(0, 0);
    };

    const handleLast = () => {
        navigate(`/dashboard/reviews?page=${allReviews.pages}`);
        dispatch(getAllReviewsSliceListAction(companies[0].id, allReviews.pages));
        window.scrollTo(0, 0);
    };

    const {startPage, endPage} = paginationGenerate(
        allReviews.pages,
        allReviews.page
    );

    for (let number = startPage; number <= endPage; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === allReviews.page}
                className="mx-1 my-3 pagination-styles"
                onClick={() => {
                    navigate(`/dashboard/reviews?page=${number}`);
                    dispatch(getAllReviewsSliceListAction(companies[0].id, number));
                    window.scrollTo(0, 0);
                }}
            >
                {number}
            </Pagination.Item>
        );
    }

    return (
        <Flex direction="column">
            <Card w={{sm: "100%", md: "70%"}}>
                <CardHeader>
                    <Heading size="md">Все отзывы</Heading>
                </CardHeader>
                {!error && reviews.length && !loading ? (
                    <CardBody>
                        <Stack divider={<StackDivider />} spacing="4">
                            {reviews.map((review) => (
                                <Box key={review.id}>
                                    <Heading
                                        size="xs"
                                        textTransform="uppercase"
                                        _hover={{
                                            cursor: "pointer",
                                            color: "#4584FF",
                                        }}
                                        onClick={() =>
                                            navigate(
                                                `/dashboard/reviews/review/${review.id}`
                                            )
                                        }
                                    >
                                        {review.full_name}
                                    </Heading>
                                    <Text pt="2" fontSize="sm">
                                        Оценка: {review.rating}
                                    </Text>
                                    <Text pt="2" fontSize="sm">
                                        {review.body
                                            ? review.body
                                            : "Пустой отзыв"}
                                    </Text>
                                </Box>
                            ))}
                        </Stack>
                    </CardBody>
                ) : loading ? (
                    <Flex
                        mt="100px"
                        mb="100px"
                        h="40vh"
                        justifyContent="center"
                    >
                        <Spinner w="100px" h="100px" thickness="10px" />
                    </Flex>
                ) : (
                    <Text textAlign="center" size="md" mb="20px">
                        Отзывов нет
                    </Text>
                )}
            </Card>

            {!error && allReviews.pages > 1 && !loading && (
                <Pagination>
                    <Pagination.First
                        onClick={handleFirst}
                        className="mx-1 my-3 pagination-styles"
                    />
                    <Pagination.Prev
                        onClick={handlePrev}
                        className="mx-1 my-3 pagination-styles"
                    />
                    {items}
                    <Pagination.Next
                        onClick={handleNext}
                        className="mx-1 my-3 pagination-styles"
                    />
                    <Pagination.Last
                        onClick={handleLast}
                        className="mx-1 my-3 pagination-styles"
                    />
                </Pagination>
            )}
        </Flex>
    );
};

export default Reviews;
