import { Button } from "shared/ui/Button";
import styles from "./RatingWindow.module.scss";

export const RatingWindow = ({
    callBack,
}) => {
    return (
        <div className={styles.ratingPage}>
            <div className={styles.button__holder}>
                <div className={styles.button__wrapper}>
                    <Button
                        className={styles.ratingPage__button}
                        onClick={() => callBack(false)}
                    >
                        <svg className={styles.ratingPage__icon} viewBox="0 0 120 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M60 1.9375C26.8548 1.9375 0 28.8203 0 62C0 95.1797 26.8548 122.062 60 122.062C93.1452 122.062 120 95.1797 120 62C120 28.8203 93.1452 1.9375 60 1.9375ZM60 110.438C33.3145 110.438 11.6129 88.7133 11.6129 62C11.6129 35.2867 33.3145 13.5625 60 13.5625C86.6855 13.5625 108.387 35.2867 108.387 62C108.387 88.7133 86.6855 110.438 60 110.438ZM40.6452 58.125C44.9274 58.125 48.3871 54.6617 48.3871 50.375C48.3871 46.0883 44.9274 42.625 40.6452 42.625C36.3629 42.625 32.9032 46.0883 32.9032 50.375C32.9032 54.6617 36.3629 58.125 40.6452 58.125ZM79.3548 58.125C83.6371 58.125 87.0968 54.6617 87.0968 50.375C87.0968 46.0883 83.6371 42.625 79.3548 42.625C75.0726 42.625 71.6129 46.0883 71.6129 50.375C71.6129 54.6617 75.0726 58.125 79.3548 58.125Z" fill="#FF5454"/>
                            <path d="M60.0096 85.2202C52.1467 85.2202 44.7193 88.7077 39.6871 94.7623C37.6306 97.2327 33.9532 97.5475 31.5096 95.5131C29.0419 93.4545 28.7032 89.7975 30.7596 87.3272C38.0177 78.6084 48.6871 73.6194 60.0096 73.6194C71.3322 73.6194 82.0016 78.6084 89.2596 87.3272C91.2919 89.7975 90.9774 93.4545 88.5096 95.5131C86.0661 97.5475 82.3887 97.2327 80.3322 94.7623C75.3 88.6834 67.8725 85.2202 60.0096 85.2202Z" fill="#FF5454"/>
                        </svg>
                    </Button>
                    <p className={styles.button__text}>
                        Плохо
                    </p>
                </div>
                <div className={styles.button__wrapper}>
                    <Button
                        className={styles.ratingPage__button}
                        onClick={() => callBack(true)}
                    >
                        <svg className={styles.ratingPage__icon} viewBox="0 0 120 122" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M60 0.9375C26.8548 0.9375 0 27.8203 0 61C0 94.1797 26.8548 121.062 60 121.062C93.1452 121.062 120 94.1797 120 61C120 27.8203 93.1452 0.9375 60 0.9375ZM60 109.438C33.3145 109.438 11.6129 87.7133 11.6129 61C11.6129 34.2867 33.3145 12.5625 60 12.5625C86.6855 12.5625 108.387 34.2867 108.387 61C108.387 87.7133 86.6855 109.438 60 109.438ZM40.6452 57.125C44.9274 57.125 48.3871 53.6617 48.3871 49.375C48.3871 45.0883 44.9274 41.625 40.6452 41.625C36.3629 41.625 32.9032 45.0883 32.9032 49.375C32.9032 53.6617 36.3629 57.125 40.6452 57.125ZM79.3548 57.125C83.6371 57.125 87.0968 53.6617 87.0968 49.375C87.0968 45.0883 83.6371 41.625 79.3548 41.625C75.0726 41.625 71.6129 45.0883 71.6129 49.375C71.6129 53.6617 75.0726 57.125 79.3548 57.125ZM80.3226 74.7078C75.2903 80.7625 67.8629 84.25 60 84.25C52.1371 84.25 44.7097 80.7867 39.6774 74.7078C37.621 72.2375 33.9435 71.9227 31.5 73.957C29.0323 76.0156 28.7177 79.6727 30.75 82.143C38.0081 90.8617 48.6774 95.8508 60 95.8508C71.3226 95.8508 81.9919 90.8617 89.25 82.143C91.3064 79.6727 90.9677 76.0156 88.5 73.957C86.0564 71.9227 82.379 72.2375 80.3226 74.7078Z" fill="#38C4A2"/>
                        </svg>
                    </Button>
                    <p className={styles.button__text}>
                        Хорошо
                    </p>
                </div>
            </div>
            <p className={styles.footer__message}> 
                Спасибо, что уделили свое время.<br />
                Пожалуйста, выберите один из вариантов
            </p>
        </div>
    );
};