export default function cn(...classNames) {
    const flattenedClassNames = classNames.flat().filter(className => className != null);
    const classArray = flattenedClassNames.map(className => {
        if (Array.isArray(className)) {
            return Object.keys(className(0)).filter(key => className[0][key].join(' '));
        } else {
            return className;
        }
    });

    const joinedClassNames = classArray.join(' ').trim();

    return joinedClassNames;
}