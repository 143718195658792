import {
    Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text
} from "@chakra-ui/react";

import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getAllConnects } from "../../../app/store/actions/connectActions";
import { getOneCode } from "../../../app/store/actions/QRActions";

import Connections from "./ui/Connections";

import DescriptionCard from "./ui/DescriptionCard";

import QRcodeBranch from "./ui/QRcodeBranch";
import Reviews from "./ui/Rewievs";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDidMount } from "../../../shared/hooks/useDidMount";
import { usePushNotification } from "../../../shared/hooks/usePushNotification";
import { getBranchAction } from "../../../entities/branch";

export default function Branch(props) {
    const { id } = useParams();

    const location = useLocation();
    const navigate = useNavigate();

    const page = location.search;

    const dispatch = useDispatch();

    const didMount = useDidMount();

    const branchUpdate = useSelector((state) => state.branchUpdate);
    const branchGetOne = useSelector((state) => state.branchGetOne);
    const getBranchDetails = useSelector((state) => state.getBranchDetails);

    const connectDelete = useSelector((state) => state.connectDelete);
    const connectUpdate = useSelector((state) => state.connectUpdate);
    const connectCreate = useSelector((state) => state.connectCreate);

    const {error: errorConnectCreate, loading: loadingConnectCreate} = connectCreate;
    const {error: errorConnectDelete, loading: loadingConnectDelete} = connectDelete;
    const {error: errorConnectUpdate, loading: loadingConnectUpdate} = connectUpdate;
    
    const QRCreate = useSelector((state) => state.QRCreate);

    const {error: errorQRCreate, loading: loadingQRCreate} = QRCreate;

    // const {branch, error, loading} = branchGetOne;
    const {
        detailsBranch,
        error,
        loading,
    } = getBranchDetails;

    const {error: errorUpdateBranch, loading: loadingUpdateBranch} = branchUpdate;  

    const [tabIndex, setTabIndex] = useState(0);

    const handleStep = () =>
        navigate(`/dashboard/branches/branch/${id}?desk=${tabIndex}`);

    const handleTabsChange = (index) => setTabIndex(index);

    useEffect(() => {
        if (Number(page.split("desk=")[1])) {
            const num = Number(page.split("desk=")[1]);
            setTabIndex(num);
        }
        if (page.split("desk=")[1]) {
            if (page.split("desk=")[1].split("/")[0]) {
                const num = Number(page.split("desk=")[1].split("/")[0]);
                setTabIndex(num);
            }
        }
    }, []);

    usePushNotification(
        errorConnectCreate,
        loadingConnectCreate,
        didMount,
        "Соединение создано успешно",
        "Произошла ошибка при создании соединения",
        connectCreate
    );

    usePushNotification(
        errorConnectDelete,
        loadingConnectDelete,
        didMount,
        "Соединение удалено успешно",
        "Произошла ошибка при удалении соединения",
        connectDelete
    );

    usePushNotification(
        errorConnectUpdate,
        loadingConnectUpdate,
        didMount,
        "Соединение обновлено успешно",
        "Произошла ошибка при обновении соединения",
        connectUpdate
    );

    usePushNotification(
        errorUpdateBranch,
        loadingUpdateBranch,
        didMount,
        "Данные о филиале обновлены успешно",
        "Произошла ошибка при обновлении данных о филиале",
        branchUpdate
    );

    usePushNotification(
        errorQRCreate,
        loadingQRCreate,
        didMount,
        "QR-код создан успешно",
        "Произошла ошибка при создании QR-кода",
        QRCreate
    );
    
    useEffect(() => {
        dispatch(getBranchAction(id));
    }, [branchUpdate]);
    
    useEffect(() => {
        dispatch(getAllConnects(id));
    }, [connectDelete, connectUpdate, connectCreate]);

    useEffect(() => {
        dispatch(getOneCode(id));
    }, [QRCreate]);

    return (
        <Flex flexDirection="column" pt={{base: "120px", md: "75px"}}>
            <Text fontSize="2xl" align="center" color="facebook">
                Филиал
            </Text>
            <Tabs
                variant="soft-rounded"
                mt="20px"
                index={tabIndex}
                onChange={handleTabsChange}
            >
                <TabList flexWrap="wrap">
                    <Tab onClick={handleStep}>Информация о филиале</Tab>
                    <Tab onClick={handleStep}>Отзывы</Tab>
                    <Tab onClick={handleStep}>Соединения</Tab>
                    <Tab onClick={handleStep}>QR-код</Tab>
                </TabList>
                <TabPanels>
                        <TabPanel>
                            <DescriptionCard
                                branchId={id}
                                error={error}
                                branchInfo={detailsBranch}
                                loading={loading}
                            />
                        </TabPanel>
                    <TabPanel>
                        <Reviews branchId={id} pageNumber={page} />
                    </TabPanel>
                    <TabPanel>
                        <Connections branchId={id} />
                    </TabPanel>
                    <TabPanel>
                        <QRcodeBranch branchId={id} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
    );
}
