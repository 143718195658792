import axios from "axios";
import {
    USER_DETAILS_RESET,
    USER_DETAILS_SUCCESS,
    USER_SEND_CODE_FAIL,
    USER_SEND_CODE_REQUEST,
    USER_SEND_CODE_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_REGISTER_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_UPDATE_FAIL,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_VERIFY_FAIL,
    USER_VERIFY_CODE_FAIL,
    USER_VERIFY_CODE_REQUEST,
    USER_VERIFY_CODE_SUCCESS,
    USER_VERIFY_REQUEST,
    USER_VERIFY_SUCCESS,
} from "../utils/constants/userConstants";

import { ERROR_500, ERROR_504 } from "../utils/constants/errorConstants";

// user verify
export const login = (email, password) => async (dispatch) => {
    try {
        // USER_VERIFY_REQUEST
        dispatch({
            type: USER_LOGIN_REQUEST,
        });

        const config = {
            headers: {
                "Content-type": "application/json",
            },
        };

        const {data} = await axios.post(
            "/user/verify/",
            {email: email, password: password},
            config
        );

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    dispatch({
                        type: USER_LOGIN_FAIL,
                        payload: "Вы не зарегистрированы",
                    });
                    break;
                case 400:
                    dispatch({
                        type: USER_LOGIN_FAIL,
                        payload: "Ошибка авторизации. Попробуйте еще раз",
                    });
                    break;

                case 403:
                    dispatch({
                        type: USER_LOGIN_FAIL,
                        payload: error.response.data,
                    });
                    break;

                case 500:
                    dispatch({
                        type: USER_LOGIN_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });
                    break;

                default:
                    dispatch({
                        type: USER_LOGIN_FAIL,
                        payload: "Ошибка авторизации. Попробуйте еще раз",
                    });
            }
        } else {
            dispatch({
                type: USER_LOGIN_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};

export const sendCode = (email) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_SEND_CODE_REQUEST,
        });

        const config = {
            headers: {
                "Content-type": "application/json",
            }
        };

        const data = await axios.post(`/user/send_code/`, { email: email }, config);

        dispatch({
            type: USER_SEND_CODE_SUCCESS
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 500:
                    dispatch({
                        type: USER_SEND_CODE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });
                    break;
                default:
                    dispatch({
                        type: USER_SEND_CODE_FAIL,
                        payload:
                            error.response && error.response.data.detail
                                ? error.response.data.detail
                                : error.message,
                    });
                    break;
            }
        } else {
            dispatch({
                type: USER_SEND_CODE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};

// USER_VERIFY_CODE

export const verifyCode = (code, email) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_VERIFY_CODE_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const {data} = await axios.post('/user/verify_code/', {
            verify_code: code,
            email: email

        }, config);

        dispatch({
            type: USER_VERIFY_CODE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 500:
                    dispatch({
                        type: USER_VERIFY_CODE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });
                    break;
                default:
                    dispatch({
                        type: USER_VERIFY_CODE_FAIL,
                        payload:
                            error.response && error.response.data.detail
                                ? error.response.data.detail
                                : error.message,
                    });
                    break;
            }
        } else {
            dispatch({
                type: USER_VERIFY_CODE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};

export const logout = () => (dispatch) => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("company");
    dispatch({type: USER_LOGOUT});
    dispatch({type: USER_DETAILS_RESET});
};

export const register =
    (first_name, last_name, email, password) => async (dispatch) => {
        try {
            dispatch({
                type: USER_REGISTER_REQUEST,
            });

            const config = {
                headers: {
                    "Content-type": "application/json",
                },
            };

            const {data} = await axios.post(
                "/user/create/",
                {
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    password: password,
                },
                config
            );

            dispatch({
                type: USER_REGISTER_SUCCESS,
                payload: data,
            });
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        dispatch({
                            type: USER_REGISTER_FAIL,
                            payload: "Ошибка при регистрации. Попробуйте позже",
                        });
                        break;
                    case 422:
                        dispatch({
                            type: USER_REGISTER_FAIL,
                            payload: "Вы не заполнили одно из полей",
                        });
                        break;

                    case 500:
                        dispatch({
                            type: USER_REGISTER_FAIL,
                            payload: "500",
                        });
                        dispatch({
                            type: ERROR_500,
                            payload: true,
                        });
                        break;

                    default:
                        dispatch({
                            type: USER_REGISTER_FAIL,
                            payload:
                                error.response && error.response.data.detail
                                    ? error.response.data.detail
                                    : error.message,
                        });
                        break;
                }
            } else {
                dispatch({
                    type: USER_REGISTER_FAIL,
                    payload:
                        error.response && error.response.data.detail
                            ? error.response.data.detail
                            : error.message,
                });
                if (error.message === "timeout of 5000ms exceeded") {
                    dispatch({
                        type: ERROR_504,
                        payload: true,
                    });
                }
            }
        }
    };

export const verify = (code) => async (dispatch) => {
    try {
        dispatch({
            type: USER_VERIFY_REQUEST,
        });

        const config = {
            headers: {
                "Content-type": "application/json",
            },
        };

        const {data} = await axios.get(`/user/verify/${code}`, config);

        dispatch({
            type: USER_VERIFY_SUCCESS,
            payload: data,
        });

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data,
        });

        dispatch({
            type: USER_VERIFY_CODE_SUCCESS,
        });

    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: USER_VERIFY_FAIL,
                        payload: "Ошибка при регистрации. Попробуйте позже",
                    });
                    break;
                case 401:
                    dispatch({
                        type: USER_VERIFY_FAIL,
                        payload: "Пустой или неправильный токен",
                    });
                    break;
                case 404:
                    dispatch({
                        type: USER_VERIFY_FAIL,
                        payload: "Пользователь не найден",
                    });
                    break;
                case 500:
                    dispatch({
                        type: USER_VERIFY_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });
                    break;
                default:
                    dispatch({
                        type: USER_REGISTER_FAIL,
                        payload:
                            error.response && error.response.data.detail
                                ? error.response.data.detail
                                : error.message,
                    });
                    break;
            }
        } else {
            dispatch({
                type: USER_REGISTER_FAIL,
                payload:
                    error.response && error.response.data.detail
                        ? error.response.data.detail
                        : error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};

export const updateUser = (userData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_UPDATE_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        if (userData.description === "") delete userData.description;
        if (userData.phone_number === "") delete userData.phone_number;
        if (userData.job_title === "") delete userData.job_title;
        // if (userData.email === "") delete userData.email;
        if (userData.password === "") delete userData.password;

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.put(`/user/update/`, userData, config);

        dispatch({
            type: USER_UPDATE_SUCCESS,
        });

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 500:
                    dispatch({
                        type: USER_UPDATE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });
                    break;
                default:
                    dispatch({
                        type: USER_UPDATE_FAIL,
                        payload:
                            error.response && error.response.data.detail
                                ? error.response.data.detail
                                : error.message,
                    });
                    break;
            }
        } else {
            dispatch({
                type: USER_UPDATE_FAIL,
                payload:
                    error.response && error.response.data.detail
                        ? error.response.data.detail
                        : error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};
