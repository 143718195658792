import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Heading,
    Stack,
    StackDivider,
    Text,
} from "@chakra-ui/react";

const DescriptionCard = ({companyInfo}) => {
    return (
        <Card
            mt="30px"
            boxShadow="lg"
            w={{sm: "100%", md: "70%"}}
            variant="filled"
        >
            <CardHeader>
                <Heading size="md">Информация о компании</Heading>
            </CardHeader>
                <CardBody>
                    <Stack divider={<StackDivider />} spacing="4">
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                Название
                            </Heading>
                            <Text pt="2" fontSize="sm">
                                {companyInfo.name}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                Дата создания
                            </Heading>

                            <Text pt="2" fontSize="sm">
                                {companyInfo.created_at}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                Владелец
                            </Heading>

                            <Text pt="2" fontSize="sm">
                                {companyInfo.owner.first_name}{" "}
                                {companyInfo.owner.last_name}
                            </Text>
                        </Box>
                    </Stack>
                </CardBody>
            
        </Card>
    );
};

export default DescriptionCard;
