import {
    REVIEW_CREATE_REQUEST,
    REVIEW_CREATE_SUCCESS,
    REVIEW_CREATE_FAIL
} from "./createReviewState";
import { ERROR_500, ERROR_504 } from "app/store/utils/constants/errorConstants";
import axios from "axios";

export const createReview = (arrData, id) => async (dispatch) => {
    const {
        name,
        email,
        phone,
        message,
    } = arrData;

    try {
        const config = {
            headers: {
                "Content-type": "application/json",
            },
        };

        const data = await axios.post(
            `/review/create/`,
            {
                branch_id: id,
                full_name: name,
                email: email,
                phone: phone,
                body: message
            },
            config
        );

        dispatch({
            type: REVIEW_CREATE_SUCCESS,
            payload: data,
        });
    } catch(error) {
        if (error.response) {
            switch (error.response.status) {
                case 500:
                    dispatch({
                        type: REVIEW_CREATE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;
                default:
                    dispatch({
                        type: REVIEW_CREATE_REQUEST,
                        payload: "Произошла ошибка" + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: REVIEW_CREATE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};
