import { Link } from "react-router-dom";
import cn from "shared/helpers/classNames";
import styles from "./Button.module.scss";

export const Button = ({
    children,
    href,
    className,
    onClick,
}) => {

    return (
        <>
            {href ? (
                <Link className={styles.button__link} to={href}>
                    <button
                        className={cn([styles.button__native, className])}
                        href={href}
                        onClick={onClick}
                    >
                        {children}
                    </button>
                </Link>
            ) : (
                <button className={cn([styles.button__native, className])} onClick={onClick}>
                    {children}
                </button>
            )}
        </>
    );
};
