import {
    USER_VERIFY_CODE_REQUEST,
    USER_VERIFY_CODE_SUCCESS,
    USER_VERIFY_CODE_FAIL
} from "../states/verificationCodeState";
import { ERROR_500, ERROR_504 } from "app/store/utils/constants/errorConstants";
import axios from "axios";

export const verifyCode = (code, email) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_VERIFY_CODE_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };
        const {data} = await axios.post('/user/verify_code/', {
            verify_code: code,
            email: email

        }, config);

        dispatch({
            type: USER_VERIFY_CODE_SUCCESS,
            payload: data,
        });

    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 500:
                    dispatch({
                        type: USER_VERIFY_CODE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });
                    break;
                default:
                    dispatch({
                        type: USER_VERIFY_CODE_FAIL,
                        payload:
                            error.response && error.response.data.detail
                                ? error.response.data.detail
                                : error.message,
                    });
                    break;
            }
        } else {
            dispatch({
                type: USER_VERIFY_CODE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};