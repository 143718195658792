import { useDispatch } from "react-redux";
import { userAuthenticationCheck } from "../api/actions/userAuthorizationAction";

export const useUserAuthorization = (mail, password) => {
  const dispatch = useDispatch();

  const handleAuthorization = () => {
    try {
        dispatch(userAuthenticationCheck(mail, password));
        return { success: true };
    } catch (error) {
        return { success: false, error: 'Ошибка авторизации' };
    }
    };

    return {
        authorize: handleAuthorization,
    };
};