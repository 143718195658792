import {
    BRANCH_CREATE_REQUEST, 
    BRANCH_CREATE_SUCCESS, 
    BRANCH_CREATE_FAIL 
} from "./createBranchState";

export const branchCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case BRANCH_CREATE_REQUEST:
            return {loading: true};

        case BRANCH_CREATE_SUCCESS:
            return {loading: false, branch: action.payload};

        case BRANCH_CREATE_FAIL:
            return {loading: false, error: action.payload};

        default:
            return state;
    }
};