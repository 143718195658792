import {
    Badge,
    Button,
    Flex,
    Td,
    Text,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";

function TablesTableRow(props) {
    const {name, codeBranch, subdomain, domain, status, date, idBranch} = props;
    const textColor = useColorModeValue("gray.700", "white");
    const bgStatus = useColorModeValue("gray.400", "#1a202c");
    const colorStatus = useColorModeValue("white", "gray.400");

    let navigate = useNavigate();
    
    const toInfoBranch = () => {
        navigate(`/dashboard/branches/branch/${idBranch}`);
    };

    return (
        <Tr>
            <Td minWidth={{sm: "250px"}} pl="0px">
                <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                    <Flex direction="column" ml="10px">
                        <Text
                            fontSize="md"
                            color={textColor}
                            fontWeight="bold"
                            minWidth="100%"
                            onClick={toInfoBranch}
                            _hover={{color:"#4584FF", cursor: "pointer"}}
                        >
                            {name}
                        </Text>
                        <Text fontSize="sm" color="gray.400" fontWeight="normal">
                            #{codeBranch}
                        </Text>
                    </Flex>
                </Flex>
            </Td>

            <Td>
                <Flex direction="column">
                    <Text fontSize="md" color={textColor} fontWeight="bold">
                        {domain}
                    </Text>
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                        {subdomain}
                    </Text>
                </Flex>
            </Td>
            <Td>
                <Badge
                    bg={status === "Online" ? "green.400" : bgStatus}
                    color={status === "Online" ? "white" : colorStatus}
                    fontSize="16px"
                    p="3px 10px"
                    borderRadius="8px"
                >
                    {status}
                </Badge>
            </Td>
            <Td>
                <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
                    {date}
                </Text>
            </Td>
            <Td>
                <Button p="0px" bg="transparent" variant="no-hover" onClick={toInfoBranch}>
                    <Text
                        fontSize="md"
                        color="gray.400"
                        fontWeight="bold"
                        cursor="pointer"
                    >
                        Редактировать
                    </Text>
                </Button>
            </Td>
        </Tr>
    );
}

export default TablesTableRow;
