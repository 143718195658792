import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    useColorModeValue,
    useDisclosure
} from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAnswer } from "../../../../app/store/actions/answerActions";

const Answer = ({reviewId}) => {
    const reviewGetOne = useSelector((state) => state.reviewGetOne);
    const inputBg = useColorModeValue("white", "gray.800");
    const {isOpen, onOpen, onClose} = useDisclosure();

    const dispatch = useDispatch();

    const {review, loading} = reviewGetOne;

    const [answer, setAnswer] = useState("");
    const [warning, setWarning] = useState("");

    const handleCreate = () => {
        const info = {
            type: 1,
            body: answer,
            review_id: reviewId,
        };
        if (answer !== "") {
            dispatch(createAnswer(info));
            setWarning("");
            setAnswer("");
            onClose();
        } else {
            setWarning("Введите обязательны поля");
        }
    };

    return (
        <Flex mt="20px">
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Ответ на отзыв</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Введите текст ответа *</FormLabel>
                            <Textarea
                                value={answer}
                                onChange={(e) => setAnswer(e.target.value)}
                                bg={inputBg}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Flex direction="column">
                            <Button
                                colorScheme="blue"
                                mr={3}
                                onClick={handleCreate}
                            >
                                Отправить
                            </Button>
                            <Text color="facebook.300">{warning}</Text>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {review.answer ? (
                <Card boxShadow="lg" w={{md: "500px", base: "80vw"}}>
                    <CardHeader>
                        <Heading size="md">Ответ на отзыв</Heading>
                        <Text fontSize="xs">{review.answer.created_at}</Text>
                    </CardHeader>
                    <CardBody>
                        <Box mb="20px">
                            <Text fontWeight="bold">
                                {review.answer.type === 0
                                    ? "Ответ создан автоматически"
                                    : "Ответ от пользователя"}
                            </Text>
                        </Box>
                        <Box>
                            <Text>{review.answer.body}</Text>
                        </Box>
                    </CardBody>
                </Card>
            ) : loading ? <></> : (
                <Button colorScheme="blue" onClick={onOpen}>
                    Оставить ответ
                </Button>
            )}
        </Flex>
    );
};

export default Answer;
