import { ERROR_500, ERROR_504 } from "../model/state";

export const errorReducer = (state = {error500: false, error504: false}, action) => {
    switch (action.type) {

        case ERROR_500:
            return {error500: action.payload, error504: false};

        case ERROR_504:
            return {error500: false, error504: action.payload};

        default:
            return state;
    }
};