export const TELEBOT_CREATE_REQUEST = "TELEBOT_CREATE_REQUEST";
export const TELEBOT_CREATE_SUCCESS = "TELEBOT_CREATE_SUCCESS";
export const TELEBOT_CREATE_FAIL = "TELEBOT_CREATE_FAIL";

export const TELEBOT_DELETE_REQUEST = "TELEBOT_DELETE_REQUEST";
export const TELEBOT_DELETE_SUCCESS = "TELEBOT_DELETE_SUCCESS";
export const TELEBOT_DELETE_FAIL = "TELEBOT_DELETE_FAIL";

export const TELEBOT_READ_REQUEST = "TELEBOT_READ_REQUEST";
export const TELEBOT_READ_SUCCESS = "TELEBOT_READ_SUCCESS";
export const TELEBOT_READ_FAIL = "TELEBOT_READ_FAIL";
export const TELEBOT_READ_RESET = "TELEBOT_READ_RESET";

export const TELEBOT_UPDATE_REQUEST = "TELEBOT_UPDATE_REQUEST";
export const TELEBOT_UPDATE_SUCCESS = "TELEBOT_UPDATE_SUCCESS";
export const TELEBOT_UPDATE_FAIL = "TELEBOT_UPDATE_FAIL";
export const TELEBOT_UPDATE_RESET = "TELEBOT_UPDATE_RESET";