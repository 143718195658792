// chakra imports
import { Box, ChakraProvider, Portal } from "@chakra-ui/react";
import Footer from "../../../components/Footer/Footer.js";
// core components
import theme from "dashboard/theme/theme.js";
import { ErrorPage } from "pages/ErrorPage/index.js";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import AuthNavbar from "../../../components/Navbars/AuthNavbar.js";
import routes from "../../../routers/routes.js";

export default function AuthLayout(props) {
    const {...rest} = props;

    const userAuthentication = useSelector((state) => state.userAuthentication);
    const { isLoggedIn } = userAuthentication;

    let navigate = useNavigate();

    const internalError = useSelector((state) => state.internalError);

    const {error500, error504} = internalError;

    const wrapper = useRef();
    useEffect(() => {
        document.body.style.overflow = "unset";
        // Specify how to clean up after this effect:
    }, []);

    if (isLoggedIn) {
        navigate("/dashboard/");
    }

    const getActiveRoute = (routes) => {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (routes[i].category) {
                let categoryActiveRoute = getActiveRoute(routes[i].views);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(
                        routes[i].layout + routes[i].path
                    ) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].views);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (
                    window.location.href.indexOf(
                        routes[i].layout + routes[i].path
                    ) !== -1
                ) {
                    if (routes[i].secondaryNavbar) {
                        return routes[i].secondaryNavbar;
                    }
                }
            }
        }
        return activeNavbar;
    };
    const navRef = React.useRef();
    document.documentElement.dir = "ltr";
    return (
        <>
            {error500 ? (
                <ErrorPage errorMessage="попробуйте позже" errorStatus="500" />
            ) : error504 ? (
                <ErrorPage errorStatus="504" errorMessage="попробуйте позже" />
            ) : (
                <ChakraProvider theme={theme} resetCss={false} w="100%">
                    <Box ref={navRef} w="100%">
                        <Portal containerRef={navRef}>
                            <AuthNavbar
                                secondary={getActiveNavbar(routes)}
                                logoText="RecTop"
                            />
                        </Portal>
                        <Box w="100%">
                            <Box ref={wrapper} w="100%">
                                <Outlet />
                            </Box>
                        </Box>
                        <Box px="24px" mx="auto" width="1044px" maxW="100%">
                            <Footer />
                        </Box>
                    </Box>
                </ChakraProvider>
            )}
        </>
    );
}
