export const BRANCH_CREATE_REQUEST = 'BRANCH_CREATE_REQUEST';
export const BRANCH_CREATE_SUCCESS = 'BRANCH_CREATE_SUCCESS';
export const BRANCH_CREATE_FAIL = 'BRANCH_CREATE_FAIL';

export const BRANCH_ALL_REQUEST = 'BRANCH_ALL_REQUEST';
export const BRANCH_ALL_SUCCESS = 'BRANCH_ALL_SUCCESS';
export const BRANCH_ALL_FAIL = 'BRANCH_ALL_FAIL';

export const BRANCH_GET_ONE_REQUEST = 'BRANCH_GET_ONE_REQUEST';
export const BRANCH_GET_ONE_SUCCESS = 'BRANCH_GET_ONE_SUCCESS';
export const BRANCH_GET_ONE_FAIL = 'BRANCH_GET_ONE_FAIL';

export const BRANCH_UPDATE_REQUEST = 'BRANCH_UPDATE_REQUEST';
export const BRANCH_UPDATE_SUCCESS = 'BRANCH_UPDATE_SUCCESS';
export const BRANCH_UPDATE_FAIL = 'BRANCH_UPDATE_FAIL';
export const BRANCH_UPDATE_RESET = 'BRANCH_UPDATE_RESET';