export const branchModel = {
    "id": 0,
    "branch": {
        "id": 0,
        "name": "Loading..",
        "address": "Loading..",
        "connect_set": [
            {
                "id": 0,
                "type": "0"
            },
            {
                "id": 1,
                "type": "1"
            }
        ]
    }
};