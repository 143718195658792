// Chakra imports
import {
    Button,
    Flex,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { createCompany } from "../../../app/store/actions/companyActions";
import Branches from "./ui/Branches";

import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getAllCompaniesAction } from "../../../entities/company";
import { useDidMount } from "../../../shared/hooks/useDidMount";

import { usePushNotification } from "../../../shared/hooks/usePushNotification";
import { Preloader } from "widgets/Preloader";
import { SpinnerIcon } from "@chakra-ui/icons";

const Tables = () => {
    const dispatch = useDispatch();
    const inputBg = useColorModeValue("white", "gray.800");
    const textColor = useColorModeValue("gray.700", "white");

    const didMount = useDidMount();

    const {isOpen, onOpen, onClose} = useDisclosure();

    const [nameCompany, setNameCompany] = useState("");
    let location = useLocation();

    const page = location.search;

    const companyCreate = useSelector((state) => state.companyCreate);
    const {error: companyCreateError, loading: companyCreateLoading} = companyCreate;

    useEffect(() => {
        dispatch(getAllCompaniesAction());
    }, [companyCreate]);

    usePushNotification(
        companyCreateError,
        companyCreateLoading,
        didMount,
        "Компания создана успешно",
        "Произошла ошибка при создании компании",
        createCompany
    );

    const allCompanies = useSelector((state) => state.allCompanies);
    const branchAll = useSelector((state) => state.branchAll);
    const {error: branchError} = branchAll;

    const {companies, loading, error} = allCompanies;

    const [warning, setWarning] = useState("");

    const handleCreateCompany = () => {
        if (nameCompany !== "") {
            setWarning("");
            dispatch(createCompany(nameCompany));
            onClose();
        } else {
            setWarning("Заполните, пожалуйста, название компании");
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Создать компанию</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                        >
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                mb="20px"
                            >
                                Название компании *
                            </Text>
                            <Flex align="center" mb="20px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={nameCompany}
                                        onChange={(e) =>
                                            setNameCompany(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="outline" onClick={handleCreateCompany}>
                            Создать
                        </Button>
                        {warning}
                    </ModalFooter>
                </ModalContent>
                </Modal>
            <>
                { companies ? (
                    <Flex direction="column" pt={{base: "120px", md: "75px"}}>
                    {!error && !branchError && companies[0] ? (
                        <Branches
                            title={"Филиалы"}
                            companyInfo={companies[0]}
                            pageNumber={page}
                            loading={loading}
                            captions={["Назание", "Адрес", "Дата создания", ""]}
                        />
                    ) : error || branchError ? (
                        <Text color="red">
                            Призошла ошибка при загрузке данных о компании и
                            филиалах
                        </Text>
                    ) : loading ? (
                        <Flex mt="100px" mb="100px" justifyContent="center">
                            <Spinner w="100px" h="100px" thickness="10px"/>
                        </Flex>
                    ) : (
                        <Flex
                            justify="center"
                            gap="20px"
                            mt="20%"
                            align="center"
                            direction="column"
                        >
                            <Text fontSize="2xl" align="center" color={textColor}>
                                Вы еще не создали компанию
                            </Text>
                            <Button
                                variant="outline"
                                onClick={onOpen}
                                width={{md: "30%"}}
                                colorScheme="facebook"
                            >
                                Добавить компанию
                            </Button>
                        </Flex>
                    )}
                </Flex>
                ): (<Preloader><Spinner w="100px" h="100px" thickness="10px" /></Preloader>)}
            
            </>
        </>
    );
};

export default Tables;
