// Chakra imports
import {
    Button,
    Flex,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Table,
    Tbody,
    Text,
    Textarea,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";

// Custom components
import "app/styles/shared/pagination.scss";
import Card from "dashboard/components/Card/Card.js";
import CardBody from "dashboard/components/Card/CardBody.js";
import CardHeader from "dashboard/components/Card/CardHeader.js";
import TablesTableRow from "dashboard/components/Tables/TablesTableRow";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Pagination } from "react-bootstrap";
import { createBranchAction, getBranchListAction, getBranchListToPage } from "../../../../entities/branch";

import { paginationGenerate } from "shared/helpers/pagination.js";
import { useDidMount } from "shared/hooks/useDidMount.js";

const Branches = ({title, captions, companyInfo, pageNumber}) => {
    const textColor = useColorModeValue("gray.700", "white");
    const inputBg = useColorModeValue("white", "gray.800");

    let navigate = useNavigate();

    const toast = useToast();

    const {isOpen, onOpen, onClose} = useDisclosure();

    const dispatch = useDispatch();

    let branchArray = [];
    const [warning, setWarning] = useState("");

    const [nameBranch, setNameBranch] = useState("");
    const [addressBranch, setAddressBranch] = useState("");
    const [descriptionBranch, setDescriptionBranch] = useState("");
    const [shortDescriptionBranch, setShortDescriptionBranch] = useState("");
    const [mobileBranch, setMobileBranch] = useState("");
    const [emailBranch, setEmailBranch] = useState("");

    const didMount = useDidMount();

    const branchAll = useSelector((state) => state.branchAll);

    const branchCreate = useSelector((state) => state.branchCreate);

    const {branches, error, loading} = branchAll;

    const {loading: createLoading, error: errorCreate} = branchCreate;

    useEffect(() => {
        if (!pageNumber.split("?page=")[1]) {
            dispatch(getBranchListAction(companyInfo.id));
        } else {
            dispatch(
                getBranchListToPage(
                    companyInfo.id,
                    pageNumber.split("?page=")[1]
                )
            );
        }
        
    }, [branchCreate]);

    useEffect(() => {
        if (!errorCreate && didMount && !createLoading) {
            toast({
                title: "Филиал создан успешно",
                position: "bottom-left",
                variant: "subtle"
            });
        }
        if(errorCreate && didMount){
            toast({
                title: "Ошибка при создании филиала",
                position: "bottom-left",
                variant: "subtle",
                status: "error"
            });
        }
    }, [branchCreate]);

    if (!error) {
        branchArray = branches.branches;
    }

    let branchInfoCreate;

    if (companyInfo) {
        branchInfoCreate = {
            name: nameBranch,
            address: addressBranch,
            description: descriptionBranch,
            short_description: shortDescriptionBranch,
            company_id: companyInfo.id,
            phone_number: mobileBranch,
            email: emailBranch,
        };
    }

    const handleCreateBranch = () => {
        if (nameBranch !== "") {
            dispatch(createBranchAction(branchInfoCreate));
            onClose();
            setWarning("");
            setAddressBranch("");
            setDescriptionBranch("");
            setEmailBranch("");
            setMobileBranch("");
            setNameBranch("");
        } else {
            setWarning("Введите обязательные поля");
        }
    };

    let items = [];

    const handleNext = () => {
        if (branches.page < branches.pages) {
            navigate(`/dashboard/branches?page=${branches.page + 1}`);
            dispatch(getBranchListToPage(companyInfo.id, branches.page + 1));
            window.scrollTo(0, 0);
        }
    };

    const handlePrev = () => {
        if (branches.page > 1) {
            navigate(`/dashboard/branches?page=${branches.page - 1}`);
            dispatch(getBranchListToPage(companyInfo.id, branches.page - 1));
            window.scrollTo(0, 0);
        }
    };

    const handleFirst = () => {
        navigate(`/dashboard/branches?page=${1}`);
        dispatch(getBranchListToPage(companyInfo.id, 1));
        window.scrollTo(0, 0);
    };

    const handleLast = () => {
        navigate(`/dashboard/branches?page=${branches.pages}`);
        dispatch(getBranchListToPage(companyInfo.id, branches.pages));
        window.scrollTo(0, 0);
    };

    const {startPage, endPage} = paginationGenerate(
        branches.pages,
        branches.page
    );

    for (let number = startPage; number <= endPage; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === branches.page}
                className="mx-1 my-3 pagination-styles"
                onClick={() => {
                    navigate(`/dashboard/branches?page=${number}`);
                    dispatch(getBranchListToPage(companyInfo.id, number));
                    window.scrollTo(0, 0);
                }}
            >
                {number}
            </Pagination.Item>
        );
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Создать филиал</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                        >
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                mb="20px"
                            >
                                Название филиала *
                            </Text>
                            <Flex align="center" mb="20px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={nameBranch}
                                        onChange={(e) =>
                                            setNameBranch(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 20px 0px"
                            >
                                Адрес
                            </Text>
                            <Flex align="center" mb="20px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={addressBranch}
                                        onChange={(e) =>
                                            setAddressBranch(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 20px 0px"
                            >
                                Описание
                            </Text>
                            <Flex align="center" mb="20px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Textarea
                                        value={descriptionBranch}
                                        fontSize="xs"
                                        py="11px"
                                        onChange={(e) =>
                                            setDescriptionBranch(e.target.value)
                                        }
                                    ></Textarea>
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 20px 0px"
                            >
                                Короткое описание
                            </Text>
                            <Flex align="center" mb="20px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Textarea
                                        fontSize="xs"
                                        py="11px"
                                        value={shortDescriptionBranch}
                                        onChange={(e) =>
                                            setShortDescriptionBranch(
                                                e.target.value
                                            )
                                        }
                                    ></Textarea>
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 20px 0px"
                            >
                                Номер телефона
                            </Text>
                            <Flex align="center" mb="20px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={mobileBranch}
                                        onChange={(e) =>
                                            setMobileBranch(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 20px 0px"
                            >
                                Email
                            </Text>
                            <Flex align="center" mb="20px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={emailBranch}
                                        onChange={(e) =>
                                            setEmailBranch(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="outline" onClick={handleCreateBranch}>
                            Создать
                        </Button>
                        {warning}
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Card overflowX={{sm: "scroll", xl: "hidden"}}>
                <CardHeader p="6px 0px 22px 0px">
                    <Flex direction="column">
                        <Text fontSize="xl" color={textColor} fontWeight="bold">
                            {title}
                        </Text>
                        <Text>Компании {companyInfo.name}</Text>
                    </Flex>
                </CardHeader>

                <>
                {!error && branchArray.length !== 0 && !loading ? (<CardBody>
                        
                            <Table variant="simple" color={textColor}>
                                <Thead>
                                    <Tr my=".8rem" pl="0px" color="gray.400">
                                        {captions.map((caption, idx) => {
                                            return (
                                                <Th
                                                    color="gray.400"
                                                    key={idx}
                                                    ps={
                                                        idx === 0 ? "0px" : null
                                                    }
                                                >
                                                    {caption}
                                                </Th>
                                            );
                                        })}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {branchArray.map((row) => {
                                        return (
                                            <TablesTableRow
                                                key={`${row.email}-${row.name}`}
                                                name={row.name}
                                                codeBranch={row.id}
                                                domain={
                                                    row.address
                                                        ? row.address
                                                        : "Нет информации"
                                                }
                                                status={row.created_at}
                                                idBranch={row.id}
                                            />
                                        );
                                    })}
                                </Tbody>
                            </Table>
                    </CardBody>) : loading ? (
                            <Flex
                                mt="100px"
                                mb="100px"
                                justifyContent="center"
                            >
                                <Spinner w="100px" h="100px" thickness="10px"/>
                            </Flex>
                        ) : error && (
                            <Text color="red">
                                Произошла ошибка при загрузке филиалов
                            </Text>
                        )}
                    {branches.pages > 1 && !loading && (
                        <Pagination>
                            <Pagination.First
                                className="mx-1 my-3 pagination-styles"
                                onClick={handleFirst}
                            />
                            <Pagination.Prev
                                onClick={handlePrev}
                                className="mx-1 my-3 pagination-styles"
                            />
                            {items}
                            <Pagination.Next
                                onClick={handleNext}
                                className="mx-1 my-3 pagination-styles"
                            />
                            <Pagination.Last
                                className="mx-1 my-3 pagination-styles"
                                onClick={handleLast}
                            />
                        </Pagination>
                    )}
                    <Button
                        colorScheme="facebook"
                        variant="outline"
                        onClick={onOpen}
                    >
                        Добавить филиал
                    </Button>
                </>
            </Card>
            {/* {loading && <CircularProgress isIndeterminate color="purple.600"/>} */}
        </>
    );
};

export default Branches;
