export const paginationGenerate = (allPages, currentPage) => {
    const showPages = 3;

    if (showPages >= allPages) {
        return {startPage: 1, endPage: allPages};
    }
    else {
        // startPage = currentPage;
        if (currentPage != allPages && currentPage != 1) {
            return {startPage: currentPage - 1, endPage: currentPage + 1};
        }
        if (currentPage === allPages) {
            return {startPage: allPages - 2, endPage: allPages};
        }
        if (currentPage === 1) {
            return {startPage: currentPage, endPage: currentPage + showPages - 1};
        }
    }
};