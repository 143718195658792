import { useState } from "react";
import { Input } from "shared/ui/Input";
import { nameValidation } from "shared/ui/Input/helpers/validation/nameValidation";
import { emailValidation } from "shared/ui/Input/helpers/validation/emailValidation";
import { phoneValidation } from "shared/ui/Input/helpers/validation/phoneValidation";
import { phoneMask } from "shared/ui/Input/lib/phoneMask";
import { getInputState } from "widgets/ApplicationForm/helpers/getInputState";
import { Textarea } from "shared/ui/Textarea";
import { isAllInputValid } from "widgets/ApplicationForm/helpers/isAllInputValid";
import { errorHandler } from "widgets/ApplicationForm/helpers/errorHandler";
import { dataFormatter } from "../../../helpers/dataFormatter";
import { sendFormDataModel } from "../../../models/sendFormDataModel";
import styles from "./ComplaintForm.module.scss";

export const ComplaintForm = (props) => {
    const {
        model = [],
        sendData,
    } = props;
    const [ data, setData ] = useState(model || []);
    
    const submite = (e) => {
        e.preventDefault();

        if (isAllInputValid(data)) {
            const formatData = dataFormatter(data, sendFormDataModel);
            sendData(formatData);
        } else {
            setData(errorHandler(data));
        }
    };

    const handlerValue = (
        value,
        inputState, 
        type,
    ) => {
        const updateData = data.map((item) => {
            if (item.type === type) {
                return {
                    ...item,
                    value: value,
                    state: inputState
                };
            }

            return item;
        });

        setData(updateData);
    };

    return (
        <div className={styles.complaintForm}>
            <form className={styles.complaintForm__from} onSubmit={submite}>
                <Input
                    dataHandler={handlerValue}
                    validation={nameValidation}
                    errorState={getInputState(data, 'text')}
                    type='text'
                    name='name'
                    placeholder='Ваше имя'
                    maxLength={20}
                    className={styles.complaintForm__input}
                />
                <Input
                    dataHandler={handlerValue}
                    validation={emailValidation}
                    errorState={getInputState(data, 'email')}
                    type='email'
                    name='email'
                    placeholder='Ваш email'
                    maxLength={20}
                    className={styles.complaintForm__input}
                />
                <Input
                    dataHandler={handlerValue}
                    validation={phoneValidation}
                    errorState={getInputState(data, 'phone')}
                    type='phone'
                    name='phone'
                    mask={phoneMask}
                    placeholder='Ваш телефон'
                    maxLength={20}
                    className={styles.complaintForm__input}
                />
                <Textarea
                    dataHandler={handlerValue}
                    validation={nameValidation}
                    errorState={getInputState(data, 'message')}
                    type='text'
                    name='message'
                    placeholder='Сожалеем о вашем негативном опыте. Пожалуйста, опишите ситуацию'
                    maxLength={40}
                    className={styles.complaintForm__textarea}
                />
                <div className={styles.Button__wrapper}>
                    <button className={styles.button} type="submit">
                        Отправить заявку
                    </button>
                </div>
            </form>
        </div>
    );
};