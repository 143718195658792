import {
    USER_AUTHENTICATION_REQUEST,
    USER_AUTHENTICATION_SUCCESS,
    USER_AUTHENTICATION_FAIL
} from "../states/userAuthenticationState";

export const userAuthentificateReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_AUTHENTICATION_REQUEST:
            return {loading: true};

        case USER_AUTHENTICATION_SUCCESS:
            return {loading: false, userInfo: action.payload, isLoggedIn: true};

        case USER_AUTHENTICATION_FAIL:
            return {loading: false, error: action.payload};

        default:
            return state;
    }
};