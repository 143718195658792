import { useEffect, useState } from 'react';
import { getOneBranch } from 'app/store/actions/branchActions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getIdFromUrl } from '../helpers/getIdFromUrl';
import { ComplaintForm } from './components/ComplaintForm/ComplaintForm';
import { CompletedWindow } from './components/CompletedWindow/CompletedWindow';
import { RatingWindow } from './components/RatingWindow/RatingWindow';
import { ReviewWindow } from './components/ReviewWindow/ReviewWindow';
import { SvgIcon } from 'shared/ui/Icon';
import { formData } from '../models/formData';
import { ErrorPage } from 'pages/ErrorPage';
import { Preloader } from 'widgets/Preloader';
import { Spinner } from 'widgets/Preloader';
import { branchModel } from '../models/branchModel';
import styles from "./QRRatesPage.module.scss";
import cn from 'shared/helpers/classNames';
import { createReviewAction } from '../../../entities/review';

export const QRRatesPage = () => {
    /**
     * setRating(null | true | false)
     * null - render <RatingWindow />
     * true - render <ReviewWindow />
     * false - render <ComplaintForm />
     */
    const [ rating, setRating ] = useState(null);
    /**
     * setCompleted(false | true)
     * false - render <RatingWindow />
     * true = render <CompletedWindow />
     */
    const [ branchData, setBranchData ] = useState(branchModel); 
    const [ completed, setCompleted ] = useState(false);
    const dispatch = useDispatch();
    const { slug } = useParams();
    const idUrl = getIdFromUrl(slug);

    const sendFormData = (data) => {
        dispatch(createReviewAction(data, idUrl));
    };
    
    const handleRating = (rating) => {
        setRating(rating);
    };

    const handleCompleted = (completed) => {
        sendFormData(completed);
        setCompleted(completed);
    };

    const branchGetOne = useSelector((state) => state.branchGetOne);
    const { branch, loading, error } = branchGetOne;

    useEffect(() => {
        dispatch(getOneBranch(slug));
    }, [dispatch, slug]);

    useEffect(() => {
        if (branch && branch.branch) {
            setBranchData(branch);
        }
    }, [
        branch,
        branchData
    ]);

    return (
        <>
            { loading ? (
                <Preloader>
                    <Spinner />
                </Preloader>
            ) : (
                <>
                    { error ? (
                        <ErrorPage
                            errorStatus={404}
                            errorMessage={'Страница не найдена'}
                        />
                    ) : (
                        <section className={styles.QRPage}>
                            <div className={cn([
                                styles.QRPage__window,
                                rating === false ? 
                                    styles.QRPage__window_completed :
                                null,
                                completed === true ? 
                                    styles.QRPage__window_completed : 
                                null
                                ])}>
                                    { completed ?
                                        <CompletedWindow /> :
                                        <>
                                            <h2 className={styles.QRPage__title}>
                                                Оцените работу компании
                                            </h2>
                                            <p className={styles.QRPage__info}>
                                                {branchData.branch.name} {branchData.branch.address}
                                            </p>
                                            {   
                                                rating === null ?
                                                    <RatingWindow
                                                        callBack={handleRating}
                                                    /> : 
                                                rating === true ?
                                                    <ReviewWindow
                                                        data={branchData.branch.connect_set}
                                                    /> :
                                                rating === false ? 
                                                    <ComplaintForm
                                                        model={formData}
                                                        sendData={handleCompleted}
                                                    /> : 
                                                null
                                            }
                                            {
                                                rating !== null ?
                                                    <span onClick={() => setRating(null)} className={styles.QRPage__exit}>
                                                        <SvgIcon.closeIcon />
                                                    </span> :
                                                null
                                            }
                                        </> 
                                    }
                            </div>
                        </section>
                    )}
                </>
            )}
        </>
    );
};