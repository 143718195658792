import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import {
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    Spinner,
    Text,
} from "@chakra-ui/react";

import { Pagination } from "react-bootstrap";

import { paginationGenerate } from "../../../../shared/helpers/pagination";

import "app/styles/shared/pagination.scss";

import { getOneReviewsListAction, getOneReviewsSliceListAction } from "../../../../entities/review";

export default function Reviews({branchId, pageNumber}) {
    const reviewList = useSelector((state) => state.reviewList);

    const {reviewsList, error, loading} = reviewList;

    const dispatch = useDispatch();

    let navigate = useNavigate();

    let items = [];

    useEffect(() => {
        if (!pageNumber.split("page=")[1]) {
            dispatch(getOneReviewsListAction(branchId));
        } else {
            dispatch(
                getOneReviewsSliceListAction(branchId, pageNumber.split("page=")[1])
            );
        }
    }, []);

    const handleFirst = () => {
        navigate(`/dashboard/branches/branch/${branchId}?desk=1/page=${1}`);
        dispatch(getOneReviewsSliceListAction(branchId, 1));
        window.scrollTo(0, 0);
    };

    const handleLast = () => {
        navigate(
            `/dashboard/branches/branch/${branchId}?desk=1/page=${reviewsList.pages}`
        );
        dispatch(getOneReviewsSliceListAction(branchId, reviewsList.pages));
        window.scrollTo(0, 0);
    };

    const handleNext = () => {
        if (reviewsList.page < reviewsList.pages) {
            navigate(
                `/dashboard/branches/branch/${branchId}?desk=1/page=${
                    reviewsList.page + 1
                }`
            );
            dispatch(getOneReviewsSliceListAction(branchId, reviewsList.page + 1));
            window.scrollTo(0, 0);
        }
    };

    const handlePrev = () => {
        if (reviewsList.page > 1) {
            navigate(
                `/dashboard/branches/branch/${branchId}?desk=1/page=${
                    reviewsList.page - 1
                }`
            );
            dispatch(getOneReviewsSliceListAction(branchId, reviewsList.page - 1));
            window.scrollTo(0, 0);
        }
    };

    const {startPage, endPage} = paginationGenerate(
        reviewsList.pages,
        reviewsList.page
    );

    for (let number = startPage; number <= endPage; number++) {
        items.push(
            <Pagination.Item
                key={number}
                active={number === reviewList.page}
                className="mx-1 my-3 pagination-styles"
                onClick={() => {
                    navigate(
                        `/dashboard/branches/branch/${branchId}?desk=1/page=${number}`
                    );
                    dispatch(getOneReviewsSliceListAction(branchId, number));
                    window.scrollTo(0, 0);
                }}
            >
                {number}
            </Pagination.Item>
        );
    }

    return (
        <Flex direction="column">
            {loading ? (
                <Flex justifyContent="center">
                    <Spinner w="100px" h="100px" mt="100px" thickness="10px" />
                </Flex>
            ) : !error && reviewsList.reviews.length > 0 ? (
                reviewsList.reviews.map((review) => (
                    <Card
                        key={review.id}
                        mt="30px"
                        w={{sm: "100%", md: "70%"}}
                        boxShadow="lg"
                    >
                        <CardHeader
                            _hover={{cursor: "pointer", color: "#4584FF"}}
                            onClick={() =>
                                navigate(
                                    `/dashboard/reviews/review/${review.id}`
                                )
                            }
                        >
                            <Heading size="md">{review.full_name}</Heading>
                        </CardHeader>
                        <CardBody>
                            <Heading size="xs" textTransform="uppercase">
                                Оценка: {review.rating}
                            </Heading>
                            <Text pt="2" fontSize="sm">
                                {review.body ? review.body : "Пустой отзыв"}
                            </Text>
                            <Text pt="2" fontSize="sm">
                                Ссылка на отзыв: {review.link}
                            </Text>
                        </CardBody>
                    </Card>
                ))
            ) : (
                <Text>О филиале отзывов нет</Text>
            )}
            {!error && reviewsList.pages > 1 && !loading && (
                <Pagination>
                    <Pagination.First
                        onClick={handleFirst}
                        className="mx-1 my-3 pagination-styles"
                    />
                    <Pagination.Prev
                        onClick={handlePrev}
                        className="mx-1 my-3 pagination-styles"
                    />
                    {items}
                    <Pagination.Next
                        onClick={handleNext}
                        className="mx-1 my-3 pagination-styles"
                    />
                    <Pagination.Last
                        onClick={handleLast}
                        className="mx-1 my-3 pagination-styles"
                    />
                </Pagination>
            )}
        </Flex>
    );
}
