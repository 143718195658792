import {
    COMPANY_READ_REQUEST,
    COMPANY_READ_SUCCESS,
    COMPANY_READ_FAIL,
    COMPANY_READ_RESET 
} from "./getCompanyState";

export const companyDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case COMPANY_READ_REQUEST:
            return {loading: true};

        case COMPANY_READ_SUCCESS:
            return {loading: false, company: action.payload};

        case COMPANY_READ_FAIL:
            return {loading: false, error: action.payload};
        
        case COMPANY_READ_RESET: 
            return {company: null};

        default:
            return state;
    }
};