import { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { SvgIcon } from "shared/ui/Icon";
import { AiOutlinePlus } from "react-icons/ai";
import { Icon } from "@chakra-ui/react";
import { FaTrashAlt } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import {
    createConnect,
    deleteConnect,
    updateConnect,
} from "app/store/actions/connectActions";

const Connections = ({branchId}) => {
    const inputBg = useColorModeValue("white", "gray.800");

    const initialState = [
        {
            id: null,
            key: null,
        },
        {
            id: null,
            key: null,
        },
        {
            id: null,
            key: null,
        },
        {
            id: null,
            key: null,
        },
        {
            id: null,
            key: null,
        },
        {
            id: null,
            key: null,
        },
    ];

    const {isOpen, onOpen, onClose} = useDisclosure();

    const {
        isOpen: isOpenCreate,
        onOpen: onOpenCreate,
        onClose: onCloseCreate,
    } = useDisclosure();

    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose,
    } = useDisclosure();

    const dispatch = useDispatch();

    const [keyCode, setKeyCode] = useState("");

    const [warning, setWarning] = useState("");

    const [keyCodeCreate, setKeyCodeCreate] = useState("");

    const [existConnectId, setExistConnectId] = useState(10);
    const [existType, setExistType] = useState(10);
    const connectAll = useSelector((state) => state.connectAll);

    const {connects, error, loading} = connectAll;

    const [connectTypes, setConnectTypes] = useState(initialState);

    useEffect(() => {
        setConnectTypes(initialState);
        const copy = Object.assign([], initialState);
        if (connects[0]) {
            connects.forEach(({type, id, key}) => {
                if (type in connectTypes) {
                    copy[type].id = id;
                    copy[type].key = key;
                    setConnectTypes(copy);
                }
            });
        }
    }, [connectAll]);

    const handleCreate = (type) => {
        const connectInfoCreate = {
            type: type,
            key: keyCodeCreate,
            branch_id: branchId,
        };
        if (keyCodeCreate !== "") {
            dispatch(createConnect(connectInfoCreate));
            setWarning("");
            setKeyCodeCreate("");
            onCloseCreate();
        } else {
            setWarning("Введите обязательные поля");
        }
    };

    const handleChange = (id, type) => {
        const connectInfo = {
            type: type,
            key: keyCode,
        };
        if (keyCode !== "") {
            dispatch(updateConnect(connectInfo, id));
            setWarning("");
            setKeyCode("");
            onClose();
        } else {
            setWarning("Введите обязательные поля");
        }
    };

    const handleDelete = (id) => {
        dispatch(deleteConnect(id));
        onDeleteClose();
    };

    return (
        <>
            <Modal isOpen={isOpenCreate} onClose={onCloseCreate}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Введите информацию о соединении</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Введите ключ подключения *</FormLabel>
                            <Input
                                bg={inputBg}
                                value={keyCodeCreate}
                                onChange={(e) =>
                                    setKeyCodeCreate(e.target.value)
                                }
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        {warning}
                        <Button
                            colorScheme="gray"
                            mr={3}
                            onClick={onCloseCreate}
                        >
                            Закрыть
                        </Button>
                        <Button
                            colorScheme="facebook"
                            onClick={() => {
                                handleCreate(existType);
                            }}
                        >
                            Создать
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader closeButton></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Вы действительно хотите удалить соединение?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="red" onClick={() => handleDelete(existConnectId)}>
                        Удалить
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Внесите изменения</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Введите ключ подключения *</FormLabel>
                            <Input
                                bg={inputBg}
                                value={keyCode}
                                onChange={(e) => setKeyCode(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        {warning}
                        <Button colorScheme="gray" mr={3} onClick={onClose}>
                            Закрыть
                        </Button>
                        <Button
                            colorScheme="facebook"
                            onClick={() => {
                                handleChange(existConnectId, existType);
                            }}
                        >
                            Сохранить
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {loading ? (
                <Flex mt="100px" justifyContent="center">
                    <Spinner w="100px" h="100px" mt="100px" thickness="10px" />
                </Flex>
            ) : (
                !error &&
                connectTypes.map((type, i) => (
                    <Card
                        key={i}
                        mt="30px"
                        mb="30px"
                        w={{sm: "100%", md: "70%"}}
                        boxShadow="lg"
                    >
                        <CardBody>
                            <Flex justify="space-between" alignItems="center">
                                <Flex direction="column">
                                    <Heading size="md" mb={3}>
                                        {i === 0
                                            ? "Яндекс"
                                            : i === 1
                                            ? "2GIS"
                                            : i === 2
                                            ? "Google"
                                            : i === 3
                                            ? "Flamp"
                                            : i === 4
                                            ? "Zoon"
                                            : "Yell"}
                                    </Heading>
                                    {type.id ? (
                                        <Flex direction="column">
                                            <Text>Соединение создано</Text>
                                            <Text>Ключ: {type.key}</Text>
                                        </Flex>
                                    ) : (
                                        <Text>Соединение не создано</Text>
                                    )}
                                </Flex>
                                {type.id ? (
                                    <Flex gap="10px">
                                        <button
                                            style={{cursor: "pointer"}}
                                            onClick={() => {
                                                onOpen();
                                                setExistConnectId(type.id);
                                                setExistType(i);
                                            }}
                                        >
                                            <SvgIcon.PencilSquare
                                                width={16}
                                                height={16}
                                            />
                                        </button>
                                        <Icon
                                            color="red"
                                            as={FaTrashAlt}
                                            style={{cursor: "pointer"}}
                                            onClick={() => {
                                                onDeleteOpen();
                                                setExistConnectId(type.id);
                                                }
                                            }
                                        />
                                    </Flex>
                                ) : (
                                    <Flex align="center">
                                        <Icon
                                            as={AiOutlinePlus}
                                            style={{cursor: "pointer"}}
                                            onClick={() => {
                                                onOpenCreate();
                                                setExistType(i);
                                            }}
                                        />
                                    </Flex>
                                )}
                            </Flex>
                        </CardBody>
                    </Card>
                ))
            )}
        </>
    );
};

export default Connections;
