export const connectSetList = [
    {
        "type": "0",
        "icon": "/images/svg/yandex_logo.svg"
    },
    {
        "type": "1",
        "icon": "/images/svg/2gis-logo.svg"
    },
    {
        "type": "2",
        "icon": "/images/svg/Zoon-logo.svg" //fix
    },
    {
        "type": "3",
        "icon": "/images/svg/Zoon-logo.svg" //fix
    },
    {
        "type": "4",
        "icon": "/images/svg/Zoon-logo.svg"
    },
    {
        "type": "5",
        "icon": "/images/svg/Zoon-logo.svg" //fix
    },
];