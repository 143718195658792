/*eslint-disable*/
import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react";

export default function Footer(props) {
    // const linkmaincolor = useColorModeValue("maincolor", "red.200");=
    return (
        <Flex
            flexDirection={{
                base: "column",
                xl: "row",
            }}
            alignItems={{
                base: "center",
                xl: "start",
            }}
            justifyContent="space-between"
            px="30px"
            pb="20px"
        >
            <Text
                color="gray.400"
                textAlign={{
                    base: "center",
                    xl: "start",
                }}
                mb={{base: "20px", xl: "0px"}}
            >
                RecTop {1900 + new Date().getYear()},{" "}

            </Text>
            <List
                display="flex"
                flexDirection={{
                    base: "column",
                    md: "row"
                }}
            >
                <ListItem
                    me={{
                        base: "20px",
                        md: "44px",
                    }}
                >
                    <Link color="gray.400" href="/">
                        Главная страница
                    </Link>
                </ListItem>
                <ListItem
                    me={{
                        base: "20px",
                        md: "44px",
                    }}
                    color="gray.400"
                >
                    info@rectop.ru
                </ListItem>
                <ListItem color="gray.400">
                    + 7 (964) 364-11-21
                </ListItem>
            </List>
        </Flex>
    );
}
