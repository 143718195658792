import { useDispatch } from "react-redux";
import { verifyCode } from "../api/actions/verificationCodeActions";

export const useVerificationCode = (code, mail) => {
    const dispatch = useDispatch();

    const handleVerification = () => {
        try {
            dispatch(verifyCode(code, mail));
            return { success: true };
        } catch (error) {
            return { success: false, error: 'Ошибка проверки кода' };
        }
    };

    return {
        verifyCode: handleVerification,
    };
};