import { 
    COMPANY_DELETE_REQUEST,
    COMPANY_DELETE_FAIL,
    COMPANY_DELETE_SUCCESS
} from "./deleteCompanyState";
import { COMPANY_READ_RESET, COMPANY_UPDATE_RESET } from "app/store/utils/constants/companyConstants";
import { ERROR_500, ERROR_504 } from "app/store/utils/constants/errorConstants";
import axios from "axios";

export const deleteCompany = (companyID) => async (dispatch, getState) => {
    try {
        dispatch({
            type: COMPANY_DELETE_REQUEST,
        });

        localStorage.removeItem("company");

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.delete(
            `/company/delete/${companyID}`,
            config
        );

        dispatch({
            type: COMPANY_READ_RESET,
        });

        dispatch({
            type: COMPANY_UPDATE_RESET,
        });

        dispatch({
            type: COMPANY_DELETE_SUCCESS,
        });

        localStorage.removeItem("company");
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: COMPANY_DELETE_FAIL,
                        payload: "Ошибка при запросе",
                    });
                    break;

                case 403:
                    dispatch({
                        type: COMPANY_DELETE_FAIL,
                        payload: "Ошибка доступа",
                    });
                    break;

                case 404:
                    dispatch({
                        type: COMPANY_DELETE_FAIL,
                        payload: "Компания не найдена",
                    });
                    break;

                case 500:
                    dispatch({
                        type: COMPANY_DELETE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;

                default:
                    dispatch({
                        type: COMPANY_DELETE_FAIL,
                        payload: "Произошла ошибка: " + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: COMPANY_DELETE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};
