import { useEffect } from "react";

import { Flex, Text } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOneReviewAction } from "../../../entities/review";
import Answer from "./ui/Answer";
import DescriptionCard from "./ui/DescriptionCard";

export default function Review(props) {
    const { id } = useParams();

    const dispatch = useDispatch();

    const answerCreate = useSelector((state) => state.answerCreate);

    const {loading} = answerCreate;

    useEffect(() => {
        dispatch(getOneReviewAction(id));
    }, [answerCreate]);

    return (
        <Flex
            flexDirection="column"
            justifyContent="center"
            align="center"
            pt={{base: "120px", md: "75px"}}
        >
            <Text fontSize="2xl" align="center">
                Отзыв
            </Text>
            <DescriptionCard />
            {!loading && <Answer reviewId={id} />}
        </Flex>
    );
}
