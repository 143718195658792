import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Alert, Spinner } from "react-bootstrap";

import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Switch,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import signInImage from "../../../dashboard/assets/img/signInImage.png";
import { useNavigate } from "react-router-dom";
import { useUserVerification } from "features/Auth/login/user/userVerification/hooks/useUserVerification";
import { useGetCode } from "features/Auth/login/confirmationСode/getСode";
import { useVerificationCode } from "features/Auth/login/confirmationСode/verificationCode/hooks/useVerificationCode";
import { useUserAuthorization } from "features/Auth/login/user/userAuthorization";
import { formModel } from "../model/formModel";

export const LogInPage = () => {
    const titleColor = useColorModeValue("maincolor");
    const textColor = useColorModeValue("gray.400", "white");

    let navigate = useNavigate();

    const {isOpen, onOpen, onClose} = useDisclosure();

    const userLogin = useSelector((state) => state.userLogin);
    const userVerifyCode = useSelector((state) => state.userVerifyCode);
    const userSendCode = useSelector((state) => state.userSendCode);
    const {error, loading, userInfo: userVerify} = userLogin;
    const {userInfo: userAuthInfo} = useSelector((state) => state.userAuthentication);
    const {error: errorVerify, loading: loadingVerify} = userVerifyCode;
    const {error: errorCheck, detail: detailSendCode} = userSendCode;
    const [isClicked, setIsClicked] = useState(false);

    const [ formData, setFormData ] = useState(formModel);
    const [ code, setCode ] = useState("");
    const [ message, setMessage ] = useState("");
    const [ errorColor, setErrorColor ] = useState("");
    const [ showButton, setShowButton ] = useState(false);
    const [ count, setCount ] = useState(60);

    const { verify } = useUserVerification(formData.email, formData.password);
    const { getCode } = useGetCode(formData.email);
    const { verifyCode } = useVerificationCode(code, formData.email);
    const { authorize } = useUserAuthorization(formData.email, formData.password);

    const isLoggedIn = localStorage.getItem("isLoggedIn");

    const handleFormData = (e, type) => {
        if (type === 'email') {
            setFormData({ ...formData, email: e.target.value });
        } else if (type === 'password') {
            setFormData({ ...formData, password: e.target.value });
        } else {
            setFormData(formModel);
        }
    };
    
    const sendUserData = () => {
        const result = verify();
        if (result.success) {
            setIsClicked(true);
            onOpen();
        } else {
            setErrorColor('red.100');
        }
    };

    const handleSendCode = () => {
        const result = getCode();
        if (result.success) {
            // Обработка успешной отправки кода
        } else if (isClicked) {
            setMessage("Пожалуйста, активируйте ваш аккаунт, вся информация у вас на почте.");
        }
    };

    const handleVerifyCode = () => {
        const result = verifyCode();
        if (result.success) {
            checkUserLogin();
        }
    };

    const checkUserLogin = () => {
        authorize();
    };

    useEffect(() => {
        if (userAuthInfo && isLoggedIn) {
            navigate("/dashboard/");
        }
    }, [userAuthInfo]);

    useEffect(() => {
        handleSendCode();
    }, [userVerify]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Введите код подтверждения, отправленный вам на почту
                    </ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Введите ключ подключения *</FormLabel>
                            <Input
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                            />
                        </FormControl>
                        {errorVerify && (
                            <Flex align="center">
                                <Text color="red">{errorVerify}.</Text>
                            </Flex>
                        )}
                        {errorVerify && (
                            <FormControl display="flex" alignItems="center">
                                <FormLabel
                                    htmlFor="remember-login"
                                    mb="0"
                                    ms="1"
                                    color="red"
                                    fontWeight="normal"
                                >
                                    Вы сможете отправить код повторно через{" "}
                                    {count} сек.
                                </FormLabel>
                            </FormControl>
                        )}
                        {showButton && (
                            <Button onClick={(e) => {
                                e.stopPropagation();
                                handleSendCode();
                            }}>
                                Отправить код повторно
                            </Button>
                        )}

                        {loadingVerify && (
                            <Spinner animation="border" variant="primary" />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="facebook"
                            onClick={() => handleVerifyCode()}
                        >
                            Войти в аккаунт
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Flex position="relative" mb="40px">
                <Flex
                    h={{sm: "initial", md: "75vh", lg: "85vh"}}
                    w="100%"
                    maxW="1044px"
                    mx="auto"
                    justifyContent="space-between"
                    mb="30px"
                    pt={{sm: "100px", md: "0px"}}
                >
                    <Flex
                        alignItems="center"
                        justifyContent="start"
                        style={{userSelect: "none"}}
                        w={{base: "100%", md: "50%", lg: "42%"}}
                    >
                        <Flex
                            direction="column"
                            w="100%"
                            background="transparent"
                            p="48px"
                            mt={{md: "150px", lg: "80px"}}
                        >
                            <Heading
                                color="gray.600"
                                fontSize="32px"
                                mb="10px"
                            >
                                Добро пожаловать
                            </Heading>
                            <Text
                                mb="36px"
                                ms="4px"
                                color={textColor}
                                fontWeight="bold"
                                fontSize="14px"
                            >
                                Введите свой пароль и электронную почту, чтобы
                                войти
                            </Text>
                            <FormControl>
                                <FormLabel
                                    ms="4px"
                                    fontSize="sm"
                                    fontWeight="normal"
                                >
                                    Email
                                </FormLabel>
                                <Input
                                    borderRadius="15px"
                                    mb="24px"
                                    fontSize="sm"
                                    type="text"
                                    placeholder="Ваш email"
                                    size="lg"
                                    bg={errorColor}
                                    value={formData.email}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        handleFormData(e, 'email');
                                    }}
                                />
                                <FormLabel
                                    ms="4px"
                                    fontSize="sm"
                                    fontWeight="normal"
                                >
                                    Пароль
                                </FormLabel>
                                <Input
                                    borderRadius="15px"
                                    mb="36px"
                                    fontSize="sm"
                                    type="password"
                                    placeholder="Ваш пароль"
                                    size="lg"
                                    bg={errorColor}
                                    value={formData.password}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        handleFormData(e, 'password');
                                    }}
                                />
                                <FormControl display="flex" alignItems="center">
                                    <Switch id="remember-login" me="10px" />
                                    <FormLabel
                                        htmlFor="remember-login"
                                        mb="0"
                                        ms="1"
                                        fontWeight="normal"
                                    >
                                        Запомнить меня
                                    </FormLabel>
                                </FormControl>
                                {loading && (
                                    <Spinner
                                        animation="border"
                                        variant="primary"
                                    />
                                )}
                                {error && (
                                    <FormControl
                                        display="flex"
                                        mt="10px"
                                        alignItems="center"
                                    >
                                        <FormLabel
                                            htmlFor="remember-login"
                                            mb="0"
                                            ms="1"
                                            color="red"
                                            fontWeight="normal"
                                        >
                                            {error}
                                        </FormLabel>
                                    </FormControl>
                                )}
                                {message != "" && (
                                    <Alert
                                        variant="primary"
                                        className="d-flex justify-content-center"
                                    >
                                        {message}
                                    </Alert>
                                )}
                                <Button
                                    onClick={() => sendUserData()}
                                    fontSize="10px"
                                    type="submit"
                                    bg="maincolor"
                                    w="100%"
                                    h="45"
                                    mb="20px"
                                    color="white"
                                    mt="20px"
                                    _hover={{
                                        bg: "secondary",
                                    }}
                                    _active={{
                                        bg: "secondary",
                                    }}
                                >
                                    ВОЙТИ
                                </Button>
                            </FormControl>
                            <Flex
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                                maxW="100%"
                                mt="0px"
                            >
                                <Text color={textColor} fontWeight="medium">
                                    У Вас ещё нет аккаунта?
                                    <Link
                                        color={titleColor}
                                        ms="5px"
                                        fontWeight="bold"
                                        // href="/auth/signup"
                                    >
                                        Регистрация
                                    </Link>
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Box
                        display={{base: "none", md: "block"}}
                        overflowX="hidden"
                        h="100%"
                        w="40vw"
                        position="absolute"
                        right="0px"
                    >
                        <Box
                            bgImage={signInImage}
                            w="100%"
                            h="100%"
                            bgSize="cover"
                            bgPosition="50%"
                            position="absolute"
                            borderBottomLeftRadius="20px"
                        ></Box>
                    </Box>
                </Flex>
            </Flex>
        </>
    );
};
