import styles from "./ErrorPage.module.scss";

export const ErrorPage = ({errorStatus, errorMessage}) => {
    return (
        <div className={styles.errorPage}>
            <div className={styles.errorPage__container}>
                <div className={styles.errorPage__message}>{errorStatus}</div>
                <div className={styles.errorPage__warning}>{errorMessage}</div>
            </div>
        </div>
    );
};
