import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    Grid,
    Heading,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    StackDivider,
    Text,
    Textarea,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";

import { Spinner } from "react-bootstrap";
import { updateBranchAction } from "../../../../entities/branch";

export default function DescriptionCard({
    branchId,
    branchInfo,
    error,
    loading,
}) {
    const inputBg = useColorModeValue("white", "gray.800");
    const branchGetOne = useSelector((state) => state.branchGetOne);

    const {isOpen, onOpen, onClose} = useDisclosure();

    const dispatch = useDispatch();

    useEffect(() => {
        setNameBranch(branchInfo.name);
        if (branchInfo.address !== null) setAddressBranch(branchInfo.address);
        if (branchInfo.description !== null)
            setDescriptionBranch(branchInfo.description);
        if (branchInfo.short_description !== null)
            setShortDescriptionBranch(branchInfo.short_description);
        if (branchInfo.phone_number !== null)
            setMobileBranch(branchInfo.phone_number);
        if (branchInfo.email !== null) setEmailBranch(branchInfo.email);
    }, [isOpen]);

    const { branch } = branchGetOne;

    const [nameBranch, setNameBranch] = useState("");
    const [addressBranch, setAddressBranch] = useState("");
    const [descriptionBranch, setDescriptionBranch] = useState("");
    const [shortDescriptionBranch, setShortDescriptionBranch] = useState("");
    const [mobileBranch, setMobileBranch] = useState("");
    const [emailBranch, setEmailBranch] = useState("");
    const [warning, setWarning] = useState("");

    const branchInfoEdit = {
        name: nameBranch,
        address: addressBranch,
        description: descriptionBranch,
        short_description: shortDescriptionBranch,
        phone_number: mobileBranch,
        email: emailBranch,
    };

    const changeBranch = () => {
        if (nameBranch !== "") {
            dispatch(updateBranchAction(branchId, branchInfoEdit));
            onClose();
            setWarning("");
        } else {
            setWarning("Введите обязательные поля");
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Введите данные о филиале (обязательные поля помечены *)
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex
                            direction="column"
                            justify="center"
                            align="center"
                        >
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                mb="10px"
                            >
                                Название филиала *
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={nameBranch || ""}
                                        onChange={(e) =>
                                            setNameBranch(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 10px 0px"
                            >
                                Адрес
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={addressBranch || ""}
                                        onChange={(e) =>
                                            setAddressBranch(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 10px 0px"
                            >
                                Описание
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Textarea
                                        value={descriptionBranch || ""}
                                        fontSize="xs"
                                        py="11px"
                                        onChange={(e) =>
                                            setDescriptionBranch(e.target.value)
                                        }
                                    ></Textarea>
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 10px 0px"
                            >
                                Короткое описание
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Textarea
                                        fontSize="xs"
                                        py="11px"
                                        value={shortDescriptionBranch || ""}
                                        onChange={(e) =>
                                            setShortDescriptionBranch(
                                                e.target.value
                                            )
                                        }
                                    ></Textarea>
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 10px 0px"
                            >
                                Номер телефона
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={mobileBranch || ""}
                                        onChange={(e) =>
                                            setMobileBranch(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                fontWeight="600"
                                m="6px 0px 10px 0px"
                            >
                                Email
                            </Text>
                            <Flex align="center" mb="10px">
                                <InputGroup
                                    bg={inputBg}
                                    borderRadius="15px"
                                    w="250px"
                                >
                                    <Input
                                        value={emailBranch || ""}
                                        onChange={(e) =>
                                            setEmailBranch(e.target.value)
                                        }
                                        fontSize="xs"
                                        py="11px"
                                        borderRadius="inherit"
                                    />
                                </InputGroup>
                            </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        {warning}
                        <Button
                            colorScheme="facebook"
                            mr={3}
                            onClick={changeBranch}
                        >
                            Сохранить изменения
                        </Button>
                        <Button colorScheme="gray" onClick={onClose}>
                            Закрыть
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {loading ? (
                <Flex mt="100px" mb="100px" justifyContent="center">
                    <Spinner/>
                </Flex>
            ) : (
                <Flex direction="column">
                    <Card mt="30px" boxShadow="lg" w={{sm: "100%", md: "70%"}}>
                        <CardHeader>
                            <Heading size="md">Информация о филиале</Heading>
                        </CardHeader>

                        {!error && (
                            <CardBody>
                                <Stack divider={<StackDivider />} spacing="4">
                                    <Box>
                                        <Heading
                                            size="xs"
                                            textTransform="uppercase"
                                        >
                                            Название
                                        </Heading>

                                        <Text pt="2" fontSize="sm">
                                            {branchInfo.name
                                                ? branchInfo.name
                                                : "Нет информации"}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Heading
                                            size="xs"
                                            textTransform="uppercase"
                                        >
                                            Краткое описание
                                        </Heading>

                                        <Text pt="2" fontSize="sm">
                                            {branchInfo.short_description
                                                ? branchInfo.short_description
                                                : "Нет информации"}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Heading
                                            size="xs"
                                            textTransform="uppercase"
                                        >
                                            Адрес
                                        </Heading>

                                        <Text pt="2" fontSize="sm">
                                            {branchInfo.address
                                                ? branchInfo.address
                                                : "Нет информации"}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Heading
                                            size="xs"
                                            textTransform="uppercase"
                                        >
                                            График работы
                                        </Heading>
                                        {branchInfo.schedule ? (
                                            <Flex direction="column">
                                                <Grid
                                                    templateColumns={{
                                                        base: "repeat(2, 1fr)",
                                                        md: "repeat(2, 3fr) 1fr",
                                                    }}
                                                >
                                                    <Text pt="2" fontSize="sm">
                                                        Понедельник:
                                                    </Text>
                                                    <Text>
                                                        {
                                                            branchInfo.schedule
                                                                .monday
                                                                .start_time
                                                        }
                                                        -
                                                        {
                                                            branchInfo.schedule
                                                                .monday.end_time
                                                        }
                                                    </Text>
                                                    <Text color="silver">
                                                        {branchInfo.schedule
                                                            .monday.day_type ===
                                                        "weekday"
                                                            ? "рабочий день"
                                                            : "выходной"}
                                                    </Text>
                                                </Grid>
                                                <Grid
                                                    templateColumns={{
                                                        base: "repeat(2, 1fr)",
                                                        md: "repeat(2, 3fr) 1fr",
                                                    }}
                                                >
                                                    <Text pt="2" fontSize="sm">
                                                        Вторник:
                                                    </Text>
                                                    <Text>
                                                        {
                                                            branchInfo.schedule
                                                                .tuesday
                                                                .start_time
                                                        }
                                                        -
                                                        {
                                                            branchInfo.schedule
                                                                .tuesday
                                                                .end_time
                                                        }
                                                    </Text>
                                                    <Text color="silver">
                                                        {branchInfo.schedule
                                                            .tuesday
                                                            .day_type ===
                                                        "weekday"
                                                            ? "рабочий день"
                                                            : "выходной"}
                                                    </Text>
                                                </Grid>

                                                <Grid
                                                    templateColumns={{
                                                        base: "repeat(2, 1fr)",
                                                        md: "repeat(2, 3fr) 1fr",
                                                    }}
                                                >
                                                    <Text pt="2" fontSize="sm">
                                                        Среда:
                                                    </Text>
                                                    <Text>
                                                        {
                                                            branchInfo.schedule
                                                                .wednesday
                                                                .start_time
                                                        }
                                                        -
                                                        {
                                                            branchInfo.schedule
                                                                .wednesday
                                                                .end_time
                                                        }
                                                    </Text>
                                                    <Text color="silver">
                                                        {branchInfo.schedule
                                                            .wednesday
                                                            .day_type ===
                                                        "weekday"
                                                            ? "рабочий день"
                                                            : "выходной"}
                                                    </Text>
                                                </Grid>
                                                <Grid
                                                    templateColumns={{
                                                        base: "repeat(2, 1fr)",
                                                        md: "repeat(2, 3fr) 1fr",
                                                    }}
                                                >
                                                    <Text pt="2" fontSize="sm">
                                                        Четверг:
                                                    </Text>
                                                    <Text>
                                                        {
                                                            branchInfo.schedule
                                                                .thursday
                                                                .start_time
                                                        }
                                                        -
                                                        {
                                                            branchInfo.schedule
                                                                .thursday
                                                                .end_time
                                                        }
                                                    </Text>
                                                    <Text color="silver">
                                                        {branchInfo.schedule
                                                            .thursday
                                                            .day_type ===
                                                        "weekday"
                                                            ? "рабочий день"
                                                            : "выходной"}
                                                    </Text>
                                                </Grid>
                                                <Grid
                                                    templateColumns={{
                                                        base: "repeat(2, 1fr)",
                                                        md: "repeat(2, 3fr) 1fr",
                                                    }}
                                                >
                                                    <Text pt="2" fontSize="sm">
                                                        Пятница:
                                                    </Text>
                                                    <Text>
                                                        {
                                                            branchInfo.schedule
                                                                .friday
                                                                .start_time
                                                        }
                                                        -
                                                        {
                                                            branchInfo.schedule
                                                                .friday.end_time
                                                        }
                                                    </Text>
                                                    <Text color="silver">
                                                        {branchInfo.schedule
                                                            .friday.day_type ===
                                                        "weekday"
                                                            ? "рабочий день"
                                                            : "выходной"}
                                                    </Text>
                                                </Grid>
                                                <Grid
                                                    templateColumns={{
                                                        base: "repeat(2, 1fr)",
                                                        md: "repeat(2, 3fr) 1fr",
                                                    }}
                                                >
                                                    <Text pt="2" fontSize="sm">
                                                        Суббота:
                                                    </Text>
                                                    <Text>
                                                        {
                                                            branchInfo.schedule
                                                                .saturday
                                                                .start_time
                                                        }
                                                        -
                                                        {
                                                            branchInfo.schedule
                                                                .saturday
                                                                .end_time
                                                        }
                                                    </Text>
                                                    <Text color="silver">
                                                        {branchInfo.schedule
                                                            .saturday
                                                            .day_type ===
                                                        "weekday"
                                                            ? "рабочий день"
                                                            : "выходной"}
                                                    </Text>
                                                </Grid>
                                                <Grid
                                                    templateColumns={{
                                                        base: "repeat(2, 1fr)",
                                                        md: "repeat(2, 3fr) 1fr",
                                                    }}
                                                >
                                                    <Text pt="2" fontSize="sm">
                                                        Воскресенье:
                                                    </Text>
                                                    <Text>
                                                        {
                                                            branchInfo.schedule
                                                                .sunday
                                                                .start_time
                                                        }
                                                        -
                                                        {
                                                            branchInfo.schedule
                                                                .sunday.end_time
                                                        }
                                                    </Text>
                                                    <Text color="silver">
                                                        {branchInfo.schedule
                                                            .sunday.day_type ===
                                                        "weekday"
                                                            ? "рабочий день"
                                                            : "выходной"}
                                                    </Text>
                                                </Grid>
                                            </Flex>
                                        ) : (
                                            <Text color="silver">
                                                Графика работы нет
                                            </Text>
                                        )}
                                    </Box>
                                    <Box>
                                        <Heading
                                            size="xs"
                                            textTransform="uppercase"
                                        >
                                            Описание
                                        </Heading>

                                        <Text pt="2" fontSize="sm">
                                            {branchInfo.description
                                                ? branchInfo.description
                                                : "Нет информации"}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Heading
                                            size="xs"
                                            textTransform="uppercase"
                                        >
                                            Телефон
                                        </Heading>

                                        <Text pt="2" fontSize="sm">
                                            {branchInfo.phone_number
                                                ? branchInfo.phone_number
                                                : "Нет информации"}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Heading
                                            size="xs"
                                            textTransform="uppercase"
                                        >
                                            Электронная почта
                                        </Heading>

                                        <Text pt="2" fontSize="sm">
                                            {branchInfo.email
                                                ? branchInfo.email
                                                : "Нет информации"}
                                        </Text>
                                    </Box>
                                </Stack>
                            </CardBody>
                        )}
                    </Card>
                    <Button colorScheme="facebook" mt="30px" onClick={onOpen} w="200px">
                        Редактировать
                    </Button>
                </Flex>
            )}
        </>
    );
}
