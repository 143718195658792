import { BRANCH_ALL_REQUEST, BRANCH_ALL_SUCCESS, BRANCH_ALL_FAIL } from './getBranchListState';
import { ERROR_500, ERROR_504 } from 'app/store/utils/constants/errorConstants';
import axios from 'axios';

export const getPageListBranches =
    (listID, page) => async (dispatch, getState) => {
        try {
            dispatch({
                type: BRANCH_ALL_REQUEST,
            });

            const {
                userAuthentication: {userInfo},
            } = getState();

            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const {data} = await axios.get(
                `/branch/read/list/${listID}?page=${page}`,
                config
            );

            dispatch({
                type: BRANCH_ALL_SUCCESS,
                payload: data,
            });
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        dispatch({
                            type: BRANCH_ALL_FAIL,
                            payload: "Ошибка при запросе",
                        });
                        break;

                    case 401:
                        dispatch({
                            type: BRANCH_ALL_FAIL,
                            payload: "Пустой или неправильный токен",
                        });
                        break;

                    case 403:
                        dispatch({
                            type: BRANCH_ALL_FAIL,
                            payload: "Ошибка доступа",
                        });
                        break;

                    case 404:
                        dispatch({
                            type: BRANCH_ALL_FAIL,
                            payload: "Компания не найдена",
                        });
                        break;

                    case 500:
                        dispatch({
                            type: BRANCH_ALL_FAIL,
                            payload: "500",
                        });
                        dispatch({
                            type: ERROR_500,
                            payload: true,
                        });

                        break;

                    default:
                        dispatch({
                            type: BRANCH_ALL_FAIL,
                            payload: "Произошла ошибка" + error,
                        });
                        break;
                }
            } else {
                dispatch({
                    type: BRANCH_ALL_FAIL,
                    payload: error.message,
                });
                if (error.message === "timeout of 5000ms exceeded") {
                    dispatch({
                        type: ERROR_504,
                        payload: true,
                    });
                }
            }
        }
    };