import axios from "axios";
import "./timeout";

import {
    CONNECT_ALL_FAIL,
    CONNECT_ALL_REQUEST,
    CONNECT_ALL_SUCCESS,
    CONNECT_CREATE_FAIL,
    CONNECT_CREATE_REQUEST,
    CONNECT_CREATE_SUCCESS,
    CONNECT_DELETE_FAIL,
    CONNECT_DELETE_REQUEST,
    CONNECT_DELETE_SUCCESS,
    CONNECT_GET_ONE_FAIL,
    CONNECT_GET_ONE_REQUEST,
    CONNECT_GET_ONE_SUCCESS,
    CONNECT_UPDATE_FAIL,
    CONNECT_UPDATE_REQUEST,
    CONNECT_UPDATE_SUCCESS
} from "../utils/constants/connectConstants";

import { ERROR_500, ERROR_504 } from "../utils/constants/errorConstants";

export const createConnect = (connectInfo) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONNECT_CREATE_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.post(
            `/connect/create/`,
            connectInfo,
            config
        );

        dispatch({
            type: CONNECT_CREATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: CONNECT_CREATE_FAIL,
                        payload: "Ошибка при создании",
                    });
                    break;

                case 403:
                    dispatch({
                        type: CONNECT_CREATE_FAIL,
                        payload: "Ошибка доступа",
                    });
                    break;

                case 404:
                    dispatch({
                        type: CONNECT_CREATE_FAIL,
                        payload: "Филиал не найден",
                    });
                    break;

                case 406:
                    dispatch({
                        type: CONNECT_CREATE_FAIL,
                        payload: "Такой connect уже создан для филиала",
                    });
                    break;

                case 500:
                    dispatch({
                        type: CONNECT_CREATE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;

                default:
                    dispatch({
                        type: CONNECT_CREATE_FAIL,
                        payload: "Произошла ошибка: " + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: CONNECT_CREATE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};

export const updateConnect =
    (connectInfo, connectID) => async (dispatch, getState) => {
        try {
            dispatch({
                type: CONNECT_UPDATE_REQUEST,
            });

            const {
                userAuthentication: {userInfo},
            } = getState();

            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const {data} = await axios.put(
                `/connect/update/${connectID}`,
                connectInfo,
                config
            );

            dispatch({
                type: CONNECT_UPDATE_SUCCESS,
            });
        } catch (error) {
            if (error.resonse) {
                switch (error.response.status) {
                    case 400:
                        dispatch({
                            type: CONNECT_UPDATE_FAIL,
                            payload: "Ошибка при запросе",
                        });
                        break;

                    case 403:
                        dispatch({
                            type: CONNECT_UPDATE_FAIL,
                            payload: "Ошибка доступа",
                        });
                        break;

                    case 404:
                        dispatch({
                            type: CONNECT_UPDATE_FAIL,
                            payload: "Connect не найден",
                        });
                        break;

                    case 500:
                        dispatch({
                            type: CONNECT_UPDATE_FAIL,
                            payload: "500",
                        });
                        dispatch({
                            type: ERROR_500,
                            payload: true,
                        });

                        break;

                    default:
                        dispatch({
                            type: CONNECT_UPDATE_FAIL,
                            payload: "Произошла ошибка: " + error,
                        });
                        break;
                }
            } else {
                dispatch({
                    type: CONNECT_UPDATE_FAIL,
                    payload: error.message,
                });
                if (error.message === "timeout of 5000ms exceeded") {
                    dispatch({
                        type: ERROR_504,
                        payload: true,
                    });
                }
            }
        }
    };

export const getAllConnects = (branchId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONNECT_ALL_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.get(
            `/connect/read/list/${branchId}`,
            config
        );

        dispatch({
            type: CONNECT_ALL_SUCCESS,
            payload: data,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: CONNECT_ALL_FAIL,
                        payload: "Ошибка при запросе",
                    });
                    break;

                case 404:
                    dispatch({
                        type: CONNECT_ALL_FAIL,
                        payload: "Компания не найдена",
                    });
                    break;

                case 500:
                    dispatch({
                        type: CONNECT_ALL_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;

                default:
                    dispatch({
                        type: CONNECT_ALL_FAIL,
                        payload: "Произошла ошибка: " + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: CONNECT_ALL_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};

export const getOneConnect = (branchId) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONNECT_GET_ONE_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.get(
            `/connect/read/${branchId}`,
            config
        );

        dispatch({
            type: CONNECT_GET_ONE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: CONNECT_GET_ONE_FAIL,
                        payload: "Ошибка при запросе",
                    });
                    break;

                case 403:
                    dispatch({
                        type: CONNECT_GET_ONE_FAIL,
                        payload: "Ошибка доступа",
                    });
                    break;

                case 404:
                    dispatch({
                        type: CONNECT_GET_ONE_FAIL,
                        payload: "Connect не найден",
                    });
                    break;

                case 500:
                    dispatch({
                        type: CONNECT_GET_ONE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;

                default:
                    dispatch({
                        type: CONNECT_GET_ONE_FAIL,
                        payload: "Произошла ошибка: " + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: CONNECT_GET_ONE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};

export const deleteConnect = (connectID) => async (dispatch, getState) => {
    try {
        dispatch({
            type: CONNECT_DELETE_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.delete(
            `/connect/delete/${connectID}`,
            config
        );

        dispatch({
            type: CONNECT_DELETE_SUCCESS,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: CONNECT_DELETE_FAIL,
                        payload: "Ошибка при запросе",
                    });
                    break;

                case 403:
                    dispatch({
                        type: CONNECT_DELETE_FAIL,
                        payload: "Ошибка доступа",
                    });
                    break;

                case 404:
                    dispatch({
                        type: CONNECT_DELETE_FAIL,
                        payload: "Connect не найден",
                    });
                    break;

                case 500:
                    dispatch({
                        type: CONNECT_DELETE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;

                default:
                    dispatch({
                        type: CONNECT_DELETE_FAIL,
                        payload: "Произошла ошибка: " + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: CONNECT_DELETE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};
