import { 
    COMPANY_READ_ALL_REQUEST, 
    COMPANY_READ_ALL_SUCCESS, 
    COMPANY_READ_ALL_FAIL, 
    COMPANY_READ_ALL_RESET
} from "./getCompanyListState";

export const allCompaniesReducer = (state = {companies: {}}, action) => {
    switch (action.type) {
        case COMPANY_READ_ALL_REQUEST:
            return {...state, loading: true};

        case COMPANY_READ_ALL_SUCCESS:
            return {loading: false, companies: action.payload};

        case COMPANY_READ_ALL_FAIL:
            return {loading: false, error: action.payload};
        
        case COMPANY_READ_ALL_RESET: 
            return {company: null};

        default:
            return state;

    }
};