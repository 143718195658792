import {
    COMPANY_UPDATE_REQUEST,
    COMPANY_UPDATE_SUCCESS,
    COMPANY_UPDATE_FAIL
} from "./updateCompanyState";
import { COMPANY_READ_SUCCESS } from "../getCompany/getCompanyState";
import axios from "axios";
import { ERROR_500, ERROR_504 } from "app/store/utils/constants/errorConstants";

export const updateCompany =
    (companyName, companyID) => async (dispatch, getState) => {
        try {
            dispatch({
                type: COMPANY_UPDATE_REQUEST,
            });

            const {
                userAuthentication: {userInfo},
            } = getState();

            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const {data} = await axios.put(
                `/company/update/${companyID}`,
                {name: companyName},
                config
            );

            dispatch({
                type: COMPANY_UPDATE_SUCCESS,
            });

            dispatch({
                type: COMPANY_READ_SUCCESS,
                payload: data,
            });

            localStorage.setItem("company", JSON.stringify(data));
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        dispatch({
                            type: COMPANY_UPDATE_FAIL,
                            payload: "Ошибка при запросе",
                        });
                        break;

                    case 403:
                        dispatch({
                            type: COMPANY_UPDATE_FAIL,
                            payload: "Ошибка доступа",
                        });
                        break;

                    case 404:
                        dispatch({
                            type: COMPANY_UPDATE_FAIL,
                            payload: "Компания не найдена",
                        });
                        break;

                    case 500:
                        dispatch({
                            type: COMPANY_UPDATE_FAIL,
                            payload: "500",
                        });
                        dispatch({
                            type: ERROR_500,
                            payload: true,
                        });

                        break;

                    default:
                        dispatch({
                            type: COMPANY_UPDATE_FAIL,
                            payload: "Произошла ошибка: " + error,
                        });
                        break;
                }
            } else {
                dispatch({
                    type: COMPANY_UPDATE_FAIL,
                    payload: error.message,
                });
                if (error.message === "timeout of 5000ms exceeded") {
                    dispatch({
                        type: ERROR_504,
                        payload: true,
                    });
                }
            }
        }
    };