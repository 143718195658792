import { 
    COMPANY_READ_ALL_REQUEST, 
    COMPANY_READ_ALL_SUCCESS, 
    COMPANY_READ_ALL_FAIL 
} from "./getCompanyListState";
import { ERROR_500, ERROR_504 } from "app/store/utils/constants/errorConstants";
import { logout } from "app/store/actions/userActions";
import axios from "axios";

export const getAllCompanies = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: COMPANY_READ_ALL_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.get(`/company/read/all/`, config);

        dispatch({
            type: COMPANY_READ_ALL_SUCCESS,
            payload: data,
        });
        localStorage.setItem("companies", data);
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: COMPANY_READ_ALL_FAIL,
                        payload: "Ошибка при запросе",
                    });
                    break;

                case 401:
                    dispatch({
                        type: COMPANY_READ_ALL_FAIL,
                        payload: "Пустой или неправильный токен",
                    });
                    dispatch(logout());
                    window.location.reload();
                    break;

                case 403:
                    dispatch({
                        type: COMPANY_READ_ALL_FAIL,
                        payload: "Ошибка доступа",
                    });
                    break;

                case 404:
                    dispatch({
                        type: COMPANY_READ_ALL_FAIL,
                        payload: "Компании не найдены",
                    });
                    break;

                case 500:
                    dispatch({
                        type: COMPANY_READ_ALL_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });
                    break;

                default:
                    dispatch({
                        type: COMPANY_READ_ALL_FAIL,
                        payload: "Произошла ошибка: " + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: COMPANY_READ_ALL_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};