import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Spinner } from "react-bootstrap";
import { verify } from "app/store/actions/userActions.js";
import styles from "./VerificationPage.module.scss";

export const VerificationPage = (props) => {
    const { code } = useParams();

    const dispatch = useDispatch();
    const userVerify = useSelector((state) => state.userVerify);
    const {error, userInfo, loading} = userVerify;
    let location = useLocation();
    const navigate = useNavigate();
    const user = localStorage.getItem("userInfo");
    const [message, setMessage] = useState("Подождите, пожалуйста...");

    useEffect(() => {
        function verify_user() {
            dispatch(verify(code));
        }

        verify_user();
    }, [location]);

    useEffect(() => {
        async function toVerify() {
            if (userInfo) {
                setMessage(
                    "Ваша почта успешно подтверждена. Сейчас вы перейдете в личный кабинет"
                );
                await new Promise((resolve, reject) =>
                    setTimeout(resolve, 3000)
                );
                navigate("/dashboard/");
            } else {
                setMessage(error);
            }
        }

        toVerify();
    }, [userInfo]);

    useEffect(() => {
        if (error) {
            setMessage(error);
        }
    }, [error]);

    return (
        <div className={styles.verificationPage}>
            <Alert>
                {message}
                {loading && <Spinner animation="border"/>}
            </Alert>
        </div>
    );
};
