import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
    Button,
    Card,
    CardBody,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    useColorModeValue,
    useDisclosure
} from "@chakra-ui/react";
import { qrCodeCreate } from "../../../../app/store/actions/QRActions";

import { useNavigate } from "react-router-dom";

function QRcodes({createError}) {
    const inputBg = useColorModeValue("white", "gray.800");

    let navigate = useNavigate();

    const [branchId, setBranchId] = useState("");
    const [warning, setWarning] = useState("");

    const dispatch = useDispatch();

    const handleCreate = () => {
        if (branchId !== "") {
            dispatch(qrCodeCreate(branchId));
            onClose();
            setWarning("");
            setBranchId("");
        } else {
            setWarning("Введите обязательны поля");
        }
    };

    const {isOpen, onOpen, onClose} = useDisclosure();

    const QRAll = useSelector((state) => state.QRAll);

    const {codes, loading} = QRAll;

    return (
        <Flex direction="column">
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Создайте qr код</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Введите номер филиала *</FormLabel>
                            <Input
                                bg={inputBg}
                                value={branchId}
                                onChange={(e) => setBranchId(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Flex direction="column">
                            <Text>{warning}</Text>
                            <Button variant="facebook" onClick={handleCreate}>
                                Создать
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {loading ? (
                <Flex mt="100px" mb="100px" justifyContent="center">
                    <Spinner w="100px" h="100px" thickness="10px" />
                </Flex>
            ) : Object.keys(codes).length !== 0 ? (
                codes.map((code) => (
                    <Card
                        key={code.slug_name}
                        w={{sm: "100%", md: "70%"}}
                        mb="20px"
                        _hover={{color: "#4584FF", cursor: "pointer"}}
                    >
                        <CardBody
                            onClick={() =>
                                navigate(`/qrcoderates/${code.slug_name}`)
                            }
                        >
                            {code.slug_name}
                        </CardBody>
                    </Card>
                ))
            ) : (
                <Heading size="md">У вас нет qr кодов</Heading>
            )}

            {createError && <Text color="red">{createError}</Text>}
            <Button mt="30px" colorScheme="facebook" onClick={onOpen}>
                Создать QR-код
            </Button>
        </Flex>
    );
}

export default QRcodes;
