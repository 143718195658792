export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_VERIFY_REQUEST = "USER_VERIFY_REQUEST";
export const USER_VERIFY_SUCCESS = "USER_VERIFY_SUCCESS";
export const USER_VERIFY_FAIL = "USER_VERIFY_FAIL";

export const USER_SEND_CODE_REQUEST = "USER_SEND_CODE_REQUEST";
export const USER_SEND_CODE_SUCCESS = "USER_SEND_CODE_SUCCESS";
export const USER_SEND_CODE_FAIL = "USER_SEND_CODE_FAIL";

export const USER_VERIFY_CODE_REQUEST = "USER_VERIFY_CODE_REQUEST";
export const USER_VERIFY_CODE_SUCCESS = "USER_VERIFY_CODE_SUCCESS";
export const USER_VERIFY_CODE_FAIL = "USER_VERIFY_CODE_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";
