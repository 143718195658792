import Billing from "../pages/Billing/index.js";
import Dashboard from "../pages/Dashboard/index.js";
import Tables from "../pages/Tables/index.js";

import { LogInPage } from "pages/LogInPage/index.js";
import { RegistrationPage } from "pages/RegistrationPage/index.js";
import { VerificationPage } from "pages/VerificationPage";
import Branch from "../pages/Branch/index.js";
import Profile from "../pages/Profile/index.js";
import Review from "../pages/Review/index.js";

import {
    CreditIcon,
    DocumentIcon,
    HomeIcon,
    PersonIcon,
    RocketIcon,
    StatsIcon,
    SupportIcon,
} from "../components/Icons/Icons.js";
import AllReviews from "../pages/AllReviews/index.js";
import Company from "../pages/Company/index.js";

export const dashRoutes = [
    {
        path: "/",
        name: "Главная страница",

        icon: <HomeIcon color="inherit"/>,
        component: Dashboard,
        layout: "/dashboard",
    },
    {
        path: "/branches",
        name: "Филиалы",

        icon: <StatsIcon color="inherit"/>,
        component: Tables,
        layout: "/dashboard",
    },
    {
        path: "/billing",
        name: "Тарифы",

        icon: <CreditIcon color="inherit"/>,
        component: Billing,
        layout: "/dashboard",
    },
    {
        path: "/profile",
        name: "Профиль",

        icon: <PersonIcon color="inherit"/>,
        secondaryNavbar: true,
        component: Profile,
        layout: "/dashboard",
    },
    {
        path: "/reviews",
        name: "Отзывы",

        secondaryNavbar: false,
        icon: <SupportIcon color="inherit"/>,
        component: AllReviews,
        layout:"/dashboard"
    },
    {
        path: "/company",
        name: "Компания",

        secondaryNavbar: false,
        icon: <RocketIcon color="inherit"/>,
        component: Company,
        layout:"/dashboard"
    },
    {
        path: "/reviews/review/:id",
        pathBack: "/reviews",
        name: "Отзыв",
        secondName: "Отзывы",

        secondaryNavbar: false,
        component: Review,
        layout:"/dashboard"
    },
    {
        path: "/branches/branch/:id",
        pathBack: "/branches",
        name: "Филиал",
        secondName: "Филиалы",

        secondaryNavbar: false,
        component: Branch,
        layout:"/dashboard"
    },
    {
        path: "/branches?page=:id",
        name: "Филиалы",

        icon: <StatsIcon color="inherit"/>,
        component: Tables,
        layout: "/dashboard",
    },
    {
        path: "/reviews?page=:id",
        name: "Отзывы",

        component: AllReviews,
        layout: "/dashboard",
    },
    {
        path: "/branches/branch/:id?desk=:num/page=:id",
        pathBack: "/branches",
        name: "Филиал",
        secondName: "Филиалы",

        component: AllReviews,
        layout: "/dashboard",
    },
    
    {
        path: "/signin",
        name: "Вход",

        icon: <DocumentIcon color="inherit"/>,
        component: LogInPage,
        layout: "/auth",
    },
    {
        path: "/signup",
        name: "Регистрация",

        icon: <RocketIcon color="inherit"/>,
        secondaryNavbar: true,
        component: RegistrationPage,
        layout: "/auth",
    },
    {
        path: "/verify/:code",
        name: "Verify email",

        icon: <RocketIcon color="inherit"/>,
        secondaryNavbar: false,
        component: VerificationPage,
        layout: "/auth",
    },
    {
        path: "/company?desk=:id",
        name: "Компания",

        secondaryNavbar: false,
        icon: <RocketIcon color="inherit"/>,
        component: Company,
        layout:"/dashboard"
    },
    {
        path: "/branches/branch/:id?desk=:num",
        pathBack: "/branches",
        name: "Филиал",
        secondName: "Филиалы",

        secondaryNavbar: false,
        icon: <RocketIcon color="inherit"/>,
        component: Branch,
        layout:"/dashboard"
    },
];
export default dashRoutes;
