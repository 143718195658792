export const RATE_ADD_REQUEST = 'RATE_ADD_REQUEST';
export const RATE_ADD_SUCCESS = 'RATE_ADD_SUCCESS';
export const RATE_ADD_FAIL = 'RATE_ADD_FAIL';

export const RATE_READ_REQUEST = 'RATE_READ_REQUEST';
export const RATE_READ_SUCCESS = 'RATE_READ_SUCCESS';
export const RATE_READ_FAIL = 'RATE_READ_FAIL';

export const RATE_CHANGE_REQUEST = 'RATE_CHANGE_REQUEST';
export const RATE_CHANGE_SUCCESS = 'RATE_CHANGE_SUCCESS';
export const RATE_CHANGE_FAIL = 'RATE_CHANGE_FAIL';
export const RATE_CHANGE_RESET = 'RATE_CHANGE_RESET';
