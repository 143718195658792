export const QR_CREATE_REQUEST = 'QR_CREATE_REQUEST';
export const QR_CREATE_SUCCESS = 'QR_CREATE_SUCCESS';
export const QR_CREATE_FAIL = 'QR_CREATE_FAIL';

export const QR_ALL_REQUEST = 'QR_ALL_REQUEST';
export const QR_ALL_SUCCESS = 'QR_ALL_SUCCESS';
export const QR_ALL_FAIL = 'QR_ALL_FAIL';

export const QR_GET_ONE_REQUEST = 'QR_GET_ONE_REQUEST';
export const QR_GET_ONE_SUCCESS = 'QR_GET_ONE_SUCCESS';
export const QR_GET_ONE_FAIL = 'QR_GET_ONE_FAIL';
