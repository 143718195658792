import { useDispatch } from "react-redux";
import { validateEmail } from "pages/LogInPage/helpers/validation/validateEmail";
import { login } from "app/store/actions/userActions";

export const useUserVerification = (email, password) => {
  const dispatch = useDispatch();

  const handleVerification = () => {
    if (!validateEmail(email)) {
      return { success: false, error: 'Невалидный email' };
    }

    if (password === '') {
      return { success: false, error: 'Введите пароль' };
    }

    try {
      dispatch(login(email, password));
      return { success: true };
    } catch (error) {
      return { success: false, error: 'Ошибка входа' };
    }
  };

  return {
    verify: handleVerification,
  };
};