import {
    REVIEW_DELETE_REQUEST,
    REVIEW_DELETE_SUCCESS,
    REVIEW_DELETE_FAIL
} from "./deleteReviewState";

export const reviewDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case REVIEW_DELETE_REQUEST:
            return {loading: true};

        case REVIEW_DELETE_SUCCESS:
            return {loading: false, success: true};

        case REVIEW_DELETE_FAIL:
            return {loading: false, error: action.payload};

        default:
            return state;
    }
};