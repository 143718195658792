import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";

export const usePushNotification = (error, loading, didMount, message, errorMessage, input) => {
    const toast = useToast();
    useEffect(() => {
        if (!error && !loading && didMount) {
            toast({
                title: message,
                variant: "subtle",
                position: "bottom-left",
            });
        }
        if(didMount && error) {
            toast({
                title: errorMessage,
                variant: "subtle",
                status: "error",
                position: "bottom-left",
            });
        } 
    }, [input]);
    
};