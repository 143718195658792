import axios from "axios";
import {
    GET_BRANCH_DETAILD_ERROR,
    GET_BRANCH_DETAILS_REQUEST,
    GET_BRANCH_DETAILS_SUCCESS
} from "./getBranchState";
import { ERROR_500, ERROR_504 } from "app/store/utils/constants/errorConstants";
import { logout } from "app/store/actions/userActions";

export const getBranchDetailsAction = (branchID) => async (dispatch, getState) => {
    try {
        dispatch({
            type: GET_BRANCH_DETAILS_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.get(`/branch/read/${branchID}`, config);

        dispatch({
            type: GET_BRANCH_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: GET_BRANCH_DETAILD_ERROR,
                        payload: "Ошибка при запросе",
                    });
                    break;
                case 401:
                    dispatch({
                        type: GET_BRANCH_DETAILD_ERROR,
                        payload: "Пустой или неправильный токен",
                    });
                    dispatch(logout());
                    window.location.reload();
                    break;
                case 403:
                    dispatch({
                        type: GET_BRANCH_DETAILD_ERROR,
                        payload: "Ошибка доступа",
                    });
                    break;
                case 404:
                    dispatch({
                        type: GET_BRANCH_DETAILD_ERROR,
                        payload: "branch не найден",
                    });
                    break;

                case 500:
                    dispatch({
                        type: GET_BRANCH_DETAILD_ERROR,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });
                    break;

                default:
                    dispatch({
                        type: GET_BRANCH_DETAILD_ERROR,
                        payload: "Произошла ошибка: " + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: GET_BRANCH_DETAILD_ERROR,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};