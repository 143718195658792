export const CONNECT_CREATE_REQUEST = "CONNECT_CREATE_REQUEST";
export const CONNECT_CREATE_SUCCESS = "CONNECT_CREATE_SUCCESS";
export const CONNECT_CREATE_FAIL = "CONNECT_CREATE_FAIL";

export const CONNECT_DELETE_REQUEST = "CONNECT_DELETE_REQUEST";
export const CONNECT_DELETE_SUCCESS = "CONNECT_DELETE_SUCCESS";
export const CONNECT_DELETE_FAIL = "CONNECT_DELETE_FAIL";

export const CONNECT_ALL_REQUEST = "CONNECT_ALL_REQUEST";
export const CONNECT_ALL_SUCCESS = "CONNECT_ALL_SUCCESS";
export const CONNECT_ALL_FAIL = "CONNECT_ALL_FAIL";

export const CONNECT_GET_ONE_REQUEST = "CONNECT_GET_ONE_REQUEST";
export const CONNECT_GET_ONE_SUCCESS = "CONNECT_GET_ONE_SUCCESS";
export const CONNECT_GET_ONE_FAIL = "CONNECT_GET_ONE_FAIL";
export const CONNECT_GET_ONE_RESET = "CONNECT_GET_ONE_RESET";

export const CONNECT_UPDATE_REQUEST = "CONNECT_UPDATE_REQUEST";
export const CONNECT_UPDATE_SUCCESS = "CONNECT_UPDATE_SUCCESS";
export const CONNECT_UPDATE_FAIL = "CONNECT_UPDATE_FAIL";
export const CONNECT_UPDATE_RESET = "CONNECT_UPDATE_RESET";