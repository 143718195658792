// Chakra imports
import { Flex, Grid, Spinner, Text, useColorModeValue } from "@chakra-ui/react";
import { useEffect } from "react";
import { FaCube, FaPenFancy } from "react-icons/fa";
import ProfileBgImage from "dashboard/assets/img/ProfileBackground.png";
import avatar4 from "dashboard/assets/img/avatars/avatar4.png";

import { useDispatch, useSelector } from "react-redux";

import { IoDocumentsSharp } from "react-icons/io5";

import Companies from "./ui/Companies";
import Header from "./ui/Header"; 
import ProfileInformation from "./ui/ProfileInformation";

import { getAllCompaniesAction } from "../../../entities/company";
import { getUserDataAction } from "../../../entities/user";
import { useDidMount } from "shared/hooks/useDidMount";
import { usePushNotification } from "shared/hooks/usePushNotification";

function Profile() {
    // Chakra color mode
    const bgProfile = useColorModeValue(
        "hsla(0,0%,100%,.8)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );

    const dispatch = useDispatch();
    const didMount = useDidMount();

    const companyCreate = useSelector((state) => state.companyCreate);
    const companyDelete = useSelector((state) => state.companyDelete);
    const companyUpdate = useSelector((state) => state.companyUpdate);

    const {error: errorCompanyUpdate, loading: loadingCompanyUpdate} = companyUpdate;
    const {error: errorDeleteCompany, loading: loadingDeleteCompany} = companyDelete;
    const {error: errorCreateCompany, loading: loadingCreateCompany} = companyCreate;

    const userDetails = useSelector((state) => state.userData);
    const userUpdate = useSelector((state) => state.userUpdate);
    const {loading: userUpdateLoading, error: errorUpdateUser} = userUpdate;
    const {user, error, loading} = userDetails;

    useEffect(() => {
        dispatch(getUserDataAction());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllCompaniesAction());
    }, [companyCreate, companyDelete, companyUpdate]);

    usePushNotification(
        errorUpdateUser,
        userUpdateLoading,
        didMount,
        "Данные пользователя успешно обновлены",
        "Произошла ошибка при обновлении данных пользователя",
        userUpdate
    );

    usePushNotification(
        errorDeleteCompany,
        loadingDeleteCompany,
        didMount,
        "Компания успешно удалена",
        "Произошла ошибка при удалении компании",
        companyDelete
    );

    usePushNotification(
        errorCreateCompany,
        loadingCreateCompany,
        didMount,
        "Компания успешно создана",
        "Произошла ошибка при создании компании",
        companyCreate
    );

    usePushNotification(
        errorCompanyUpdate,
        loadingCompanyUpdate,
        didMount,
        "Компания успешно обновлена",
        "Произошла ошибка при обновлении компании",
        companyUpdate
    );

    const allCompanies = useSelector((state) => state.allCompanies);
    const {companies, loading: loadingCompany} = allCompanies;

    return (
        <Flex direction="column">
            <Header
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                avatarImage={avatar4}
                name={user ? user.first_name + " " + user.last_name : ""}
                email={user ? user.email : ""}
                loading={loading}
                tabs={[
                    {
                        name: "OVERVIEW",
                        icon: <FaCube w="100%" h="100%" />,
                    },
                    {
                        name: "TEAMS",
                        icon: <IoDocumentsSharp w="100%" h="100%" />,
                    },
                    {
                        name: "PROJECTS",
                        icon: <FaPenFancy w="100%" h="100%" />,
                    },
                ]}
            />
            {loading || loadingCompany || userUpdateLoading ? (
                <Flex mt="100px" mb="100px" justifyContent="center">
                    <Spinner w="100px" h="100px" thickness="10px" />
                </Flex>
            ) : (
                <Grid
                    templateColumns={{sm: "1fr", md: "repeat(2, 1fr)"}}
                    gap="22px"
                >
                    <ProfileInformation
                        title={"Информация о профиле"}
                        description={user ? user.description : ""}
                        mobile={user ? user.phone_number : ""}
                        rate={user ? user.rate : ""}
                        job={user ? user.job_title : ""}
                        name={
                            user ? user.first_name + " " + user.last_name : ""
                        }
                        email={user ? user.email : ""}
                        error={error}
                    />
                    {companies ? (
                        <Companies
                            title={"Компании"}
                            companyInfo={companies[0]}
                        />
                    ) : (
                        <Text color="red">
                            Произошла ошибка при загрузке информации и компании
                        </Text>
                    )}
                </Grid>
            )}
        </Flex>
    );
}

export default Profile;
