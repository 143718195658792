import {
    REVIEW_ONE_REQUEST,
    REVIEW_ONE_SUCCESS,
    REVIEW_ONE_FAIL
} from "./getReviewState";

export const reviewGetOneReducer = (state = {review: {}}, action) => {
    switch (action.type) {
        case REVIEW_ONE_REQUEST:
            return {...state, loading: true};

        case REVIEW_ONE_SUCCESS:
            return {loading: false, review: action.payload};

        case REVIEW_ONE_FAIL:
            return {loading: false, error: action.payload};

        default:
            return state;
    }
};