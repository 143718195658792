import styles from "./CompletedWindow.module.scss";

export const CompletedWindow = () => {
    return (
        <div className={styles.completedWindow}>
            <h2 className={styles.completedWindow__title}>
                Большое спасибо!
            </h2>
            <p className={styles.completedWindow__message}>
                Мы примем меры, чтобы исправить сложившуюся <br />
                ситуацию и улучшить наш сервис в будущем. <br />
                Спасибо за ваш отзыв, он очень важен для нас.
            </p>
        </div>
    );
};