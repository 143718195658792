import {
    REVIEW_UPDATE_REQUEST,
    REVIEW_UPDATE_SUCCESS,
    REVIEW_UPDATE_FAIL,
    REVIEW_UPDATE_RESET
} from "./updateReviewState";

export const reviewUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case REVIEW_UPDATE_REQUEST:
            return {loading: true};

        case REVIEW_UPDATE_SUCCESS:
            return {loading: false, success: true};

        case REVIEW_UPDATE_FAIL:
            return {loading: false, error: action.payload};
        
        case REVIEW_UPDATE_RESET:
            return {review: {}};

        default:
            return state;
    }
};