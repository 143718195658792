import { 
    BRANCH_ALL_REQUEST,
    BRANCH_ALL_SUCCESS,
    BRANCH_ALL_FAIL
} from "./getBranchListState";

export const branchAllReducer = (state = {branches: {branches: []}}, action) => {
    switch (action.type) {
        case BRANCH_ALL_REQUEST:
            return {...state, loading: true};

        case BRANCH_ALL_SUCCESS:
            return {loading: false, branches: action.payload};

        case BRANCH_ALL_FAIL:
            return {loading: false, error: action.payload};

        default:
            return state;
    }
};