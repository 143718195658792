// Chakra imports
import {
    Box,
    Button,
    Flex,
    HStack,
    useColorModeValue
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import routes from "../../routers/routes.js";
import {
    DocumentIcon,
    HomeIcon,
    RocketIcon,
} from "../Icons/Icons.js";
import SidebarResponsive from "../Sidebar/SidebarResponsive.js";

export default function AuthNavbar(props) {
    const [open, setOpen] = useState(false);
    const handleDrawerToggle = () => {
        setOpen(!open);
    };
    const {logo, logoText, secondary, ...rest} = props;
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    };
    // Chakra color mode
    let navbarIcon = useColorModeValue("gray.700", "gray.200");
    let mainText = useColorModeValue("gray.700", "gray.200");
    let navbarBg = useColorModeValue(
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
    );
    let navbarBorder = useColorModeValue(
        "1.5px solid #FFFFFF",
        "1.5px solid rgba(255, 255, 255, 0.31)"
    );
    let navbarShadow = useColorModeValue(
        "0px 7px 23px rgba(0, 0, 0, 0.05)",
        "none"
    );
    let navbarFilter = useColorModeValue(
        "none",
        "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
    );
    let navbarBackdrop = "blur(21px)";
    let bgButton = useColorModeValue(
        "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
        "gray.800"
    );
    let navbarPosition = "fixed";
    let colorButton = "white";
    if (props.secondary === true) {
        navbarIcon = "gray.700";
        navbarBg = "none";
        navbarBorder = "none";
        navbarShadow = "initial";
        navbarFilter = "initial";
        navbarBackdrop = "none";
        bgButton = "gray.700";
        colorButton = "white";
        mainText = "gray.700";
        navbarPosition = "absolute";
    }
    const brand = (
        <NavLink
            to="/"
            
        >
            <img src="/images/svg/navbar_brand_computer.svg" />

        </NavLink>
    );
    const linksAuth = (
        <HStack display={{sm: "none", lg: "flex"}}>
            <NavLink to="/">
                <Button
                    fontSize="sm"
                    ms="0px"
                    px="0px"
                    me={{sm: "2px", md: "16px"}}
                    color={navbarIcon}
                    variant="transparent-with-icon"
                    leftIcon={<HomeIcon color={navbarIcon} w="12px" h="12px" me="0px"/>}
                >
                    Главная
                </Button>
            </NavLink>
            {/* <NavLink to="/auth/signup">
                <Button
                    fontSize="sm"
                    ms="0px"
                    px="0px"
                    me={{sm: "2px", md: "16px"}}
                    color={navbarIcon}
                    variant="transparent-with-icon"
                    leftIcon={
                        <RocketIcon color={navbarIcon} w="12px" h="12px" me="0px"/>
                    }
                >
                    Регистрация
                </Button>
            </NavLink> */}
                <Button
                    fontSize="sm"
                    ms="0px"
                    px="0px"
                    me={{sm: "2px", md: "16px"}}
                    color={navbarIcon}
                    variant="transparent-with-icon"
                    leftIcon={
                        <RocketIcon color={navbarIcon} w="12px" h="12px" me="0px"/>
                    }
                >
                    Регистрация
                </Button>
            <NavLink to="/auth/signin">
                <Button
                    fontSize="sm"
                    ms="0px"
                    px="0px"
                    me={{sm: "2px", md: "16px"}}
                    color={navbarIcon}
                    variant="transparent-with-icon"
                    leftIcon={
                        <DocumentIcon color={navbarIcon} w="12px" h="12px" me="0px"/>
                    }
                >
                    Вход
                </Button>
            </NavLink>
        </HStack>
    );
    return (
        <Flex
            position={navbarPosition}
            top="16px"
            left="50%"
            transform="translate(-50%, 0px)"
            background={navbarBg}
            border={navbarBorder}
            boxShadow={navbarShadow}
            filter={navbarFilter}
            backdropFilter={navbarBackdrop}
            borderRadius="15px"
            px="16px"
            py="22px"
            mx="auto"
            width="1044px"
            maxW="90%"
            alignItems="center"
        >
            <Flex w="100%" justifyContent={{sm: "start", lg: "space-between"}} alignItems={{lg: "center"}}>
                {brand}
                <Box
                    ms={{base: "auto", lg: "0px"}}
                    display={{base: "flex", lg: "none"}}
                >
                    <SidebarResponsive
                        logoText={props.logoText}
                        secondary={props.secondary}
                        routes={routes.slice(11,13)}
                        // logo={logo}
                        {...rest}
                    />
                </Box>
                {linksAuth}
                <NavLink to="/">
                    <Button
                        bg={bgButton}
                        color={colorButton}
                        fontSize="xs"
                        variant="no-hover"
                        borderRadius="35px"
                        px="30px"
                        display={{
                            sm: "none",
                            lg: "flex",
                        }}
                    >
                        Главная
                    </Button>
                </NavLink>
            </Flex>
        </Flex>
    );
}

AuthNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    brandText: PropTypes.string,
};
