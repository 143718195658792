import {
    BRANCH_UPDATE_REQUEST,
    BRANCH_UPDATE_SUCCESS,
    BRANCH_UPDATE_FAIL
} from "./updateBranchState";
import { ERROR_500, ERROR_504 } from "app/store/utils/constants/errorConstants";
import axios from "axios";

export const updateBranch = (branchID, info) => async (dispatch, getState) => {
    try {
        dispatch({
            type: BRANCH_UPDATE_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        if (info.address === "") delete info.address;
        if (info.description === "") delete info.description;
        if (info.short_description === "") delete info.short_description;
        if (info.phone_number === "") delete info.phone_number;
        if (info.email === "") delete info.email;

        const {data} = await axios.put(
            `/branch/update/${branchID}`,
            info,
            config
        );

        dispatch({
            type: BRANCH_UPDATE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: BRANCH_UPDATE_FAIL,
                        payload: "Ошибка при запросе",
                    });
                    break;

                case 401:
                    dispatch({
                        type: BRANCH_UPDATE_FAIL,
                        payload: "Пустой или неправильный токен",
                    });
                    break;

                case 403:
                    dispatch({
                        type: BRANCH_UPDATE_FAIL,
                        payload: "Ошибка доступа",
                    });
                    break;

                case 404:
                    dispatch({
                        type: BRANCH_UPDATE_FAIL,
                        payload: "Филиал не найден",
                    });
                    break;

                case 500:
                    dispatch({
                        type: BRANCH_UPDATE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;

                default:
                    dispatch({
                        type: BRANCH_UPDATE_FAIL,
                        payload: "Произошла ошибка" + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: BRANCH_UPDATE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};