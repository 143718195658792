import axios from "axios";
import "./timeout";

import {
    COMPANY_CREATE_FAIL,
    COMPANY_CREATE_REQUEST,
    COMPANY_CREATE_SUCCESS,
    COMPANY_DELETE_FAIL,
    COMPANY_DELETE_REQUEST,
    COMPANY_DELETE_SUCCESS,
    COMPANY_READ_ALL_FAIL,
    COMPANY_READ_ALL_REQUEST,
    COMPANY_READ_ALL_SUCCESS,
    COMPANY_READ_FAIL,
    COMPANY_READ_REQUEST,
    COMPANY_READ_RESET,
    COMPANY_READ_SUCCESS,
    COMPANY_UPDATE_FAIL,
    COMPANY_UPDATE_REQUEST,
    COMPANY_UPDATE_RESET,
    COMPANY_UPDATE_SUCCESS
} from "../utils/constants/companyConstants";

import { ERROR_500, ERROR_504 } from "../utils/constants/errorConstants";

import { logout } from "./userActions";

export const createCompany = (companyName) => async (dispatch, getState) => {
    try {
        dispatch({
            type: COMPANY_CREATE_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.post(
            `/company/create/`,
            {name: companyName},
            config
        );

        dispatch({
            type: COMPANY_CREATE_SUCCESS,
            payload: data,
        });

        dispatch({
            type: COMPANY_READ_SUCCESS,
            payload: data,
        });

        localStorage.setItem("company", JSON.stringify(data));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: COMPANY_CREATE_FAIL,
                        payload: "Ошибка при создании",
                    });
                    break;

                case 500:
                    dispatch({
                        type: COMPANY_CREATE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;

                default:
                    dispatch({
                        type: COMPANY_CREATE_FAIL,
                        payload: "Произошла ошибка: " + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: COMPANY_CREATE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};

// export const readCompany = (companyID) => async (dispatch, getState) => {
//     try {
//         dispatch({
//             type: COMPANY_READ_REQUEST,
//         });

//         const {
//             userAuthentication: {userInfo},
//         } = getState();

//         const config = {
//             headers: {
//                 "Content-type": "application/json",
//                 Authorization: `Bearer ${userInfo.token}`,
//             },
//         };

//         const {data} = await axios.get(
//             `/company/read/${companyID}`,
//             config
//         );

//         dispatch({
//             type: COMPANY_READ_SUCCESS,
//             payload: data,
//         });

//         localStorage.setItem("company", JSON.stringify(data));
//     } catch (error) {
//         if (error.response) {
//             switch (error.response.status) {
//                 case 400:
//                     dispatch({
//                         type: COMPANY_READ_FAIL,
//                         payload: "Ошибка при запросе",
//                     });
//                     break;

//                 case 401:
//                     dispatch({
//                         type: COMPANY_READ_FAIL,
//                         payload: "Пустой или неправильный токен",
//                     });
//                     dispatch(logout());
//                     window.location.reload();
//                     break;

//                 case 403:
//                     dispatch({
//                         type: COMPANY_READ_FAIL,
//                         payload: "Ошибка доступа",
//                     });
//                     break;

//                 case 404:
//                     dispatch({
//                         type: COMPANY_READ_FAIL,
//                         payload: "Компания не найдена",
//                     });
//                     break;

//                 case 500:
//                     dispatch({
//                         type: COMPANY_READ_FAIL,
//                         payload: "500",
//                     });
//                     dispatch({
//                         type: ERROR_500,
//                         payload: true,
//                     });

//                     break;

//                 default:
//                     dispatch({
//                         type: COMPANY_READ_FAIL,
//                         payload: "Произошла ошибка: " + error,
//                     });
//                     break;
//             }
//         } else {
//             dispatch({
//                 type: COMPANY_READ_FAIL,
//                 payload: error.message,
//             });
//             if (error.message === "timeout of 5000ms exceeded") {
//                 dispatch({
//                     type: ERROR_504,
//                     payload: true,
//                 });
//             }
//         }
//     }
// };

export const getAllCompanies = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: COMPANY_READ_ALL_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.get(`/company/read/all/`, config);

        dispatch({
            type: COMPANY_READ_ALL_SUCCESS,
            payload: data,
        });
        localStorage.setItem("companies", data);
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: COMPANY_READ_ALL_FAIL,
                        payload: "Ошибка при запросе",
                    });
                    break;

                case 401:
                    dispatch({
                        type: COMPANY_READ_ALL_FAIL,
                        payload: "Пустой или неправильный токен",
                    });
                    dispatch(logout());
                    window.location.reload();
                    break;

                case 403:
                    dispatch({
                        type: COMPANY_READ_ALL_FAIL,
                        payload: "Ошибка доступа",
                    });
                    break;

                case 404:
                    dispatch({
                        type: COMPANY_READ_ALL_FAIL,
                        payload: "Компании не найдены",
                    });
                    break;

                case 500:
                    dispatch({
                        type: COMPANY_READ_ALL_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });
                    break;

                default:
                    dispatch({
                        type: COMPANY_READ_ALL_FAIL,
                        payload: "Произошла ошибка: " + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: COMPANY_READ_ALL_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};

export const updateCompany =
    (companyName, companyID) => async (dispatch, getState) => {
        try {
            dispatch({
                type: COMPANY_UPDATE_REQUEST,
            });

            const {
                userAuthentication: {userInfo},
            } = getState();

            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const {data} = await axios.put(
                `/company/update/${companyID}`,
                {name: companyName},
                config
            );

            dispatch({
                type: COMPANY_UPDATE_SUCCESS,
            });

            dispatch({
                type: COMPANY_READ_SUCCESS,
                payload: data,
            });

            localStorage.setItem("company", JSON.stringify(data));
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        dispatch({
                            type: COMPANY_UPDATE_FAIL,
                            payload: "Ошибка при запросе",
                        });
                        break;

                    case 403:
                        dispatch({
                            type: COMPANY_UPDATE_FAIL,
                            payload: "Ошибка доступа",
                        });
                        break;

                    case 404:
                        dispatch({
                            type: COMPANY_UPDATE_FAIL,
                            payload: "Компания не найдена",
                        });
                        break;

                    case 500:
                        dispatch({
                            type: COMPANY_UPDATE_FAIL,
                            payload: "500",
                        });
                        dispatch({
                            type: ERROR_500,
                            payload: true,
                        });

                        break;

                    default:
                        dispatch({
                            type: COMPANY_UPDATE_FAIL,
                            payload: "Произошла ошибка: " + error,
                        });
                        break;
                }
            } else {
                dispatch({
                    type: COMPANY_UPDATE_FAIL,
                    payload: error.message,
                });
                if (error.message === "timeout of 5000ms exceeded") {
                    dispatch({
                        type: ERROR_504,
                        payload: true,
                    });
                }
            }
        }
    };

export const deleteCompany = (companyID) => async (dispatch, getState) => {
    try {
        dispatch({
            type: COMPANY_DELETE_REQUEST,
        });

        localStorage.removeItem("company");

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.delete(
            `/company/delete/${companyID}`,
            config
        );

        dispatch({
            type: COMPANY_READ_RESET,
        });

        dispatch({
            type: COMPANY_UPDATE_RESET,
        });

        dispatch({
            type: COMPANY_DELETE_SUCCESS,
        });

        localStorage.removeItem("company");
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: COMPANY_DELETE_FAIL,
                        payload: "Ошибка при запросе",
                    });
                    break;

                case 403:
                    dispatch({
                        type: COMPANY_DELETE_FAIL,
                        payload: "Ошибка доступа",
                    });
                    break;

                case 404:
                    dispatch({
                        type: COMPANY_DELETE_FAIL,
                        payload: "Компания не найдена",
                    });
                    break;

                case 500:
                    dispatch({
                        type: COMPANY_DELETE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;

                default:
                    dispatch({
                        type: COMPANY_DELETE_FAIL,
                        payload: "Произошла ошибка: " + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: COMPANY_DELETE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};
