import {
    BRANCH_UPDATE_REQUEST,
    BRANCH_UPDATE_SUCCESS,
    BRANCH_UPDATE_FAIL,
    BRANCH_UPDATE_RESET
} from "./updateBranchState";

export const branchUpdateReducer = (state = {branch:{}}, action) => {
    switch (action.type) {
        case BRANCH_UPDATE_REQUEST:
            return {...state, loading: true};

        case BRANCH_UPDATE_SUCCESS:
            return {loading: false, success: true};

        case BRANCH_UPDATE_FAIL:
            return {loading: false, error: action.payload};

        case BRANCH_UPDATE_RESET:
            return {branch: {}};

        default:
            return state;
    }
};