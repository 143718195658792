import {
    COMPANY_UPDATE_REQUEST,
    COMPANY_UPDATE_SUCCESS,
    COMPANY_UPDATE_FAIL,
    COMPANY_UPDATE_RESET
} from "./updateCompanyState";

export const companyUpdateReducer = (state = {}, action) => {
    switch (action.type) {
        case COMPANY_UPDATE_REQUEST:
            return {loading: true};

        case COMPANY_UPDATE_SUCCESS:
            return {loading: false, success: true};

        case COMPANY_UPDATE_FAIL:
            return {loading: false, error: action.payload};
        
        case COMPANY_UPDATE_RESET:
            return {company: null};

        default:
            return state;
    }
};