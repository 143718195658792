import AdminLayout from "dashboard/layouts/AdminLayout/ui/AdminLayout";
import AuthLayout from "dashboard/layouts/AuthLayout/ui/AuthLayout";
import AllReviews from "dashboard/pages/AllReviews";
import Billing from "dashboard/pages/Billing";
import Branch from "dashboard/pages/Branch";
import Company from "dashboard/pages/Company";
import Dashboard from "dashboard/pages/Dashboard";
import Profile from "dashboard/pages/Profile";
import Review from "dashboard/pages/Review";
import Tables from "dashboard/pages/Tables";
import { Spinner } from "widgets/Preloader";
import { Preloader } from "widgets/Preloader";
import { ErrorPage } from "pages/ErrorPage";
import { LogInPage } from "pages/LogInPage";
import { QRRatesPage } from "pages/QRRatesPage";
// import { RegistrationPage } from "pages/RegistrationPage";
import { VerificationPage } from "pages/VerificationPage";
import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

const HomePage = lazy(() => import('pages/HomePage/ui/HomePage'));

export const router = createBrowserRouter([
    {
        path: "/",
        element:
            <Suspense
                fallback={
                    <Preloader
                        ><Spinner />
                    </Preloader>
                }
            >
                <HomePage />
            </Suspense>,
        errorElement: <ErrorPage errorMessage="страница не найдена" errorStatus="404" />,
    },
    {
        path: "/auth",          
        element: <AuthLayout />,
        errorElement: <ErrorPage errorMessage="Страница не найдена" errorStatus="404" />,
        children: [
            {
                path: "/auth",
                element: <ErrorPage errorMessage="Страницы не найдена" errorStatus="404" />
            },
            // {
            //     path: "/auth/signup",
            //     element: <RegistrationPage />
            // },
            {
                path: "/auth/signin",
                element: <LogInPage />
            },
            {
                path: "/auth/verify/:code",
                element: <VerificationPage />
            }
        ]
    },
    {
        path: "/dashboard",
        element: <AdminLayout />,
        children: [
            {
                path: "/dashboard",
                element: <Dashboard />
            },
            {
                path: "/dashboard/branches",
                element: <Tables />
            },
            {
                path: "/dashboard/billing",
                element: <Billing />
            },
            {
                path: "/dashboard/profile",
                element: <Profile />
            },
            {
                path: "/dashboard/reviews",
                element: <AllReviews />
            },
            {
                path: "/dashboard/company",
                element: <Company />
            },
            {
                path: "/dashboard/reviews/review/:id",
                element: <Review />
            },
            {
                path: "/dashboard/branches/branch/:id",
                element: <Branch />
            },
            {
                path: "/dashboard/branches?page=:id",
                element: <Tables />
            },
            {
                path: "/dashboard/reviews?page=:id",
                element: <AllReviews />
            },
            {
                path: "/dashboard/branches/branch/:id?desk=:num/page=:id",
                element: <AllReviews />
            },
            {
                path: "/dashboard/branches/branch/:id?desk=:num/page=:id",
                element: <AllReviews />
            },
            {
                path: "/dashboard/company?desk=:id",
                element: <Company />
            },
            {
                path: "/dashboard/branches/branch/:id?desk=:num",
                element: <Branch />
            },
        ]
    },
    {
        path: "/qrcoderates/:slug",
        element: <QRRatesPage />
    }
]);