import { 
    COMPANY_CREATE_REQUEST,
    COMPANY_CREATE_SUCCESS,
    COMPANY_CREATE_FAIL
} from "./createCompanyState";
import { COMPANY_READ_SUCCESS } from "app/store/utils/constants/companyConstants";
import { ERROR_500, ERROR_504 } from "app/store/utils/constants/errorConstants";
import axios from "axios";

//FIXME: где то есть ошибка
export const createCompanyAction = (companyName) => async (dispatch, getState) => {
    try {
        dispatch({
            type: COMPANY_CREATE_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.post(
            `/company/create/`,
            {name: companyName},
            config
        );

        dispatch({
            type: COMPANY_CREATE_SUCCESS,
            payload: data,
        });

        dispatch({
            type: COMPANY_READ_SUCCESS,
            payload: data,
        });

        localStorage.setItem("company", JSON.stringify(data));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: COMPANY_CREATE_FAIL,
                        payload: "Ошибка при создании",
                    });
                    break;

                case 500:
                    dispatch({
                        type: COMPANY_CREATE_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });

                    break;

                default:
                    dispatch({
                        type: COMPANY_CREATE_FAIL,
                        payload: "Произошла ошибка: " + error,
                    });
                    break;
            }
        } else {
            dispatch({
                type: COMPANY_CREATE_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};