import { REVIEW_LIST_REQUEST, REVIEW_LIST_SUCCESS, REVIEW_LIST_FAIL } from "./getReviewBranchListState";

export const reviewListReducer = (state = {reviewsList: {reviews: []}}, action) => {
    switch (action.type) {
        case REVIEW_LIST_REQUEST:
            return {...state, loading: true};

        case REVIEW_LIST_SUCCESS:
            return {loading: false, reviewsList: action.payload};

        case REVIEW_LIST_FAIL:
            return {loading: false, error: action.payload};

        default:
            return state;
    }
};