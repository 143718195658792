// Chakra imports
import {
    Button,
    Flex,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DescriptionCard from "./ui/DescriptionCard";

import { getAllCompaniesAction } from "../../../entities/company";
import { createCompanyAction } from "../../../entities/company";
import { useDidMount } from "../../../shared/hooks/useDidMount";
import { usePushNotification } from "../../../shared/hooks/usePushNotification";
import { Preloader } from "widgets/Preloader";

export default function Dashboard() {
    const textColor = useColorModeValue("gray.700", "white");

    const {isOpen, onOpen, onClose} = useDisclosure();

    const dispatch = useDispatch();

    const didMount = useDidMount();

    const [nameCompany, setNameCompany] = useState("");
    const [warning, setWarning] = useState("");

    const companyCreate = useSelector((state) => state.companyCreate);
    const companyDelete = useSelector((state) => state.companyDelete);
    const companyUpdate = useSelector((state) => state.companyUpdate);
    const allCompanies = useSelector((state) => state.allCompanies);

    const {companies, error, loading: companyReadLoading} = allCompanies;
    const {loading: companyCreateLoading, error: companyCreateError} = companyCreate;

    const inputBg = useColorModeValue("white", "gray.800");

    useEffect(() => {
        dispatch(getAllCompaniesAction());
    }, [companyCreate, companyDelete, companyUpdate]);

    usePushNotification(
        companyCreateError,
        companyCreateLoading,
        didMount,
        "Компания создана успешно",
        "Произошла ошибка при создании компании",
        companyCreate
    );

    const handleCreateCompany = () => {
        if (nameCompany !== "") {
            setWarning("");
            dispatch(createCompanyAction(nameCompany));
            onClose();
        } else {
            setWarning("Заполните, пожалуйста, название компании");
        }
    };

    return (
        <>
            {companies ? (
                <Flex flexDirection="column" pt={{base: "120px", md: "75px"}}>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Создать компанию</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex
                                direction="column"
                                justify="center"
                                align="center"
                            >
                                <Text
                                    fontSize="sm"
                                    color="gray.500"
                                    fontWeight="600"
                                    mb="20px"
                                >
                                    Название компании *
                                </Text>
                                <Flex align="center" mb="20px">
                                    <InputGroup
                                        bg={inputBg}
                                        borderRadius="15px"
                                        w="250px"
                                    >
                                        <Input
                                            value={nameCompany}
                                            onChange={(e) =>
                                                setNameCompany(e.target.value)
                                            }
                                            fontSize="xs"
                                            py="11px"
                                            borderRadius="inherit"
                                        />
                                    </InputGroup>
                                </Flex>
                            </Flex>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="outline" onClick={handleCreateCompany}>
                                Создать
                            </Button>
                            {warning}
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                {!error && companies[0] && !companyReadLoading ? (
                    <DescriptionCard
                        companyInfo={companies[0]}
                    />
                ) : companyCreateLoading || companyReadLoading ? (
                    <Flex mt="100px" mb="100px" justifyContent="center">
                        <Spinner w="100px" h="100px" thickness="10px" />
                    </Flex>
                ) : (
                    <Flex
                        justify="center"
                        gap="20px"
                        mt="20%"
                        align="center"
                        direction="column"
                    >
                        <Text fontSize="2xl" align="center" color={textColor}>
                            Вы еще не создали компанию
                        </Text>
                        <Button
                            variant="outline"
                            onClick={onOpen}
                            width={{md: "30%"}}
                            colorScheme="facebook"
                        >
                            Добавить компанию
                        </Button>
                    </Flex>
                )}
    
                {/* <Grid
                    templateColumns={{sm: "1fr", lg: "1.3fr 1.7fr"}}
                    templateRows={{sm: "repeat(2, 1fr)", lg: "1fr"}}
                    gap='24px'
                    mb={{lg: "26px"}}>
                    <ActiveUsers
                        title={"Active Users"}
                        percentage={23}
                        chart={<BarChart/>}
                    />
                    <SalesOverview
                        title={"Sales Overview"}
                        percentage={5}
                        chart={<LineChart/>}
                    />
                </Grid>
                <Grid
                    templateColumns={{sm: "1fr", md: "1fr 1fr", lg: "2fr 1fr"}}
                    templateRows={{sm: "1fr auto", md: "1fr", lg: "1fr"}}
                    gap='24px'>
                    <Projects
                        title={"Projects"}
                        amount={30}
                        captions={["Companies", "Members", "Budget", "Completion"]}
                        data={dashboardTableData}
                    />
                    <OrdersOverview
                        title={"Orders Overview"}
                        amount={30}
                        data={timelineData}
                    />
                </Grid> */}
            </Flex>
            ) : <Preloader><Spinner w="100px" h="100px" thickness="10px" /></Preloader>}
        </>
    );
}
