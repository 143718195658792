/* eslint-disable react/jsx-no-target-blank */
import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    Icon,
    Image,
    Spinner,
    Text
} from "@chakra-ui/react";

import { FaExternalLinkAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { redirect, useNavigate } from "react-router-dom";
import { qrCodeCreate } from "../../../../app/store/actions/QRActions";

const QRcodeBranch = ({branchId}) => {
    const QRGetOne = useSelector((state) => state.QRGetOne);
    const {code, loading} = QRGetOne;

    let navigate = useNavigate();

    const dispatch = useDispatch();

    const handleCreate = () => {
        dispatch(qrCodeCreate(branchId));
    };

    return (
        <Flex>
            {code && Object.keys(code).length !== 0 ? (
                <Card w={{sm: "100%", md: "70%"}} mt="30px" boxShadow="lg">
                    <CardHeader>
                        <Heading size="md">Информация о qr-коде</Heading>
                    </CardHeader>
                    <CardBody>
                        <Flex
                            direction={{sm: "row", base: "column"}}
                            gap="20px"
                            justify="space-between"
                            align="center"
                        >
                            <Box>
                                <Heading
                                    size="xs"
                                    mb="10px"
                                    textTransform="uppercase"
                                >
                                    QR-код
                                </Heading>
                                <Image src={code.image}/>
                                <Flex
                                    justifyContent="space-between"
                                >
                                    <Button>
                                        <a 
                                            href={code.image}
                                            target="_blank"    
                                        >
                                            скачать
                                        </a>
                                    </Button>
                                    <Box
                                        onClick={() =>
                                            navigate(
                                                `/qrcoderates/${code.slug_name}`
                                            )
                                        }
                                        _hover={{color: "#4584FF", cursor: "pointer"}}
                                    >
                                        <Icon as={FaExternalLinkAlt}/>
                                    </Box>
                                </Flex>
                            </Box>
                        </Flex>
                    </CardBody>
                </Card>
            ) : loading ? (
                <Flex w="100vw" justifyContent="center">
                    <Spinner w="100px" h="100px" mt="100px" thickness="10px"/>
                </Flex>
            ) : (
                <Flex direction="column" mt="20px" gap="20px">
                    <Text>QR-код для этого филиала еще не создан</Text>
                    <Button colorScheme="facebook" onClick={handleCreate}>
                        Создать QR-код
                    </Button>
                </Flex>
            )}
        </Flex>
    );
};

export default QRcodeBranch;
