// Chakra Imports
import { Button, useColorModeValue } from "@chakra-ui/react";
// Custom Icons
import PropTypes from "prop-types";
import React from "react";
import { SettingsIcon } from "../Icons/Icons";

export default function FixedPlugin(props) {
    const {secondary, onChange, onSwitch, fixed, ...rest} = props;
    // Chakra Color Mode
    let navbarIcon = useColorModeValue("gray.500", "gray.200");
    let bgButton = useColorModeValue("white", "gray.600");
    let fixedDisplay = "flex";
    if (props.secondary) {
        fixedDisplay = "none";
    }

    const settingsRef = React.useRef();
    return (
        <>
            <Button
                h="52px"
                w="52px"
                onClick={props.onOpen}
                bg={bgButton}
                position="fixed"
                variant="no-hover"
                left={document.documentElement.dir === "rtl" ? "35px" : ""}
                right={document.documentElement.dir === "rtl" ? "" : "35px"}
                bottom="30px"
                borderRadius="50px"
                boxShadow="0 2px 12px 0 rgb(0 0 0 / 16%)"
            >
                <SettingsIcon
                    cursor="pointer"
                    ref={settingsRef}
                    color={navbarIcon}
                    w="20px"
                    h="20px"
                />
            </Button>
        </>
    );
}

FixedPlugin.propTypes = {
    fixed: PropTypes.bool,
    onChange: PropTypes.func,
    onSwitch: PropTypes.func,
};
