export const ANSWER_CREATE_REQUEST = "ANSWER_CREATE_REQUEST";
export const ANSWER_CREATE_SUCCESS = "ANSWER_CREATE_SUCCESS";
export const ANSWER_CREATE_FAIL = "ANSWER_CREATE_FAIL";

export const ANSWER_DELETE_REQUEST = "ANSWER_DELETE_REQUEST";
export const ANSWER_DELETE_SUCCESS = "ANSWER_DELETE_SUCCESS";
export const ANSWER_DELETE_FAIL = "ANSWER_DELETE_FAIL";

export const ANSWER_READ_REQUEST = "ANSWER_READ_REQUEST";
export const ANSWER_READ_SUCCESS = "ANSWER_READ_SUCCESS";
export const ANSWER_READ_FAIL = "ANSWER_READ_FAIL";
export const ANSWER_READ_RESET = "ANSWER_READ_RESET";

export const ANSWER_UPDATE_REQUEST = "ANSWER_UPDATE_REQUEST";
export const ANSWER_UPDATE_SUCCESS = "ANSWER_UPDATE_SUCCESS";
export const ANSWER_UPDATE_FAIL = "ANSWER_UPDATE_FAIL";
export const ANSWER_UPDATE_RESET = "ANSWER_UPDATE_RESET";
