import { List } from "shared/ui/List";
import { getIconByType } from "../../../helpers/getIconByType";
import styles from "./ReviewWindow.module.scss";

const linkToReview = ({
    type,
    url,
    id
}) => {
    const icon = getIconByType(type);
    
    return (
        <a className={styles.reviewWindow__item} href={url}>
            <img src={icon} />
        </a> 
    );
};

export const ReviewWindow = ({ data }) => {
    return (
        <List
            listStyle={styles.reviewWindow}
            itemStyle={styles.reviewWindow__link}
            itemContentRender={linkToReview}
            data={data}
        />
    );
};
