import {
    USER_AUTHENTICATION_REQUEST,
    USER_AUTHENTICATION_SUCCESS,
    USER_AUTHENTICATION_FAIL
 } from "dashboard/layouts/AdminLayout/store/states/userAuthenticationState";
import { ERROR_500, ERROR_504 } from "app/store/utils/constants/errorConstants";
import axios from "axios";

export const userAuthenticationCheck = (username, password) => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_AUTHENTICATION_REQUEST
        });
    
        const config = {
            headers: {
                "Content-type": "application/json",
            },
        };
    
        const { data } = await axios.post(
            "/user/login/",
            {
                username: username,
                password: password
            },
            config
        );
    
        dispatch({
            type: USER_AUTHENTICATION_SUCCESS,
            payload: data,
        });

        localStorage.setItem("userInfo", JSON.stringify(data));
        localStorage.setItem("isLoggedIn", true);
    } catch(error) {
        if (error.response) {
            switch (error.response.status) {
                case 500:
                    dispatch({
                        type: USER_AUTHENTICATION_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });
                    break;
                default:
                    dispatch({
                        type: USER_AUTHENTICATION_FAIL,
                        payload: "Ошибка авторизации. Попробуйте еще раз",
                    });
            }
        } else {
            dispatch({
                type: USER_AUTHENTICATION_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};