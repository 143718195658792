import axios from "axios";
import "./timeout";

import {
    REVIEW_SETTINGS_CREATE_FAIL,
    REVIEW_SETTINGS_CREATE_REQUEST,
    REVIEW_SETTINGS_CREATE_SUCCESS,
    REVIEW_SETTINGS_DELETE_FAIL,
    REVIEW_SETTINGS_DELETE_REQUEST,
    REVIEW_SETTINGS_DELETE_SUCCESS,
    REVIEW_SETTINGS_READ_FAIL,
    REVIEW_SETTINGS_READ_REQUEST,
    REVIEW_SETTINGS_READ_RESET,
    REVIEW_SETTINGS_READ_SUCCESS,
    REVIEW_SETTINGS_UPDATE_FAIL,
    REVIEW_SETTINGS_UPDATE_REQUEST,
    REVIEW_SETTINGS_UPDATE_RESET,
    REVIEW_SETTINGS_UPDATE_SUCCESS,
} from "../utils/constants/reviewSettingsConstants";

import { ERROR_500, ERROR_504 } from "../utils/constants/errorConstants";

export const createReviewSettings =
    (reviewSettingsInfo) => async (dispatch, getState) => {
        try {
            dispatch({
                type: REVIEW_SETTINGS_CREATE_REQUEST,
            });

            const {
                userAuthentication: {userInfo},
            } = getState();

            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const {data} = await axios.post(
                `/review_settings/create/`,
                reviewSettingsInfo,
                config
            );

            dispatch({
                type: REVIEW_SETTINGS_CREATE_SUCCESS,
                payload: data,
            });
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        dispatch({
                            type: REVIEW_SETTINGS_CREATE_FAIL,
                            payload: "Ошибка при создании",
                        });
                        break;

                    case 403:
                        dispatch({
                            type: REVIEW_SETTINGS_CREATE_FAIL,
                            payload: "Ошибка доступа",
                        });
                        break;

                    case 404:
                        dispatch({
                            type: REVIEW_SETTINGS_CREATE_FAIL,
                            payload: "Компания не найдена",
                        });
                        break;

                    case 500:
                        dispatch({
                            type: REVIEW_SETTINGS_CREATE_FAIL,
                            payload: "500",
                        });
                        dispatch({
                            type: ERROR_500,
                            payload: true,
                        });
                        break;

                    default:
                        dispatch({
                            type: REVIEW_SETTINGS_CREATE_FAIL,
                            payload: "Произошла ошибка: " + error,
                        });
                        break;
                }
            } else {
                dispatch({
                    type: REVIEW_SETTINGS_CREATE_FAIL,
                    payload: error.message,
                });
                if (error.message === "timeout of 5000ms exceeded") {
                    dispatch({
                        type: ERROR_504,
                        payload: true,
                    });
                }
            }
        }
    };

export const getReviewSettings =
    (reviewSettingsID) => async (dispatch, getState) => {
        try {
            dispatch({
                type: REVIEW_SETTINGS_READ_REQUEST,
            });

            const {
                userAuthentication: {userInfo},
            } = getState();

            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const {data} = await axios.get(
                `/review_settings/read/${reviewSettingsID}`,
                config
            );

            dispatch({
                type: REVIEW_SETTINGS_READ_SUCCESS,
                payload: data,
            });
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        dispatch({
                            type: REVIEW_SETTINGS_READ_FAIL,
                            payload: "Ошибка при запросе",
                        });
                        break;

                    case 403:
                        dispatch({
                            type: REVIEW_SETTINGS_READ_FAIL,
                            payload: "Ошибка доступа",
                        });
                        break;

                    case 404:
                        dispatch({
                            type: REVIEW_SETTINGS_READ_FAIL,
                            payload: "Компания не найдена",
                        });
                        break;

                    case 500:
                        dispatch({
                            type: REVIEW_SETTINGS_READ_FAIL,
                            payload: "500",
                        });
                        dispatch({
                            type: ERROR_500,
                            payload: true,
                        });
                        break;

                    default:
                        dispatch({
                            type: REVIEW_SETTINGS_READ_FAIL,
                            payload: "Произошла ошибка: " + error,
                        });
                        break;
                }
            } else {
                dispatch({
                    type: REVIEW_SETTINGS_READ_FAIL,
                    payload: error.message,
                });
                if (error.message === "timeout of 5000ms exceeded") {
                    dispatch({
                        type: ERROR_504,
                        payload: true,
                    });
                }
            }
        }
    };

export const updateReviewSettings =
    (companyId, reviewSettingsInfo) => async (dispatch, getState) => {
        try {
            dispatch({
                type: REVIEW_SETTINGS_UPDATE_REQUEST,
            });

            const {
                userAuthentication: {userInfo},
            } = getState();

            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const {data} = await axios.put(
                `/review_settings/update/${companyId}`,
                reviewSettingsInfo,
                config
            );

            dispatch({
                type: REVIEW_SETTINGS_UPDATE_SUCCESS,
                payload: data,
            });
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        dispatch({
                            type: REVIEW_SETTINGS_UPDATE_FAIL,
                            payload: "Ошибка при запросе",
                        });
                        break;

                    case 403:
                        dispatch({
                            type: REVIEW_SETTINGS_UPDATE_FAIL,
                            payload: "Ошибка доступа",
                        });
                        break;

                    case 404:
                        dispatch({
                            type: REVIEW_SETTINGS_UPDATE_FAIL,
                            payload: "Компания не найдена",
                        });
                        break;

                    case 500:
                        dispatch({
                            type: REVIEW_SETTINGS_UPDATE_FAIL,
                            payload: "500",
                        });
                        dispatch({
                            type: ERROR_500,
                            payload: true,
                        });
                        break;

                    default:
                        dispatch({
                            type: REVIEW_SETTINGS_UPDATE_FAIL,
                            payload: "Произошла ошибка: " + error,
                        });
                        break;
                }
            } else {
                dispatch({
                    type: REVIEW_SETTINGS_UPDATE_FAIL,
                    payload: error.message,
                });
                if (error.message === "timeout of 5000ms exceeded") {
                    dispatch({
                        type: ERROR_504,
                        payload: true,
                    });
                }
            }
        }
    };

export const deleteReviewSettings =
    (reviewSettingsID) => async (dispatch, getState) => {
        try {
            dispatch({
                type: REVIEW_SETTINGS_DELETE_REQUEST,
            });

            const {
                userAuthentication: {userInfo},
            } = getState();

            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const {data} = await axios.delete(
                `/review_settings/delete/${reviewSettingsID}`,
                config
            );

            dispatch({
                type: REVIEW_SETTINGS_DELETE_SUCCESS,
            });

            dispatch({
                type: REVIEW_SETTINGS_READ_RESET,
            });

            dispatch({
                type: REVIEW_SETTINGS_UPDATE_RESET,
            });
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        dispatch({
                            type: REVIEW_SETTINGS_DELETE_FAIL,
                            payload: "Ошибка при запросе",
                        });
                        break;

                    case 403:
                        dispatch({
                            type: REVIEW_SETTINGS_DELETE_FAIL,
                            payload: "Ошибка доступа",
                        });
                        break;

                    case 404:
                        dispatch({
                            type: REVIEW_SETTINGS_DELETE_FAIL,
                            payload: "Компания не найдена",
                        });
                        break;

                    case 500:
                        dispatch({
                            type: REVIEW_SETTINGS_DELETE_FAIL,
                            payload: "500",
                        });
                        dispatch({
                            type: ERROR_500,
                            payload: true,
                        });
                        break;

                    default:
                        dispatch({
                            type: REVIEW_SETTINGS_DELETE_FAIL,
                            payload: "Произошла ошибка: " + error,
                        });
                        break;
                }
            } else {
                dispatch({
                    type: REVIEW_SETTINGS_DELETE_FAIL,
                    payload: error.message,
                });
                if (error.message === "timeout of 5000ms exceeded") {
                    dispatch({
                        type: ERROR_504,
                        payload: true,
                    });
                }
            }
        }
    };
