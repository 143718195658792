export const formData = [
    {
        value: '',
        state: 'none',
        type: 'name'
    },
    {
        value: '',
        state: 'none',
        type: 'email'
    },
    {
        value: '',
        state: 'none',
        type: 'phone'
    },
    {
        value: '',
        state: 'none',
        type: 'message'
    }
];