// Chakra imports
import {
    Avatar,
    Button,
    Flex,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
// Assets
import picCompany from "../../../../dashboard/assets/svg/globe.svg";

// Custom components
import { useState } from "react";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";

import { useDispatch } from "react-redux";

import { CloseButton } from "react-bootstrap";

import { 
    createCompanyAction, 
    updateCompanyAction,
    deleteCompanyAction
} from "../../../../entities/company";

const Conversations = ({title, companyInfo}) => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
    const inputBg = useColorModeValue("white", "gray.800");

    const {
        isOpen: isCreateOpen,
        onOpen: onCreateOpen,
        onClose: onCreateClose,
    } = useDisclosure();

    const {
        isOpen: isEditOpen,
        onOpen: onEditOpen,
        onClose: onEditClose,
    } = useDisclosure();

    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose,
    } = useDisclosure();

    const dispatch = useDispatch();

    const [nameCreate, setNameCreate] = useState("");
    const [nameEdit, setNameEdit] = useState("");

    const [warning, setWarning] = useState("");

    const handleCreate = () => {
        if (nameCreate !== "") {
            setWarning("");
            dispatch(createCompanyAction(nameCreate));
            onCreateClose();
        } else {
            setWarning("Заполните пожалуйста название компании");
        }
    };

    const handleEdit = () => {
        if (nameEdit !== "") {
            setWarning("");
            dispatch(updateCompanyAction(nameEdit, companyInfo.id));
            onEditClose();
        } else {
            setWarning("Заполните пожалуйста название компании");
        }
    };

    const handleDeleteCompany = () => {
        dispatch(deleteCompanyAction(companyInfo.id));
        onDeleteClose();
    };

    return (
        <>
            <Modal isOpen={isCreateOpen} onClose={onCreateClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Создать компанию</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text
                            fontSize="sm"
                            color="gray.500"
                            fontWeight="600"
                            mb="20px"
                        >
                            Название компании *
                        </Text>
                        <Flex align="center" mb="20px">
                            <InputGroup
                                bg={inputBg}
                                borderRadius="15px"
                                w="250px"
                            >
                                <Input
                                    value={nameCreate}
                                    onChange={(e) =>
                                        setNameCreate(e.target.value)
                                    }
                                    fontSize="xs"
                                    py="11px"
                                    borderRadius="inherit"
                                />
                            </InputGroup>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="facebook" onClick={handleCreate}>
                            Создать
                        </Button>
                        <Button colorScheme="gray" onClick={onCreateClose}>
                            Закрыть
                        </Button>
                        {warning}
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isEditOpen} onClose={onEditClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Изменить компанию</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text
                            fontSize="sm"
                            color="gray.500"
                            fontWeight="600"
                            mb="20px"
                        >
                            Введите название компании *
                        </Text>
                        <InputGroup bg={inputBg} borderRadius="15px" w="250px">
                            <Input
                                value={nameEdit}
                                onChange={(e) => setNameEdit(e.target.value)}
                                fontSize="xs"
                                py="11px"
                                borderRadius="inherit"
                            />
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="facebook" mr={3} onClick={handleEdit}>
                            Сохранить
                        </Button>
                        <Button colorScheme="gray" onClick={onEditClose}>
                            Закрыть
                        </Button>
                        {warning}
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader closeButton></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    Вы действительно хотите удалить компанию?
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="red" onClick={handleDeleteCompany}>
                        Удалить
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
            <Card p="16px">
                <CardHeader p="12px 5px" mb="12px">
                    <Text fontSize="lg" color={textColor} fontWeight="bold">
                        {title}
                    </Text>
                </CardHeader>
                <CardBody px="5px">
                    <Flex direction="column" w="100%">
                        {companyInfo && (
                            <Flex justifyContent="space-between" mb="21px">
                                <Flex align="center">
                                    <Avatar
                                        src={picCompany}
                                        w="50px"
                                        h="50px"
                                        borderRadius="15px"
                                        me="10px"
                                    />
                                    <Flex direction="column">
                                        <Text
                                            fontSize="sm"
                                            color={textColor}
                                            fontWeight="bold"
                                        >
                                            Компания
                                        </Text>
                                        <Text
                                            fontSize="xs"
                                            color="gray.500"
                                            fontWeight="400"
                                        >
                                            {companyInfo.name}
                                        </Text>
                                    </Flex>
                                </Flex>
                                <Flex align="center" mx="10px">
                                    <CloseButton
                                        onClick={onDeleteOpen}
                                    />
                                </Flex>
                            </Flex>
                        )}
                        {companyInfo ? (
                            <Button
                                variant="outline"
                                colorScheme="facebook"
                                onClick={onEditOpen}
                            >
                                Редактировать
                            </Button>
                        ) : (
                            <Button
                                variant="outline"
                                colorScheme="facebook"
                                onClick={onCreateOpen}
                            >
                                Добавить компанию
                            </Button>
                        )}
                    </Flex>
                </CardBody>
            </Card>
        </>
    );
};

export default Conversations;
