import { useEffect } from "react";

export const useKeyPress = (key, callBack) => {
    const handleKeyDown = (event) => {
        if (event.key === key && callBack) {
            callBack();
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [key, callBack]); 

    return null;
};
