// Chakra imports
import { ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import Configurator from "../../../components/Configurator/Configurator.js";
import Footer from "../../../components/Footer/Footer.js";
// Layout components
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AdminNavbar from "../../../components/Navbars/AdminNavbar.js";
import Sidebar from "../../../components/Sidebar/index.js";
import routes from "../../../routers/routes.js";
// Custom Chakra theme
import theme from "dashboard/theme/theme.js";
import FixedPlugin from "../../../components/FixedPlugin/FixedPlugin.js";
// Custom components
import { ErrorPage } from "pages/ErrorPage/index.js";
import MainPanel from "../../../components/Layout/MainPanel.js";
import PanelContainer from "../../../components/Layout/PanelContainer.js";
import PanelContent from "../../../components/Layout/PanelContent.js";

export default function AdminLayout(props) {
    // states and functions
    const [sidebarVariant, setSidebarVariant] = useState("opaque");
    const [fixed, setFixed] = useState(false);
    const internalError = useSelector((state) => state.internalError);
    const userAuthentifiication = useSelector((state) => state.userAuthentication);
    const { isLoggedIn } = userAuthentifiication;
    const location = useLocation();
    const {error500, error504} = internalError;
    // const isLoggedIn = localStorage.getItem("isLoggedIn");

    const getPathTo = (routes) => {
        let result = {name: null, path: null};
        for (let i = 0; i < routes.length; i++) {
            if (
                routes[i].path.split("/").length > 2 &&
                window.location.href.indexOf(
                    routes[i].layout + routes[i].path.split(":")[0]
                ) !== -1
            ) {
                result.name = routes[i].secondName;
                result.path = routes[i].layout + routes[i].pathBack;
            }
        }
        return result;
    };

    const getActiveRoute = (routes) => {
        let activeRoute = "Личный кабинет";
        for (let i = 1; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(
                        routes[i].layout + routes[i].path
                    ) !== -1
                ) {
                    return routes[i].name;
                }
                if (routes[i].path.split(":")) {
                    if (
                        window.location.href.indexOf(
                            routes[i].layout + routes[i].path.split(":")[0]
                        ) !== -1
                    ) {
                        return routes[i].name;
                    }
                }
            }
        }
        return activeRoute;
    };

    // This changes navbar state(fixed or not)
    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].views);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (
                    window.location.href.indexOf(
                        routes[i].layout + routes[i].path
                    ) !== -1
                ) {
                    if (routes[i].secondaryNavbar) {
                        return routes[i].secondaryNavbar;
                    }
                }
            }
        }
        return activeNavbar;
    };
    const {isOpen, onOpen, onClose} = useDisclosure();
    document.documentElement.dir = "ltr";
    
    // Chakra Color Mode
    return isLoggedIn ? (
        <>
            {error500 ? (
                <ErrorPage errorMessage="попробуйте позже" errorStatus="500" />
            ) : error504 ? (
                <ErrorPage errorStatus="504" errorMessage="попробуйте позже" />
            ) : (
                <ChakraProvider theme={theme} resetCss={false}>
                    <Sidebar
                        routes={routes}
                        logoText={"RecTop"}
                        display="none"
                        sidebarVariant={sidebarVariant}
                        {...location.pathname}
                    />
                    <MainPanel
                        w={{
                            base: "100%",
                            xl: "calc(100% - 275px)",
                        }}
                    >
                        <Portal>
                            <AdminNavbar
                                onOpen={onOpen}
                                logoText={"RecTop"}
                                previousPage={getPathTo(routes).name}
                                pathToPage={getPathTo(routes).path}
                                brandText={getActiveRoute(routes)}
                                secondary={getActiveNavbar(routes)}
                                fixed={fixed}
                                {...location.pathname}
                            />
                        </Portal>

                        <PanelContent>
                            <PanelContainer>
                                <Outlet />
                            </PanelContainer>
                        </PanelContent>

                        <Footer />
                        <Portal>
                            <FixedPlugin
                                secondary={getActiveNavbar(routes)}
                                fixed={fixed}
                                onOpen={onOpen}
                            />
                        </Portal>
                        <Configurator
                            secondary={getActiveNavbar(routes)}
                            isOpen={isOpen}
                            onClose={onClose}
                            isChecked={fixed}
                            onSwitch={(value) => {
                                setFixed(value);
                            }}
                            onOpaque={() => setSidebarVariant("opaque")}
                            onTransparent={() =>
                                setSidebarVariant("transparent")
                            }
                        />
                    </MainPanel>
                </ChakraProvider>
            )}
        </>
    ) : <Navigate to={"/auth/signin"} />;
}
