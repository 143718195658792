import { useEffect, useRef, useState } from "react";
import { useKeyPress } from "shared/hooks/useKeyPress";
import styles from './Textarea.module.scss';
import cn from "shared/helpers/classNames";

export const Textarea = ({
    dataHandler,
    validation,
    errorState = false,
    className,
    placeholder,
    type,
    name,
    maxLength,
}) => {
    const [ value, setValue ] = useState('');
    const [ inputState, setInputState ] = useState('empty');
    const [ outline, setOutline ] = useState('none');
    const inputRef = useRef(null);

    const handleValue = (e) => {
        e.stopPropagation();
        let newValue = e.target.value;
    
        setValue(newValue);
    };

    const updateInputState = (value) => {  
        if (validation) {
            let validValue = validation(value);
            setInputState(validValue.state);
        } else {
           setInputState('none');
        }
    };

    const onBlurInput = (e) => {
        e.stopPropagation();
        dataHandler(e.target.value, inputState, name);
        updateInputState(e.target.value);
        setOutline(inputState);
    };

    const onKeyDown = () => {
        setOutline(inputState);
    };

    useKeyPress("Enter", onKeyDown);

    useEffect(() => {
        const validValue = validation ? validation(value) : { state: 'none' };
        setInputState(validValue.state);
        dataHandler(value, validValue.state, name);
    }, [value]);

    useEffect(() => {
        if (errorState === true) {
            setInputState("error");
            setOutline("error");
        }   
    }, [errorState]);

    return (
        <textarea
            className={cn([className, styles[`outline_${outline}`]])}
            placeholder={placeholder}
            type={type}
            name={name}
            maxLength={maxLength}
            onChange={(e) => handleValue(e)}
            onBlur={(e) => onBlurInput(e)}
            value={value}
            ref={inputRef}
        />
    );
};