import { REVIEW_LIST_REQUEST, REVIEW_LIST_SUCCESS, REVIEW_LIST_FAIL } from "./getReviewBranchListState";
import { ERROR_500, ERROR_504 } from "app/store/utils/constants/errorConstants";
import axios from "axios";

export const getOneReviewsListPage = (listID, page) => async (dispatch, getState) => {
        try {
            dispatch({
                type: REVIEW_LIST_REQUEST,
            });

            const {
                userAuthentication: {userInfo},
            } = getState();

            const config = {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${userInfo.token}`,
                },
            };

            const {data} = await axios.get(
                `/review/read/list/${listID}?page=${page}`,
                config
            );

            dispatch({
                type: REVIEW_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        dispatch({
                            type: REVIEW_LIST_FAIL,
                            payload: "Ошибка при запросе",
                        });
                        break;

                    case 403:
                        dispatch({
                            type: REVIEW_LIST_FAIL,
                            payload: "Ошибка доступа",
                        });
                        break;

                    case 404:
                        dispatch({
                            type: REVIEW_LIST_FAIL,
                            payload: "Отзыв не найден",
                        });
                        break;

                    case 500:
                        dispatch({
                            type: REVIEW_LIST_FAIL,
                            payload: "500",
                        });
                        dispatch({
                            type: ERROR_500,
                            payload: true,
                        });
                        break;

                    default:
                        dispatch({
                            type: REVIEW_LIST_FAIL,
                            payload: "Произошла ошибка: " + error,
                        });
                        break;
                }
            } else {
                dispatch({
                    type: REVIEW_LIST_FAIL,
                    payload: error.message,
                });
                if (error.message === "timeout of 5000ms exceeded") {
                    dispatch({
                        type: ERROR_504,
                        payload: true,
                    });
                }
            }
        }
    };