import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { thunk } from "redux-thunk";
// user
import { getUserDataReducer } from "../../entities/user";
import { userUpdateReducer } from "../../entities/user";
//company
import { companyCreateReducer } from "../../entities/company";
import { companyDeleteReducer } from "../../entities/company";
import { allCompaniesReducer } from "../../entities/company";
import { companyDetailsReducer } from "../../entities/company";
import { companyUpdateReducer } from "../../entities/company";
//branch
import { branchCreateReducer } from "../../entities/branch";
import { getBranchReducer } from "../../entities/branch";
import { branchAllReducer } from "../../entities/branch";
import { branchUpdateReducer } from "../../entities/branch";
//review
import { reviewCreateReducer } from "../../entities/review";
import { reviewDeleteReducer } from "../../entities/review";
import { reviewUpdateReducer } from "../../entities/review";
import { reviewGetOneReducer } from "../../entities/review";
import { reviewListReducer } from "../../entities/review";
import { reviewAllReducer } from "../../entities/review";

//erorr
import { errorReducer } from "shared/lib/error";

import {
    userSendCodeReducer,
    userLoginReducer,
    userRegisterReducer,
    userVerifyCodeReducer,
    userVerifyReducer,
} from "./reducers/userReducers";

import { rateInfoDataReducer } from "./reducers/rateInfoReducers";

import { taskReadReducer } from "./reducers/taskReducers";

import {
    rateAddReducer,
    rateChangeReducer,
    rateReadReducer,
} from "./reducers/rateReducers";

import {
    QRAllReducer,
    QRCreateReducer,
    QRGetOneReducer,
} from "./reducers/QRCodeRedusers";

import {
    answerCreateReducer,
    answerDeleteReducer,
    answerDetailsReducer,
    answerUpdateReducer,
} from "./reducers/answerReducers";

import {
    branchGetOneReducer,
} from "./reducers/branchReducers";

import {
    connectAllReducer,
    connectCreateReducer,
    connectDeleteReducer,
    connectGetOneReducer,
    connectUpdateReducer,
} from "./reducers/connectReducers";

import {
    telebotCreateReducer,
    telebotDeleteReducer,
    telebotDetailsReducer,
    telebotUpdateReducer,
} from "./reducers/telebotReducers";

import {
    reviewSettingsCreateReducer,
    reviewSettingsDeleteReducer,
    reviewSettingsDetailsReducer,
    reviewSettingsUpdateReducer,
} from "./reducers/reviewSettingsReducers";
import { userAuthentificateReducer} from "dashboard/layouts/AdminLayout/store/reducers/userAuthenticationReducer"; 

const reducer = combineReducers({
    //user
    userData: getUserDataReducer,
    userUpdate: userUpdateReducer,

    //user login
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userVerify: userVerifyReducer,
    userSendCode: userSendCodeReducer,
    userVerifyCode: userVerifyCodeReducer,
    userAuthentication: userAuthentificateReducer,

    //company
    companyCreate: companyCreateReducer,
    companyDelete: companyDeleteReducer,
    allCompanies: allCompaniesReducer,
    companyDetails: companyDetailsReducer,

    //branch
    branchCreate: branchCreateReducer,
    getBranchDetails: getBranchReducer,
    branchAll: branchAllReducer,
    branchUpdate: branchUpdateReducer,

    //review
    reviewCreate: reviewCreateReducer,
    reviewDelete: reviewDeleteReducer,
    reviewUpdate: reviewUpdateReducer,
    reviewGetOne: reviewGetOneReducer,
    reviewList: reviewListReducer,
    reviewAll: reviewAllReducer,

    //error
    internalError: errorReducer,

    // Данные о конкретном филиале
    rateInfoData: rateInfoDataReducer,

    rateAdd: rateAddReducer,
    rateChange: rateChangeReducer,
    rateRead: rateReadReducer,

    QRCreate: QRCreateReducer,
    QRAll: QRAllReducer,
    QRGetOne: QRGetOneReducer,

    taskRead: taskReadReducer,

    companyUpdate: companyUpdateReducer,

    branchGetOne: branchGetOneReducer,

    answerCreate: answerCreateReducer,
    answerDelete: answerDeleteReducer,
    answerDetails: answerDetailsReducer,
    answerUpdate: answerUpdateReducer,

    connectCreate: connectCreateReducer,
    connectAll: connectAllReducer,
    connectDelete: connectDeleteReducer,
    connectGetOne: connectGetOneReducer,
    connectUpdate: connectUpdateReducer,

    telebotCreate: telebotCreateReducer,
    telebotDetails: telebotDetailsReducer,
    telebotDelete: telebotDeleteReducer,
    telebotUpdate: telebotUpdateReducer,

    reviewSettingsCreate: reviewSettingsCreateReducer,
    reviewSettingsDelete: reviewSettingsDeleteReducer,
    reviewSettingsDetails: reviewSettingsDetailsReducer,
    reviewSettingsUpdate: reviewSettingsUpdateReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : {};

const isLoggedInFromStorage = localStorage.getItem("isLoggedIn")
    ? localStorage.getItem("isLoggedIn")
    : undefined;

const companyFromStorage = localStorage.getItem("isLoggedIn")
    ? JSON.parse(localStorage.getItem("company"))
    : null;

const companiesFromStorage = localStorage.getItem("companies") 
    ? JSON.parse(localStorage.getItem("company"))
    : {};

const initialState = {
    userAuthentication: {
        userInfo: userInfoFromStorage,
        isLoggedIn: isLoggedInFromStorage,
    },
    allCompanies: {
        companies: companiesFromStorage,
    },
    companyDetails: {
        company: companyFromStorage,
    },
};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    compose(applyMiddleware(...middleware))
);

export default store;
