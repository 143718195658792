import { 
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL
} from "./getUserDataState";
import { ERROR_500, ERROR_504 } from "app/store/utils/constants/errorConstants";
import axios from "axios";
import { logout } from "app/store/actions/userActions";

export const getUserDataAction = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: USER_DETAILS_REQUEST,
        });

        const {
            userAuthentication: {userInfo},
        } = getState();

        const config = {
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const {data} = await axios.get(`/user/read/`, config);

        dispatch({
            type: USER_DETAILS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    dispatch({
                        type: USER_DETAILS_FAIL,
                        payload: "Ошибка при запросе. Повторите позже",
                    });
                    break;
                case 401:
                    dispatch({
                        type: USER_DETAILS_FAIL,
                        payload: "Пустой или неправильный токен",
                    });
                    dispatch(logout());
                    window.location.reload();
                    break;
                case 500:
                    dispatch({
                        type: USER_DETAILS_FAIL,
                        payload: "500",
                    });
                    dispatch({
                        type: ERROR_500,
                        payload: true,
                    });
                    break;
                default:
                    dispatch({
                        type: USER_DETAILS_FAIL,
                        payload:
                            error.response && error.response.data.detail
                                ? error.response.data.detail
                                : error.message,
                    });
                    break;
            }
        } else {
            dispatch({
                type: USER_DETAILS_FAIL,
                payload: error.message,
            });
            if (error.message === "timeout of 5000ms exceeded") {
                dispatch({
                    type: ERROR_504,
                    payload: true,
                });
            }
        }
    }
};