import { 
    COMPANY_CREATE_REQUEST,
    COMPANY_CREATE_SUCCESS,
    COMPANY_CREATE_FAIL
} from "./createCompanyState";

export const companyCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case COMPANY_CREATE_REQUEST:
            return {loading: true};

        case COMPANY_CREATE_SUCCESS:
            return {loading: false, success: true};

        case COMPANY_CREATE_FAIL:
            return {loading: false, error: action.payload};

        default:
            return state;
    }
};