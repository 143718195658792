export const REVIEW_SETTINGS_CREATE_REQUEST = "REVIEW_SETTINGS_CREATE_REQUEST";
export const REVIEW_SETTINGS_CREATE_SUCCESS = "REVIEW_SETTINGS_CREATE_SUCCESS";
export const REVIEW_SETTINGS_CREATE_FAIL = "REVIEW_SETTINGS_CREATE_FAIL";

export const REVIEW_SETTINGS_DELETE_REQUEST = "REVIEW_SETTINGS_DELETE_REQUEST";
export const REVIEW_SETTINGS_DELETE_SUCCESS = "REVIEW_SETTINGS_DELETE_SUCCESS";
export const REVIEW_SETTINGS_DELETE_FAIL = "REVIEW_SETTINGS_DELETE_FAIL";

export const REVIEW_SETTINGS_READ_REQUEST = "REVIEW_SETTINGS_READ_REQUEST";
export const REVIEW_SETTINGS_READ_SUCCESS = "REVIEW_SETTINGS_READ_SUCCESS";
export const REVIEW_SETTINGS_READ_FAIL = "REVIEW_SETTINGS_READ_FAIL";
export const REVIEW_SETTINGS_READ_RESET = "REVIEW_SETTINGS_READ_RESET";

export const REVIEW_SETTINGS_UPDATE_REQUEST = "REVIEW_SETTINGS_UPDATE_REQUEST";
export const REVIEW_SETTINGS_UPDATE_SUCCESS = "REVIEW_SETTINGS_UPDATE_SUCCESS";
export const REVIEW_SETTINGS_UPDATE_FAIL = "REVIEW_SETTINGS_UPDATE_FAIL";
export const REVIEW_SETTINGS_UPDATE_RESET = "REVIEW_SETTINGS_UPDATE_RESET";