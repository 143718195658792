import { REVIEW_ALL_REQUEST, REVIEW_ALL_SUCCESS, REVIEW_ALL_FAIL } from "./getReviewCompanyListState";

export const reviewAllReducer = (state = {allReviews: {reviews: []}}, action) => {
    switch (action.type) {
        case REVIEW_ALL_REQUEST:
            return {...state, loading: true};

        case REVIEW_ALL_SUCCESS:
            return {loading: false, allReviews: action.payload};

        case REVIEW_ALL_FAIL:
            return {loading: false, error: action.payload};

        default:
            return state;
    }
};