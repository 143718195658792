import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    Spinner,
    Stack,
    StackDivider,
    Text,
} from "@chakra-ui/react";

import { useSelector } from "react-redux";

const DescriptionCard = () => {
    const reviewGetOne = useSelector((state) => state.reviewGetOne);

    const {review, loading} = reviewGetOne;

    return (
        <Card
            mt="30px"
            boxShadow="lg"
            w={{sm: "100%", md: "70%"}}
            variant="filled"
        >
            <CardHeader>
                <Heading size="md">Информация об отзыве</Heading>
            </CardHeader>
            {loading ? (
                <Flex mt="100px" mb="100px" justifyContent="center">
                    <Spinner w="100px" h="100px" thickness="10px" />
                </Flex>
            ) : (
                <CardBody>
                    <Stack divider={<StackDivider />} spacing="4">
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                Название
                            </Heading>

                            <Text pt="2" fontSize="sm">
                                {review.full_name}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                Оценка
                            </Heading>

                            <Text pt="2" fontSize="sm">
                                {review.rating}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                Текст отзыва
                            </Heading>

                            <Text pt="2" fontSize="sm">
                                {review.body ? review.body : "Отзыв пустой"}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                Ссылка на отзыв
                            </Heading>

                            <Text pt="2" fontSize="sm">
                                {review.link}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                Дата создания
                            </Heading>

                            <Text pt="2" fontSize="sm">
                                {review.created_at}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                Филиал
                            </Heading>

                            <Text pt="2" fontSize="sm">
                                {review.branch}
                            </Text>
                        </Box>
                        <Box>
                            <Heading size="xs" textTransform="uppercase">
                                Соединение
                            </Heading>
                            {/* FIXME */}
                            <Text pt="2" fontSize="sm">
                                {review.connect === 0
                                    ? "Яндекс"
                                    : review.connect === 1
                                    ? "2GIS"
                                    : review.connect === 2
                                    ? "Google"
                                    : review.connect === 3
                                    ? "Flamp"
                                    : review.connect === 4
                                    ? "Zoon"
                                    : "Yell"}
                            </Text>
                        </Box>
                    </Stack>
                </CardBody>
            )}
        </Card>
    );
};

export default DescriptionCard;
