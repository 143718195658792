export const SvgIcon = {
    burgerMenu: () =>  
        <svg width="24" height="24" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7333 6.66663H1.26667C0.567106 6.66663 0 7.23373 0 7.93329V8.06663C0 8.76619 0.567106 9.33329 1.26667 9.33329H22.7333C23.4329 9.33329 24 8.76619 24 8.06663V7.93329C24 7.23373 23.4329 6.66663 22.7333 6.66663Z" fill="url(#paint0_linear_45_925)" />
            <path d="M22.7333 13.3334H1.26667C0.567106 13.3334 0 13.9005 0 14.6V14.7334C0 15.4329 0.567106 16 1.26667 16H22.7333C23.4329 16 24 15.4329 24 14.7334V14.6C24 13.9005 23.4329 13.3334 22.7333 13.3334Z" fill="url(#paint1_linear_45_925)" />
            <path d="M22.7333 0H1.26667C0.567106 0 0 0.567106 0 1.26667V1.4C0 2.09956 0.567106 2.66667 1.26667 2.66667H22.7333C23.4329 2.66667 24 2.09956 24 1.4V1.26667C24 0.567106 23.4329 0 22.7333 0Z" fill="url(#paint2_linear_45_925)" />
            <defs>
                <linearGradient id="paint0_linear_45_925" x1="-8.70588" y1="5.05878" x2="-7.21816" y2="20.6536" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4584FF"/>
                    <stop offset="1" stopColor="#4333A6" />
                </linearGradient>
                <linearGradient id="paint1_linear_45_925" x1="-8.70588" y1="11.7255" x2="-7.21816" y2="27.3203" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4584FF"/>
                    <stop offset="1" stopColor="#4333A6" />
                </linearGradient>
                <linearGradient id="paint2_linear_45_925" x1="-8.70588" y1="-1.60784" x2="-7.21816" y2="13.9869" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4584FF"/>
                    <stop offset="1" stopColor="#4333A6" />
                </linearGradient>
            </defs>
        </svg>,

    burgerMenuClose: () => 
        <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.4216 18.5359L2.91853 1.03279C2.34814 0.462396 1.48474 0.401004 0.990074 0.895668L0.895792 0.98995C0.401128 1.48461 0.46252 2.34801 1.03291 2.91841L18.536 20.4215C19.1064 20.9919 19.9698 21.0533 20.4645 20.5586L20.5587 20.4643C21.0534 19.9697 20.992 19.1063 20.4216 18.5359Z" fill="url(#paint0_linear_1622_609)" />
            <path d="M1.34987 18.5359L18.853 1.03279C19.4233 0.462396 20.2867 0.401004 20.7814 0.895668L20.8757 0.98995C21.3704 1.48461 21.309 2.34801 20.7386 2.91841L3.23549 20.4215C2.66509 20.9919 1.80169 21.0533 1.30703 20.5586L1.21275 20.4643C0.718081 19.9697 0.779473 19.1063 1.34987 18.5359Z" fill="url(#paint1_linear_1622_609)" />
            <defs>
                <linearGradient id="paint0_linear_1622_609" x1="-4.07578" y1="-8.23535" x2="-14.2133" y2="3.73089" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4584FF"/>
                    <stop offset="1" stopColor="#4333A6" />
                </linearGradient>
                <linearGradient id="paint1_linear_1622_609" x1="25.8473" y1="-8.23535" x2="35.9848" y2="3.73089" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4584FF"/>
                    <stop offset="1" stopColor="#4333A6" />
                </linearGradient>
            </defs>
        </svg>,
    
    userIcon: () => 
        <svg width="40" height="40" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.8758 50H42.124C46.9664 50 48.981 43.8045 45.0648 40.9563L42.9423 39.4126C41.2331 38.1696 39.174 37.5 37.0605 37.5H22.9392C20.8258 37.5 18.7667 38.1696 17.0575 39.4126L14.9349 40.9563C11.0187 43.8045 13.0334 50 17.8758 50Z" fill="url(#paint0_linear_74_1268)" />
            <path d="M40 20C40 25.5228 35.5228 30 30 30C24.4772 30 20 25.5228 20 20C20 14.4772 24.4772 10 30 10C35.5228 10 40 14.4772 40 20Z" fill="url(#paint1_linear_74_1268)" />
            <defs>
                <linearGradient id="paint0_linear_74_1268" x1="0.435907" y1="29.9632" x2="21.4752" y2="97.1397" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4584FF" />
                    <stop offset="1" stopColor="#4333A6" />
                </linearGradient>
                <linearGradient id="paint1_linear_74_1268" x1="12.7451" y1="-2.05882" x2="71.0784" y2="65.8823" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4584FF"/>
                    <stop offset="1" stopColor="#4333A6" />
                </linearGradient>
            </defs>
        </svg>,

    closeIcon: () => 
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.96875 19.2188H34.5938C34.9336 19.2188 35.2595 19.3537 35.4997 19.594C35.74 19.8343 35.875 20.1602 35.875 20.5C35.875 20.8398 35.74 21.1657 35.4997 21.406C35.2595 21.6463 34.9336 21.7812 34.5938 21.7812H8.96875C8.62894 21.7812 8.30305 21.6463 8.06277 21.406C7.82249 21.1657 7.6875 20.8398 7.6875 20.5C7.6875 20.1602 7.82249 19.8343 8.06277 19.594C8.30305 19.3537 8.62894 19.2188 8.96875 19.2188Z" fill="#D3D3D3" />
            <path d="M9.49952 20.5002L20.1262 31.1243C20.3668 31.3649 20.502 31.6912 20.502 32.0314C20.502 32.3716 20.3668 32.6979 20.1262 32.9385C19.8856 33.1791 19.5593 33.3143 19.2191 33.3143C18.8788 33.3143 18.5525 33.1791 18.312 32.9385L6.78071 21.4073C6.66139 21.2883 6.56673 21.1469 6.50214 20.9912C6.43754 20.8356 6.4043 20.6687 6.4043 20.5002C6.4043 20.3316 6.43754 20.1648 6.50214 20.0091C6.56673 19.8534 6.66139 19.712 6.78071 19.593L18.312 8.06178C18.5525 7.82119 18.8788 7.68604 19.2191 7.68604C19.5593 7.68604 19.8856 7.82119 20.1262 8.06178C20.3668 8.30236 20.502 8.62867 20.502 8.9689C20.502 9.30914 20.3668 9.63544 20.1262 9.87603L9.49952 20.5002Z" fill="#D3D3D3" />
        </svg>,

    VKIcon: () => 
        <svg width={31} height={31} viewBox={`0 0 31 31`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15.5" cy="15.5" r="15.5" fill="white"/>
        <path d="M15.6332 0.677197C15.7449 0.286507 15.6332 0 15.1046 0H13.3535C12.908 0 12.7044 0.247858 12.5926 0.520081C12.5926 0.520081 11.7023 2.80457 10.4407 4.28583C10.0335 4.71685 9.84747 4.85297 9.6255 4.85297C9.51451 4.85297 9.35322 4.71685 9.35322 4.32616V0.677197C9.35322 0.208368 9.22467 0 8.85338 0H6.10187C5.82401 0 5.65633 0.21677 5.65633 0.423458C5.65633 0.867081 6.28712 0.969585 6.35179 2.21979V4.93194C6.35179 5.52596 6.25039 5.63435 6.02682 5.63435C5.43356 5.63435 3.98993 3.34146 3.13318 0.716686C2.9663 0.206688 2.79782 0.000840535 2.35068 0.000840535H0.600446C0.0998082 0.000840535 0 0.248698 0 0.520921C0 1.00991 0.59326 3.42968 2.76349 6.62998C4.21031 8.81532 6.24719 10 8.10282 10C9.21509 10 9.35242 9.73702 9.35242 9.28331V7.63149C9.35242 7.10553 9.45862 6.99966 9.81074 6.99966C10.0694 6.99966 10.515 7.13746 11.5538 8.19022C12.7403 9.43875 12.9367 9.99916 13.6035 9.99916H15.3537C15.8535 9.99916 16.1034 9.73618 15.9597 9.21694C15.8024 8.70022 15.2355 7.94824 14.4834 7.05848C14.0745 6.551 13.4637 6.00487 13.2777 5.73181C13.0182 5.37977 13.0932 5.22433 13.2777 4.91178C13.2785 4.91262 15.4112 1.75181 15.6332 0.677197Z" fill="url(#paint0_linear_46_1483)" transform="translate(7.5 10.5)"/>
        <defs>
            <linearGradient id="paint0_linear_46_1483" x1="-5.80392" y1="-6.02941" x2="18.7832" y2="39.7894" gradientUnits="userSpaceOnUse">
                <stop stopColor="#4584FF"/>
                <stop offset="1" stopColor="#4333A6"/>
            </linearGradient>
        </defs>
    </svg>,

    TGIcon: () => 
        <svg width={31} height={31} viewBox={`0 0 31 31`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15.5" cy="15.5" r="15.5" fill="white"/>
            <path d="M13.9601 1.09218L11.8474 11.2757C11.6879 11.9942 11.2723 12.1731 10.6818 11.8348L7.46241 9.41017L5.90925 10.9373C5.73723 11.1131 5.59371 11.2598 5.26218 11.2598L5.4937 7.9091L11.4598 2.39904C11.7194 2.16291 11.4033 2.03156 11.0568 2.2682L3.68101 7.0152L0.505672 5.99916C-0.184901 5.77888 -0.197402 5.29334 0.649688 4.95449L13.0695 0.0638668C13.6446 -0.156413 14.1476 0.194706 13.9601 1.09269V1.09218Z" fill="url(#paint0_linear_46_1479)" transform="translate(7.5 9.5)"/>
            <defs>
                <linearGradient id="paint0_linear_46_1479" x1="-5.07843" y1="-7.23529" x2="28.7275" y2="38.7011" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4584FF"/>
                    <stop offset="1" stopColor="#4333A6"/>
                </linearGradient>
            </defs>
        </svg>,

    PencilSquare: ({
        width,
        height
    }) => 
        <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor" d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z">
            </path>
        </svg>
    };
