import { useRef } from "react";
// Chakra Icons
import { SearchIcon } from "@chakra-ui/icons";
import { BiLogOut } from "react-icons/bi";
// Chakra Imports
import {
    Button,
    Flex,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    useColorModeValue
} from "@chakra-ui/react";
// Assets
// Custom Icons
import { SettingsIcon } from "../Icons/Icons";
// Custom Components
import PropTypes from "prop-types";
import SidebarResponsive from "../Sidebar/SidebarResponsive";
import { useNavigate } from "react-router-dom";
import routes from "../../routers/routes.js";
import { useLogout } from "features/Auth/logout";

export default function HeaderLinks(props) {
    const {variant, children, fixed, secondary, onOpen, ...rest} = props;
    const navigate = useNavigate();
    const { logout } = useLogout();

    let maincolor = useColorModeValue("maincolor", "maincolor");
    let inputBg = useColorModeValue("white", "gray.800");
    let mainText = useColorModeValue("gray.700", "gray.200");
    let navbarIcon = useColorModeValue("gray.500", "gray.200");
    let searchIcon = useColorModeValue("gray.700", "gray.200");

    const handleExit = () => {
        logout();
        navigate('/');
    };

    if (secondary) {
        navbarIcon = "black";
        mainText = "black";
    }
    const settingsRef = useRef();
    return (
        <Flex
            pe={{sm: "0px", md: "16px"}}
            w={{sm: "100%", md: "auto"}}
            alignItems="center"
            flexDirection="row"
        >
            <InputGroup
                cursor="pointer"
                bg={inputBg}
                borderRadius="15px"
                w={{
                    sm: "128px",
                    md: "200px",
                }}
                me={{sm: "auto", md: "20px"}}
                _focus={{
                    borderColor: {maincolor},
                }}
                _active={{
                    borderColor: {maincolor},
                }}
            >
                <InputLeftElement
                    // eslint-disable-next-line react/no-children-prop
                    children={
                        <IconButton
                            bg="inherit"
                            borderRadius="inherit"
                            _hover={{}}
                            _active={{
                                bg: "inherit",
                                transform: "none",
                                borderColor: "transparent",
                            }}
                            _focus={{
                                boxShadow: "none",
                            }}
                            icon={<SearchIcon color={searchIcon} w="15px" h="15px"/>}
                        ></IconButton>
                    }
                />
                <Input
                    fontSize="xs"
                    py="11px"
                    color={mainText}
                    placeholder="Поиск..."
                    borderRadius="inherit"
                />
            </InputGroup>
            <Button
                ms="0px"
                px="0px"
                onClick={() => handleExit()}
                me={{sm: "2px", md: "16px"}}
                color={navbarIcon}
                variant="transparent-with-icon"
                rightIcon={
                    document.documentElement.dir ? (
                        ""
                    ) : (
                        <Icon color={navbarIcon} as={BiLogOut} w="22px" h="22px" me="0px"/>
                    )
                }
                leftIcon={
                    document.documentElement.dir ? (
                        <Icon color={navbarIcon} as={BiLogOut} w="22px" h="22px" me="0px"/>
                    ) : (
                        ""
                    )
                }
            >
                Выйти
            </Button>
            <SidebarResponsive
                logoText={props.logoText}
                secondary={props.secondary}
                routes={routes.slice(0,6)}
                {...rest}
            />
            <SettingsIcon
                cursor="pointer"
                ms={{base: "16px", xl: "0px"}}
                me="16px"
                ref={settingsRef}
                onClick={props.onOpen}
                color={navbarIcon}
                w="18px"
                h="18px"
            />
            
        </Flex>
    );
}

HeaderLinks.propTypes = {
    variant: PropTypes.string,
    fixed: PropTypes.bool,
    secondary: PropTypes.bool,
    onOpen: PropTypes.func,
};
