export const dataFormatter = (data, model) => {

  data.forEach(item => {
    if (item.state === "success") {
      if (item.type === "phone") {
        model[item.type] = formattingPhone(item.value);
      } else {
        model[item.type] = item.value;
      }
    }
  });

  return model;
};

const formattingPhone = (phone) => {
  const formatData = phone.replace(/\s|\(|\)|-/g, '');

  return formatData;
};