import { GET_BRANCH_DETAILD_ERROR, GET_BRANCH_DETAILS_REQUEST, GET_BRANCH_DETAILS_SUCCESS } from "./getBranchState";

export const getBranchDetailsReducer = (state = { detailsBranch: [] }, action) => {
    switch (action.type) {
        case GET_BRANCH_DETAILS_REQUEST:
            return { loading: true, detailsBranch: [] };

        case GET_BRANCH_DETAILS_SUCCESS:
            return { loading: false, detailsBranch: action.payload };

        case GET_BRANCH_DETAILD_ERROR:
            return { loading: false, detailsBranch: action.payload };

        default:
            return state;
    }
};